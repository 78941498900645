//Libs
import styled, { css } from "styled-components";
//Config
import { MOBILE_VIEWPORT_SIZE, DEVICE_IS_MOBILE } from "Config/config";

export default styled.div`
  background-image: ${(props) => props.backgroundImage};
  min-height: 100%;
  min-width: 1024px;
  filter: brightness(44%);
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;

  @media screen and (max-width: ${MOBILE_VIEWPORT_SIZE}px) {
    left: 50%;
    margin-left: -512px;
  }
  ${() =>
    DEVICE_IS_MOBILE &&
    css`
      left: 50%;
      margin-left: -512px;
    `}
`;
