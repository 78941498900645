import React from "react";
import Stories from "react-insta-stories";
import "./CollectionOverview.css";
import CollectionPreview from "../CollectionPreview/CollectionPreview";
import { connect } from "react-redux";
import { selectCategoriesData } from "../../Redux/Categories/categories-selectors";

const stories = [
  [
    {
      url:
        "https://image.winudf.com/v2/image/YnIuY29tLm1peHhpLnBhcnRwdWIuaW50ZXVyaV9zY3JlZW5fMF8xNTE0NTM0MTIzXzA0Mg/screen-0.jpg?fakeurl=1&type=.jpg",
      header: {
        heading: "Sandoz",
        subheading: "30m",
        profileImage:
          "https://seekvectorlogo.com/wp-content/uploads/2017/12/sandoz-vector-logo.png",
      },
    },
  ],
  [
    {
      url:
        "https://image.winudf.com/v2/image1/Y29tLmhlYWx0aG9icy5oYXJwZXIucml2YXJveGFiYW5fc2NyZWVuXzZfMTU3NzM4MjI4NV8wNDg/screen-6.jpg?fakeurl=1&type=.jpg",
      header: {
        heading: "Bayer",
        subheading: "37m",
        profileImage:
          "https://www.pngitem.com/pimgs/m/256-2564932_bayer-logo-png-transparent-png.png",
      },
    },
    {
      url:
        "https://image.winudf.com/v2/image1/Y29tLmhlYWx0aG9icy5oYXJwZXIucml2YXJveGFiYW5fc2NyZWVuXzZfMTU3NzM4MjI4NV8wNDg/screen-6.jpg?fakeurl=1&type=.jpg",
      header: {
        heading: "Bayer",
        subheading: "37m",
        profileImage:
          "https://www.pngitem.com/pimgs/m/256-2564932_bayer-logo-png-transparent-png.png",
      },
    },
    {
      url:
        "https://image.winudf.com/v2/image1/Y29tLmhlYWx0aG9icy5oYXJwZXIucml2YXJveGFiYW5fc2NyZWVuXzZfMTU3NzM4MjI4NV8wNDg/screen-6.jpg?fakeurl=1&type=.jpg",
      header: {
        heading: "Bayer",
        subheading: "37m",
        profileImage:
          "https://www.pngitem.com/pimgs/m/256-2564932_bayer-logo-png-transparent-png.png",
      },
    },
    {
      url:
        "https://image.winudf.com/v2/image1/Y29tLmhlYWx0aG9icy5oYXJwZXIucml2YXJveGFiYW5fc2NyZWVuXzZfMTU3NzM4MjI4NV8wNDg/screen-6.jpg?fakeurl=1&type=.jpg",
      header: {
        heading: "Bayer",
        subheading: "37m",
        profileImage:
          "https://www.pngitem.com/pimgs/m/256-2564932_bayer-logo-png-transparent-png.png",
      },
    },
    {
      url:
        "https://image.winudf.com/v2/image1/Y29tLmhlYWx0aG9icy5oYXJwZXIucml2YXJveGFiYW5fc2NyZWVuXzZfMTU3NzM4MjI4NV8wNDg/screen-6.jpg?fakeurl=1&type=.jpg",
      header: {
        heading: "Bayer",
        subheading: "37m",
        profileImage:
          "https://www.pngitem.com/pimgs/m/256-2564932_bayer-logo-png-transparent-png.png",
      },
    },
    {
      url:
        "https://image.winudf.com/v2/image1/Y29tLmhlYWx0aG9icy5oYXJwZXIucml2YXJveGFiYW5fc2NyZWVuXzZfMTU3NzM4MjI4NV8wNDg/screen-6.jpg?fakeurl=1&type=.jpg",
      header: {
        heading: "Bayer",
        subheading: "37m",
        profileImage:
          "https://www.pngitem.com/pimgs/m/256-2564932_bayer-logo-png-transparent-png.png",
      },
    },
    {
      url:
        "https://image.winudf.com/v2/image1/Y29tLmhlYWx0aG9icy5oYXJwZXIucml2YXJveGFiYW5fc2NyZWVuXzZfMTU3NzM4MjI4NV8wNDg/screen-6.jpg?fakeurl=1&type=.jpg",
      header: {
        heading: "Bayer",
        subheading: "37m",
        profileImage:
          "https://www.pngitem.com/pimgs/m/256-2564932_bayer-logo-png-transparent-png.png",
      },
    },
    {
      url:
        "https://image.winudf.com/v2/image1/Y29tLmhlYWx0aG9icy5oYXJwZXIucml2YXJveGFiYW5fc2NyZWVuXzZfMTU3NzM4MjI4NV8wNDg/screen-6.jpg?fakeurl=1&type=.jpg",
      header: {
        heading: "Bayer",
        subheading: "37m",
        profileImage:
          "https://www.pngitem.com/pimgs/m/256-2564932_bayer-logo-png-transparent-png.png",
      },
    },
    {
      url:
        "https://image.winudf.com/v2/image1/Y29tLmhlYWx0aG9icy5oYXJwZXIucml2YXJveGFiYW5fc2NyZWVuXzZfMTU3NzM4MjI4NV8wNDg/screen-6.jpg?fakeurl=1&type=.jpg",
      header: {
        heading: "Bayer",
        subheading: "37m",
        profileImage:
          "https://www.pngitem.com/pimgs/m/256-2564932_bayer-logo-png-transparent-png.png",
      },
    },
  ],
];

class CollectionOverview extends React.Component {
  constructor() {
    super();
    this.state = {
      showStories: false,
      storyIndex: null,
    };
  }

  render() {
    const { movies, tvshow, categories } = this.props;
    const { showStories, storyIndex } = this.state;
    return (
      <div className="collection-overview">
        <h1
          style={{ paddingLeft: "80px" }}
          className="collection-preview__title"
        >
          Sigue las historias
        </h1>
        <div
          style={{
            height: "80px",
            padding: "5px 40px 5px 80px",
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "40px",
          }}
        >
          {[
            "https://seekvectorlogo.com/wp-content/uploads/2017/12/sandoz-vector-logo.png",
            "https://seekvectorlogo.net/wp-content/uploads/2018/10/servier-vector-logo.png",
            "https://www.pngitem.com/pimgs/m/256-2564932_bayer-logo-png-transparent-png.png",
            "https://media.americanpharmaceuticalreview.com/m/28/Clean-Facility/Millipore-Sigma/vendor-logo.png",
            "https://enerescpm.com/wp-content/uploads/2019/06/asss.png",
            "https://www.informatica.com/content/dam/informatica-com/en/image/cc03/cc03-sanofi.png.thumbnail.300.169.png",
            "https://s3.amazonaws.com/gupy5/production/companies/237/career/1/images/2020-07-27_15-50_logo.png",
            "https://adecostarica.org/wp-content/uploads/2018/11/elvatron2.jpg",
            "https://media.ambito.com/adjuntos/239/imagenes/037/615/0037615611.jpg",
            "https://www.farmaventas.es/images/farmanoticias/noves/nuevo-logo-ferrer.png",
            "https://media-exp1.licdn.com/dms/image/C4E0BAQH48I-YCjrSpg/company-logo_200_200/0?e=2159024400&v=beta&t=L7Fxbne4obemtgADhYNHLbrobN0TRhbH0-JC7soANS4",
            "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Menarini.svg/1200px-Menarini.svg.png",
            "https://6erxg60qvo1qvjha44jrgpan-wpengine.netdna-ssl.com/wp-content/uploads/2014/02/Logo-Novo-Nordisk.jpg",
          ].map((story, index) => {
            return (
              <div
                onClick={() => {
                  this.setState({ showStories: true, storyIndex: index });
                }}
                style={{
                  cursor: "pointer",
                  borderRadius: "50%",
                  display: "inline-flex",
                  height: "60px",
                  width: "60px",
                  marginRight: "15px",
                  position: "relative",
                  background: "linear-gradient(to right, #DC4588, #FFD100)",
                  padding: "3px",
                }}
              >
                <img
                  style={{
                    width: "inherit",
                    borderRadius: "50%",
                    backgroundColor: "#fff",
                  }}
                  src={story}
                  alt=""
                />
              </div>
            );
          })}
        </div>
        {showStories && (
          <div
            onClick={() => {
              this.setState({ showStories: false });
            }}
            style={{
              backgroundColor: "#000000ab",
              position: "fixed",
              top: 0,
              left: 0,
              height: "100vh",
              width: "100vw",
              zIndex: 99999,
            }}
          >
            <div style={{ textAlign: "center" }}>
              <div
                style={{
                  height: "calc(100vh - 100px)",
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                {stories[storyIndex] && (
                  <Stories
                    defaultInterval={10000}
                    onAllStoriesEnd={() => {
                      this.setState({
                        storyIndex: storyIndex + 1,
                        showStories: stories[storyIndex + 1] !== undefined,
                      });
                    }}
                    style={{ zIndex: 9999999999999999 }}
                    stories={stories[storyIndex]}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        {categories.map((items) => (
          <CollectionPreview
            key={items.id}
            title={items.title}
            start={items.start}
            end={items.end}
            movies={movies}
            tvshow={tvshow}
          />
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  categories: selectCategoriesData(state),
});

export default connect(mapStateToProps)(CollectionOverview);
