/*
		This filter has 4 rules:
		1. Find by lastname
		2. Find by name
		3. Find by name + lastname
		4. Find by lastname + name
	*/
export function filterData(data = [], value = "", searchEntity) {
  return data.filter((user) => {
    let str = "";
    if (searchEntity === "users") {
      str = `${user.name} ${user.firstLastName || ""} ${
        user.secondLastName || ""
      }`;
    }
    //if(Another searchEntity === ...)

    return str.toLocaleLowerCase().search(value) !== -1;
  });
}
