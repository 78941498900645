import like from 'Assets/ReactionImages/likeIcon.png';
import angry from 'Assets/ReactionImages/angryIcon.png';
import haha from 'Assets/ReactionImages/hahaIcon.png';
import love from 'Assets/ReactionImages/loveIcon.png';
import sad from 'Assets/ReactionImages/sadIcon.png';
import wow from 'Assets/ReactionImages/wowIcon.png';

const reactions = {
  like,
  angry,
  haha,
  love,
  sad,
  wow
}

export default reactions;
