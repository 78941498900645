import { createSelector } from "reselect";

const selectUser = (state) => state.user;

export const selectCurrentUser = createSelector(
  [selectUser],
  (user) => user.currentUser
);

export const selectShowResourceUpload = createSelector(
  [selectUser],
  (user) => user.showResourceUpload
);

export const selectProfilePhotoUpdated = createSelector(
  [selectUser],
  (user) => user.profilePhotoUpdated
);

export const selectIsNavMenuOpen = createSelector(
  [selectUser],
  (user) => user.isNavMenuOpen
);

export const selectMessage = createSelector(
  [selectUser],
  (user) => user.message
);

export const selectIsTourOpen = createSelector(
  [selectUser],
  (user) => user.isTourOpen
);

export const selectTokenPassWord = createSelector(
  [selectUser],
  (user) => user.tokenRecoveryPassword.token
);

export const selectSessionExpired = createSelector(
  [selectUser],
  (user) => user.sessionExpired
);

export const selectMsgChangePassConfigUser = createSelector(
  [selectUser],
  (user) => user.msgChangePassConfigUser
);

export const selectPermissionCurrentUser = createSelector(
  [selectUser],
  (user) => user.currentUser.permission
);

export const selectLoadingUserData = createSelector(
  [selectUser],
  (user) => user.loading_data
)
