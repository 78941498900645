//Libs
import React from "react";
import styled from "styled-components";

const StyledLabel = styled.label`
  margin: ${(props) =>
    props.margin ? props.margin : props.theme.errorLabel.margin};
  padding: ${(props) =>
    props.padding ? props.padding : props.theme.errorLabel.padding};
  text-align: ${(props) =>
    props.textAlign ? props.textAlign : props.theme.errorLabel.textAlign};
  color: ${(props) => props.theme.errorLabel.color};
`;

export default function ErrorLabel(props) {
  const { value } = props;

  return value ? <StyledLabel {...props}>{value}</StyledLabel> : null;
}
