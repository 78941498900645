//Libs
import React from "react";
import { DEVICE_IS_MOBILE, MOBILE_VIEWPORT_SIZE } from "Config/config";
import styled, { css } from "styled-components";
//Components
import enVivo from "Assets/en-vivo.svg";

const StyledLabel = styled.img`
  display: inline !important;
  width: 6vw;
  margin-left: 10px;
  position: relative;
  bottom: 5px;
  @media screen and (max-width: ${MOBILE_VIEWPORT_SIZE}px) {
    height: ${(props) => props.mobileHeight};
  }
  ${() =>
    DEVICE_IS_MOBILE &&
    css`
      height: ${(props) => props.mobileHeight};
    `}
`;

export default function OnlineLabel({
  height,
  mobileHeight,
  margin,
  inputRef,
  className,
  ...rest
}) {
  return (
    <StyledLabel
      {...rest}
      height={height}
      mobileHeight={mobileHeight}
      margin={margin}
      ref={inputRef}
      src={enVivo}
      alt="live"
      className={className}
    />
  );
}
