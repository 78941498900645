import VideoActionTypes from "./video-types";
import UserActionTypes from "Redux/User/user-types";
import {
  fetchUserVideoMessages,
  createMessageInDatabase,
  fetchVideoData,
  closePollAnswerDatabase,
} from "Services/VideoService";

//Messages
export function getUserVideoMessages(token, videoId, whereOpts = {}) {
  return (dispatch) => {
    if (!token || !videoId) {
      dispatch({
        type: UserActionTypes.SHOW_MESSAGE,
        payload: { body: "Seleccione un id de video válido" },
      });
      return;
    }

    fetchUserVideoMessages(token, videoId, whereOpts)
      .then((messages) => {
        dispatch({
          type: VideoActionTypes.GET_USER_VIDEO_MESSAGES,
          payload: messages,
        });
      })
      .catch((err) => console.log(err));
  };
}

export function sendMessageToDatabase(
  token,
  { idKey, organizationId, resourceId, messageBody, currentVideoSecond, isQuestion }
) {
  return (dispatch) => {
    if (!token || !organizationId || !resourceId || !messageBody || !idKey) {
      dispatch({
        type: UserActionTypes.SHOW_MESSAGE,
        payload: { body: "No se pudo enviar su mensaje" },
      });
      return;
    }
    return createMessageInDatabase(token, {
      organizationId,
      resourceId,
      messageBody,
      currentVideoSecond,
      isQuestion,
    })
      .then((response) => {
        if (response) {
          return response;
        }

        dispatch({
          type: VideoActionTypes.CREATE_MESSAGE_SUCCESSFUL,
          payload: { idKey, saved: response },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: VideoActionTypes.CREATE_MESSAGE_SUCCESSFUL,
          payload: { idKey, saved: false },
        });
      });
  };
}

export const addMessageToLocalList = (message) => ({
  type: VideoActionTypes.ADD_MESSAGE_TO_LOCAL_LIST,
  payload: message,
});
// ADD NEW POLL
export const addPollToLocalList = (poll) => ({
  type: VideoActionTypes.ADD_POLL_TO_LOCAL_LIST,
  payload: poll,
});

//CLOSE CURRENT POLL
export const updatePollToLocalList = (poll = { local: false }) => {
  return (dispatch) => {
    if (poll.local) {
      const { token, pollId, organizationId, currentVideoId } = poll;
      if (!token || !pollId || !organizationId || !currentVideoId) {
        dispatch({
          type: UserActionTypes.SHOW_MESSAGE,
          payload: { body: "No se pudo cerrar la encuesta" },
        });
        return;
      }
      closePollAnswerDatabase(token, {
        pollId,
        organizationId,
        currentVideoId,
      }).then((response) => {
        dispatch({
          type: VideoActionTypes.CLOSE_POLL_DATABASE,
        });
      });
    } else {
      dispatch({
        type: VideoActionTypes.CLOSE_POLL_DATABASE,
      });
    }
  };
};

//Video
export function getVideoData(token, resourceId, organizationId) {
  return (dispatch) => {
    if (!token || !resourceId || !organizationId) {
      dispatch({
        type: UserActionTypes.SHOW_MESSAGE,
        payload: { body: "Seleccione un id de video válido" },
      });
      return;
    }
    return fetchVideoData(token, resourceId, organizationId)
      .then((video) => {
        if (!video) {
          return false;
        }
        dispatch({
          type: VideoActionTypes.REFRESH_VIDEO_WATCH_PROPERTIES,
          payload: video,
        });
        return true;
      })
      .catch((err) => console.log(err));
  };
}

export const loadNewVideoLiveCurrently = (newVideoId) => ({
  type: VideoActionTypes.LOAD_NEW_VIDEO_LIVE_CURRENTLY,
  payload: {
    videoId: newVideoId,
  },
});

export const setVideoProperties = ({
  roomId,
  videoId,
  videoUrl,
  streamId,
  streamWss,
  withChat,
}) => ({
  type: VideoActionTypes.SET_WATCH_PROPERTIES,
  payload: { roomId, videoId, videoUrl, streamId, streamWss, withChat },
});

export const resetVideoProperties = () => ({
  type: VideoActionTypes.RESET_WATCH_VIDEO_PROPERTIES,
});

export const addPollAnswerCounter = (payload) => ({
  type: VideoActionTypes.ADD_POLL_ANSWER_COUNTER,
  payload,
});
// check the user already vote
export const sentCheckPollAnswer = () => ({
  type: VideoActionTypes.CHECK_USER_ALREADY_VOTE,
});
