import React from "react";
import styled from "styled-components";

const NewMessagesContainer = styled.div`
  bottom: ${(props) => props.theme.newMessagesContainer.bottom};
  position: ${(props) => props.theme.newMessagesContainer.position};
  color: ${(props) => props.theme.newMessagesContainer.color};
  width: ${(props) => props.theme.newMessagesContainer.width};
  text-align: ${(props) => props.theme.newMessagesContainer.textAlign};
  z-index: ${(props) => props.theme.newMessagesContainer.zIndex};
`;

const NewMessagesButtonStyled = styled.span`
  cursor: ${(props) => props.theme.newMessagesButton.cursor};
  background-color: ${(props) => props.theme.newMessagesButton.backgroundColor};
  padding: ${(props) => props.theme.newMessagesButton.padding};
  border-radius: ${(props) => props.theme.newMessagesButton.borderRadius};
`;

export default function NewMessagesButton(props) {
  const { onClick } = props;

  return (
    <NewMessagesContainer>
      <NewMessagesButtonStyled onClick={() => { onClick() }}>Nuevos mensajes</NewMessagesButtonStyled>
    </NewMessagesContainer>
  );
}
