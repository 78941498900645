import directoryTypes from "./directory.types";
import UserActionTypes from "Redux/User/user-types";
import DirectoryService from "Services/DirectoryService";

import { CompanyService } from "Services/CompanyService";
import { orgnizacionUnseleted } from "Redux/DynamicConfigCompany/dcc-action";
import { dccLogout } from "Redux/DynamicConfigCompany/dcc-action";
import { clearOrganization } from "Redux/Organization/organizarion-action";
import { logout } from "Redux/User/user-actions";

export const setDirectoryData = (token, {organizationId, LastRowSpeaker}) => {
  return (dispatch) => {
    if (!token) {
      dispatch({
        type: UserActionTypes.SHOW_MESSAGE,
        payload: {
          body: "Se necesita un token válido para obtener las noticias",
        },
      });
      return;
    }
    dispatch({
      type: directoryTypes.LOADING_DIRECTORY_DATA,
      payload: true,
    });

    DirectoryService.fetchDirectoryData(token, {
      organizationId, LastRowSpeaker
    }).then(async (data) => {
      if (data === 401) {
        const companyData = await CompanyService.getCompanyData(
          window.location.host
        ).then((data) => data);
        dispatch(orgnizacionUnseleted(companyData.company));
        dispatch(clearOrganization());
        dispatch(dccLogout());
        dispatch(logout(true));
      } else {
        dispatch({
          type: directoryTypes.ADD_TOTAL_ITEMS,
          payload: data.directory.count,
        })

        dispatch({
          type: directoryTypes.SET_DIRECTORY_DATA,
          payload: data.directory.rows.length === 0 ? null : data.directory.rows,
        });
      }
    });
  };
};

export const clearDirectory = () => {
  return {
    type: directoryTypes.CLEAR_DIRECTORY,
  };
};
