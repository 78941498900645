const themeCompany = {
  nav: {
    height: "100px",
  },
  form: {
    width: "390px",
    height: "auto",
    margin: 0,
    display: "block",
    padding: "10px",
    position: "relative",
    boxShadow: "0px 0px 10px white",
    alignItems: "center",
    borderRadius: "0",
    flexDirection: "row",
    justifyContent: "center",
    backgroundImage:
      "`linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8))`",
  },
  grid: {
    gap: 0,
    top: 0,
    left: 0,
    rows: "none",
    width: "auto",
    border: "none",
    height: "auto",
    margin: 0,
    zIndex: 0,
    columns: "none",
    display: "grid",
    padding: 0,
    position: "static",
    minHeight: 0,
    overflowX: "visible",
    overflowY: "visible",
    gridAutoFlow: "row",
  },
  link: {
    color: "#fff",
    margin: 0,
  },
  menu: {
    color: "rgb(255, 255, 255)",
    width: "200px",
    fontSize: "14px",
    backgroundColor: "rgb(20, 20, 20)",
  },
  fonts: {
    size: {},
    family: "helvetica",
  },
  image: {
    width: "100%",
    border: 0,
    cursor: "pointer",
    height: "auto",
    margin: 0,
    padding: 0,
    objectFit: "cover",
  },
  title: {
    margin: "10px auto",
    textAlign: "center",
  },
  avatar: {
    width: "42px",
    height: "42px",
    margin: 0,
  },
  button: {
    primary: {
      color: "rgb(255,255,255)",
      width: "auto",
      border: "none",
      cursor: "pointer",
      height: "auto",
      display: "flex",
      padding: "0 22px 0 22px",
      fontSize: "14.5px",
      minWidth: "130px",
      fontWeight: 600,
      lineHeight: "40px",
      borderRadius: "3px",
      justifyContent: "center",
      backgroundColor: "#00B2F3",
    },
    container: {
      width: "100%",
      margin: 0,
      display: "flex",
      justifyContent: "center",
    },
    secondary: {},
  },
  drawer: {
    header: {
      color: "white",
      width: "auto",
      height: "auto",
      zIndex: 10,
      padding: "0 20px",
      position: "fixed",
      boxShadow: "0 0 8px white",
      fontWeight: "unset",
      backgroundColor: "transparent",
    },
    content: {
      border: "unset",
      backgroundColor: "rgba(0,0,0,.75)",
    },
    padding: "20px 0 20px 20px",
  },
  select: {
    color: "#fff",
    width: "auto",
    height: "42px",
    margin: "10px",
    fontSize: "14.5px",
    borderRadius: "3px !important",
    letterSpacing: "0.1em",
    backgroundColor: "#333333",
  },
  wrapper: {
    width: "auto",
    border: "none",
    cursor: "auto",
    height: "auto",
    margin: 0,
    zIndex: 0,
    display: "block",
    outline: 0,
    padding: 0,
    maxWidth: "none",
    minWidth: 0,
    position: "static",
    boxShadow: "none",
    maxHeight: "none",
    minHeight: 0,
    textAlign: "left",
    transform: "none",
    alignItems: "center",
    background: "transparent",
    transition: "all 0s ease 0s",
    flexDirection: "row",
    justifyContent: "center",
  },
  scrollbar: {
    hover: {
      background: "rgba(255,255,255,.5)",
    },
    thumb: {
      boxShadow: "",
      background: "transparent",
      borderRadius: "10px",
    },
    track: {
      background: "transparent",
    },
    width: "10px",
    height: "10px",
  },
  errorLabel: {
    color: "#c00",
    margin: 0,
    padding: "0 10px",
    textAlign: "center",
  },
  goToSignin: {
    link: {
      color: "rgb(255,255,255)",
      textDecoration: "none",
    },
    question: {
      color: "white",
      textAlign: "center",
      marginRight: "5px",
    },
    container: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
  },
  messageInput: {
    flex: 1,
    color: "rgba(255, 255, 255, 0.5)",
    right: "20px",
    width: "335px",
    border: "none",
    bottom: "20px",
    msFlex: 1,
    padding: "12px 10px",
    fontSize: "16px",
    position: "fixed",
    minHeight: "44px",
    transform: "none",
    background: "rgba(255, 255, 255, 0.1)",
    fontFamily: '"museo_sans300", sans-serif',
    lineHeight: "19px",
    transition: "all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1)",
    webkitBoxFlex: "1",
    webkitTransform: "none",
    webkitTransition: "all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1)",
    borderTopRightRadius: "3px",
    borderBottomRightRadius: "3px",
  },
  floatCheckButton: {
    top: "auto",
    left: "auto",
    color: "rgba(255, 255, 255, 0.5)",
    hover: {
      color: "rgb(117,0,0)",
    },
    right: "auto",
    bottom: "auto",
    cursor: "pointer",
    zIndex: 3,
    padding: 0,
    fontSize: "3rem",
    position: "absolute",
    boxShadow: "0 0 5px white",
    transform: "none",
    transition: "all .3s",
    borderRadius: "50%",
    backgroundColor: "rgba(0,0,0,.5)",
  },
  floatFrownButton: {
    top: "auto",
    left: "auto",
    color: "rgba(255, 255, 255, 0.5)",
    hover: {
      color: "rgb(117,0,0)",
    },
    right: "auto",
    bottom: "auto",
    cursor: "pointer",
    zIndex: 3,
    padding: 0,
    fontSize: "3rem",
    position: "absolute",
    boxShadow: "0 0 5px white",
    transform: "none",
    transition: "all .3s",
    borderRadius: "50%",
    backgroundColor: "rgba(0,0,0,.5)",
  },
  floatSmileButton: {
    top: "auto",
    left: "auto",
    color: "rgba(255, 255, 255, 0.5)",
    hover: {
      color: "rgb(117,0,0)",
    },
    right: "auto",
    bottom: "auto",
    cursor: "pointer",
    zIndex: 3,
    padding: 0,
    fontSize: "3rem",
    position: "absolute",
    boxShadow: "0 0 5px white",
    transform: "none",
    transition: "all .3s",
    borderRadius: "50%",
    backgroundColor: "rgba(0,0,0,.5)",
  },
  floatCameraButton: {
    top: "auto",
    left: "auto",
    color: "rgba(255, 255, 255, 0.5)",
    hover: {
      color: "rgb(117,0,0)",
    },
    right: "auto",
    bottom: "auto",
    cursor: "pointer",
    zIndex: 3,
    padding: 0,
    fontSize: "3rem",
    position: "absolute",
    boxShadow: "0 0 5px white",
    transform: "none",
    transition: "all .3s",
    borderRadius: "50%",
    backgroundColor: "rgba(0,0,0,.5)",
  },
  newMessagesButton: {
    cursor: "pointer",
    padding: "10px",
    borderRadius: "20px",
    backgroundColor: "#0057a2b8",
  },
  floatLoadingButton: {
    top: "auto",
    left: "auto",
    color: "rgba(255, 255, 255, 0.5)",
    hover: {
      color: "rgb(117,0,0)",
    },
    right: "auto",
    bottom: "auto",
    cursor: "pointer",
    zIndex: 3,
    padding: 0,
    fontSize: "3rem",
    position: "absolute",
    boxShadow: "0 0 5px white",
    transform: "none",
    transition: "all .3s",
    borderRadius: "50%",
    backgroundColor: "rgba(0,0,0,.5)",
  },
  newMessagesContainer: {
    color: "#fff",
    width: "375px",
    bottom: "185px",
    zIndex: "9999",
    position: "fixed",
    textAlign: "center",
  },
};
export default themeCompany;
