import { combineReducers } from "redux";
import roomsReducer from "./Room/room-reducer";
import storiesReducer from "./Stories/stories-reducer";
import userReducer from "./User/user-reducer";
import assistantReducer from "./ContactUs/assistant-reducer";
import directoryReducer from "./Directory/directory.reducer";
import speakerReducer from "./Speakers/speaker-reducer";
import searchReducer from "./Search/search-reducer";
import categoriesReducer from "./Categories/categories-reducer";
import listReducer from "./List/list-reducer";
import videoReducer from "./Video/video-reducer";
// import dcaReducer from "./DynamicConfigApp/dca-reducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createBlacklistFilter } from "redux-persist-transform-filter";
import newCardiOneReducer from "./newsCardiOne/new-cardi-one-reducer";
import dccReducer from "./DynamicConfigCompany/dcc-reducer";
//SuperChat reducer
import pollReducer from "./Poll/poll-reducer";
import OrganizationReducer from "./Organization/organization-reducer";

// you want to remove some keys before you save
const saveSubsetBlacklistFilter = createBlacklistFilter("video", [
  "messages",
  "messagesLoaded",
]);

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "video","dcc","organization"],
  transforms: [saveSubsetBlacklistFilter],
};

const rootReducer = combineReducers({
  user: userReducer,
  assistant: assistantReducer,
  directory: directoryReducer,
  speaker: speakerReducer,
  search: searchReducer,
  rooms: roomsReducer,
  stories: storiesReducer,
  categories: categoriesReducer,
  list: listReducer,
  video: videoReducer,
  news: newCardiOneReducer,
  poll: pollReducer,
  dcc: dccReducer,
  organization: OrganizationReducer,
});

export default persistReducer(persistConfig, rootReducer);
