import type from "Redux/DynamicConfigCompany/dcc-type";

const INITIAL_STATE = {
  isFetching: true,
  id: null,
  name: null,
  url: null,
  cssStyle: {},
  countryId: null,
  organizations: null,
  modules: null,
  organizationSelect: null,
  subModules: null,
};

const dccReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.SET_COMPANY_DATA:
      if (!action.payload) return { ...state, isFetching: false };
      const { id, name, url, cssStyle, countryId, organizations, modules } =
        action.payload.company;

      return {
        ...state,
        isFetching: false,
        id: id,
        name: name,
        url: url,
        styleCompany: cssStyle,
        countryId: countryId,
        organizations: organizations,
        modules: state.organizationSelect ? state.modules : modules,
      };
    // here insert organization module
    case type.SET_ORGANIZATION_MODULE:
      const { availableModules } = action.payload;

      const rolesModules =
        Array.isArray(availableModules) &&
        availableModules.map((orgnizationModule) => orgnizationModule.module);

      // get subModulos
      const subMenus = [];
      Array.isArray(rolesModules) &&
        rolesModules.forEach((subModules) => {
          if (subModules.subModules !== undefined) {
            subMenus.push(...subModules.subModules);
          }
        });

      const localModuleOrg = {
        modules: Array.prototype.concat.apply(state.modules, rolesModules),
      };

      let filterModule = localModuleOrg.modules.reduce((unique, o) => {
        if (!unique.some((module) => module.name === o.name)) {
          unique.push(o);
        }
        return unique;
      }, []);

      return {
        ...state,
        modules: filterModule,
        organizationSelect: true,
        subModules: subMenus,
      };
    case type.ORGANIZATION_UNSELETED:
      return {
        ...state,
        organizationSelect: false,
        modules: action.payload.modules,
      };
    case type.DCC_CLEAR:
      return {
        ...state,
        subModules: null,
      };
    default:
      return state;
  }
};

export default dccReducer;
