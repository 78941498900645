import SpeakerActionTypes from "./speaker-types";

const INITIAL_STATE = {
  isShowVideos: false,
  speakers: [],
  loadingNewData: false,
  totalSpeakerItem: null
};

const speakerReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case SpeakerActionTypes.SET_SHOW_VIDEOS:
      return {
        ...state,
        isShowVideos: payload,
      };
    case SpeakerActionTypes.SET_SPEAKERS:
      const filteredSpeakers = Array.isArray(payload)
        ? payload.filter(
            (user) =>
              state.speakers.findIndex((usr) => user.id === usr.id) === -1
          )
        : null;

      return {
        ...state,
        speakers: filteredSpeakers ? [...state.speakers, ...filteredSpeakers] : null,
        loadingNewData: false,
      };
    case SpeakerActionTypes.CLEAR_SPEAKER_DATE:
      return {
        ...state,
        speakers: [],
        loadingNewData: false,
      }
    case SpeakerActionTypes.LOADING_SPEAKER:
      return {
        ...state,
        loadingNewData: payload,
      }
    case SpeakerActionTypes.TOTAL_SPEAKER_ITEM:
      return {
        ...state,
        totalSpeakerItem: payload
      }

    default:
      return state;
  }
};

export default speakerReducer;
