//Libs
import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
//Components
import logo from "Assets/POLPO_SUMMIT_LOGI.png";
//Config
import { ROUTES, MOBILE_VIEWPORT_SIZE, DEVICE_IS_MOBILE } from "Config/config";

const StyledLogo = styled.img`
  width: 12vw;
  cursor: pointer;
  opacity: 0.8;
  @media screen and (max-width: ${MOBILE_VIEWPORT_SIZE}px) {
    width: 75px;
  }
  ${() =>
    DEVICE_IS_MOBILE &&
    css`
      width: 75px;
    `}
`;

export default function Logo() {
  return (
    <Link to={ROUTES.HOME}>
      <StyledLogo src={logo} alt="logo" />
    </Link>
  );
}
