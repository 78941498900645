const VideoActionTypes = {
  //Messages
  ADD_MESSAGE_TO_LOCAL_LIST: "VIDEO_ADD_MESSAGE_TO_LOCAL_LIST",
  CREATE_MESSAGE_SUCCESSFUL: "VIDEO_CREATE_MESSAGE_SUCCESSFUL",

  //Video
  GET_USER_VIDEO_MESSAGES: "VIDEO_GET_USER_VIDEO_MESSAGES",
  SET_WATCH_PROPERTIES: "VIDEO_SET_WATCH_PROPERTIES",
  REFRESH_ROOM_WATCH_PROPERTIES: "VIDEO_REFRESH_ROOM_WATCH_PROPERTIES",
  REFRESH_VIDEO_WATCH_PROPERTIES: "VIDEO_REFRESH_VIDEO_WATCH_PROPERTIES",
  RESET_WATCH_VIDEO_PROPERTIES: "VIDEO_RESET_WATCH_VIDEO_PROPERTIES",
  LOAD_NEW_VIDEO_LIVE_CURRENTLY: "VIDEO_LOAD_NEW_VIDEO_LIVE_CURRENTLY",
  //Poll
  ADD_POLL_TO_LOCAL_LIST: "VIDEO_ADD_POLL_TO_LOCAL_LIST",
  ADD_POLL_ANSWER_COUNTER: "VIDEO_ADD_POLL_ANSWER_COUNTER",
  CLOSE_POLL_DATABASE: "CLOSE_POLL_DATABASE",
  CHECK_USER_ALREADY_VOTE: "CHECK_USER_ALREADY_VOTE",
};

export default VideoActionTypes;
