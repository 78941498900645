import React from "react";
import { Wrapper, Button, Grid } from "Components";
import { ROUTES } from "Config/config";
import { Link } from "react-router-dom";
import { Typography } from "antd";
import moment from "moment";

//service
import MixPanel from "Services/MixPanelService";

const EventBeforeView = ({
  mobileView,
  lostTime,
  resource,
  categoryRef,
  poster,
  currentUser,
  isLive,
  liveRoomId
}) => {
  const { id, name, speakers, description } = resource;
  const { Title } = Typography;
  const toLink =
    isLive && liveRoomId
      ? `${ROUTES.ROOM}/${liveRoomId}/${id}${
          categoryRef ? `?ref=${categoryRef}` : ""
        }`
      : `${ROUTES.VIDEO}/${id}${
          categoryRef ? `?ref=${categoryRef}` : ""
        }`;
  return (
    <div
      style={{
        display: "grid",
        gridTemplateRows: "auto",
        gridTemplateColumns: "100%",
      }}>
      <div
        style={{
          backgroundImage: `url(${poster})`,
          backgroundSize: 'cover',
          height: mobileView ? 'calc((100vw - 16px) * 0.5625)' : '433px'
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              bottom: '20px',
              left: '20px'
            }}
          >
            {
              isLive ?
              <Link to={toLink}>
                <Button primary={true} onClick={MixPanel.track(MixPanel.TYPES.ENTER_EVENT_ONLINE,{
                  userId: currentUser.id,
                  EventDate: moment().format('MMMM Do YYYY, h:mm:ss a'),
                  EventName: name,
                  liveRoomId: liveRoomId
                })}>
                  Ver Evento
                </Button>
              </Link> :
              <Button primary={true}>
                {`El evento estará disponible ${lostTime}`}
              </Button>
            }
          </div>
        </div>
      </div>
      <Grid display="Grid" columns="50% 50%">
        <Wrapper
          display="flex"
          flexDirection="column"
          padding="24px"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Title
            style={{ color: "white", fontWeight: "700", fontSize: "24px" }}
          >
            {name}
          </Title>
          <p style={{ color: "white", fontWeight: "400", fontSize: "14px" }}>
            Disponible {lostTime}
          </p>
          <p style={{ color: "white", fontWeight: "400", fontSize: "14px" }}>
            {description}
          </p>
        </Wrapper>
        <Wrapper
          display="flex"
          flexDirection="column"
          padding="24px"
          height="100%"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Title
            style={{ color: "white", fontWeight: "700", fontSize: "24px" }}
          >
            Speaker
          </Title>
          
          {speakers.map((value, index) => {
            return (
              <React.Fragment key={index}>
                <p
                  style={{
                    color: "white",
                    fontWeight: "400",
                    fontSize: "14px",
                  }}
                >{`${value.name}`}</p>
              </React.Fragment>
            );
          })}
        </Wrapper>
      </Grid>
    </div>
  );
};
export default EventBeforeView;