import React from "react";
import logoPolpo from "../../Assets/logo-polpo.svg";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__container">
        <div className="footer__center">
          <span className="footer__text">
            Desarrollado por{" "}
            <a
              style={{ marginLeft: "10px", textDecoration: "none", color: "grey" }}
              target="_blank"
              rel="noopener noreferrer" //Add this line, because security risk
              href="https://polpocr.com"
            >
              <img height="30px" src={logoPolpo} alt="pagina polpo" />
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;