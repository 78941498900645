import styled from "styled-components";
import { Menu as _Menu } from "antd";

const Menu = styled(_Menu)`
  .ant-dropdown-arrow {
    /* top: 6px; */
    border-top-color: rgb(20, 20, 20);
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: rgb(20, 20, 20);
    -webkit-box-shadow: -2px -2px 5px rgb(0 0 0 / 6%);
    box-shadow: -2px -2px 5px rgb(0 0 0 / 6%);
  }

  background-color: ${(props) =>
    props.backgroundColor || props.theme.menu.backgroundColor};
  font-size: ${(props) => props.fontSize || props.theme.menu.fontSize};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  box-shadow: ${(props) => props.boxShadow};
  border: ${(props) => props.border};
  font-family: ${(props) => props.fontFamily};
  width: ${(props) => props.width || props.theme.menu.width};
  color: ${(props) => props.color || props.theme.menu.color};
`;

export default Menu;
