//Libs
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
//Components
import { Wrapper, Avatar, FloatCameraButton } from "Components";
//Services
import { getProfilePhotoDefault } from "Services/UserService";
//Selectors
import { selectProfilePhotoUpdated } from "Redux/User/user-selectors";

function ProfilePhoto({
  // profilePhotoUpdated,
  dispatch,
  containerStyle,
  avatarStyle,
  cameraStyle,
  profilePhotoSrc
}) {
  const [profileDefaultPhotoSrc, setProfilePhoto] = useState();
  // useEffect(() => {
  //   if (Number.isInteger(userId) && userId !== 0) {
  //     getProfilePhoto(userId).then((photo) => setProfilePhoto(photo));
  //   } else {
  //     setProfilePhoto(ImageDefault);
  //   }
  // }, []);
  useEffect(() => {
    getProfilePhotoDefault().then((photo) => setProfilePhoto(photo));
  }, []);

  return (
    <Wrapper
      margin={containerStyle?.margin}
      bottom={containerStyle?.bottom}
      right={containerStyle?.right}
      position="relative"
      display="inline-block"
    >
        <Avatar
          width={avatarStyle?.width}
          height={avatarStyle?.height}
          src={profilePhotoSrc ? profilePhotoSrc : profileDefaultPhotoSrc}
        />
      <FloatCameraButton
        left={cameraStyle.left}
        bottom={cameraStyle.bottom}
        fontSize={cameraStyle.fontSize}
      />
    </Wrapper>
  );
}

const mapStateToProps = (state) => ({
  profilePhotoUpdated: selectProfilePhotoUpdated(state),
});

export default connect(mapStateToProps)(React.memo(ProfilePhoto));
