import { createSelector } from "reselect";

const selectCardiOneNews = (state) => state.news;

export const selectNewsCardiOneNews = createSelector(
  [selectCardiOneNews],
  (newsState) => newsState.news
);

export const selectLoadingNews = createSelector(
	[selectCardiOneNews],
	(newsState) => newsState.loadingNewData
);

export const selectTotalNewsItem = createSelector(
	[selectCardiOneNews],
	(newsState) => newsState.totalNewsItem
);

