/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useCallback } from "react";
import _ from "lodash";
import useNearScreen from "Hooks/useNearScreen";
import debounce from "just-debounce-it";
import { connect } from "react-redux";
import {
  Wrapper,
  Grid,
  Card,
  Modal,
  ErrorLabel,
  FilterAndSearchBar,
  FormEmail,
  Spinner,
  Title,
} from "Components";
//Services
import ContactService from "Services/ContactUsService";
//Selectors
import { selectCurrentUser } from "Redux/User/user-selectors";
import { selectSearchInputValue } from "Redux/Search/search-selectors";
import {
  selectCreateAppointment,
  selectContactData,
  selectTotalContact,
  selectLoadingAssistant
} from "Redux/ContactUs/assistant-selectors";
import { selectedOrganizationId } from "Redux/Organization/organization-select";
//Actions
import { resetSearchInputValue } from "Redux/Search/search-actions";
import {
  setCreateAppointment,
  saveContactData,
} from "Redux/ContactUs/assistant-actions";
import { filterData } from "Services/SearchInputService";

const ContactUs = ({
  currentUser,
  contacts,
  mobileView,
  createAppointment,
  setCreateAppointment,
  saveContactData,
  searchInputValue,
  resetSearchInputValue,
  organizationId,
}) => {

  const visorRef = useRef();
  const nowShowRef = useRef();
  const canObserveVisor = !selectLoadingAssistant && selectTotalContact > 0;
  const [isNearScreen] = useNearScreen({
    externalRef: canObserveVisor ? visorRef : null,
    rootMargin: "60%",
    observeOnce: false,
  });

  const handleNextpage = useCallback(
    debounce(
      () =>
        saveContactData(currentUser.token, {
          organizationId,
          lasRowContact: contacts.length
        }),
      200
    ),
    [contacts]
  );
  React.useEffect(() => {
    if (isNearScreen && canObserveVisor && contacts.length < selectTotalContact) {
      handleNextpage();
    }
  }, [isNearScreen]);


  React.useEffect(() => {
    saveContactData(currentUser.token, { organizationId });
    resetSearchInputValue();
  }, []);

  const filteredContacts = contacts ? filterData(contacts, searchInputValue, "users") : [];

  const [medicData, setMedicData] = useState({});
  const [modalMessage, setModalMessage] = useState({
    showModal: false,
    hideModal: false,
  });

  return !contacts ? (
    <Wrapper
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Title>No hay Directorio disponibles.</Title>
    </Wrapper>
  ) : contacts.length === 0 ? (
    <Spinner />
  ) : (
    <>
      <Wrapper
        ref={nowShowRef}
        alignItems="left"
        display="flex"
        flexDirection="column"
        margin="90px 10vw 10px 10vw"
      >
        <Modal
          visible={modalMessage.showModal}
          onCancel={() => setModalMessage({ hideModal: true })}
          footer={null}
        >
          <FormEmail
            email={medicData.correo}
            userId={currentUser.id}
            token={currentUser.token}
            organizationId={organizationId}
            contactId={medicData.contactId}
            companyId={medicData.companyId}
          />
        </Modal>

        <div style={{ marginBottom: "1em" }}>
          <h1
            style={{
              color: "#fff",
            }}
          >
            Directorio Médico Bayer
          </h1>
          <FilterAndSearchBar
            mobileView={mobileView}
            onEnterKeyPressed={() =>
              ContactService.searchInContacts(
                currentUser.token,
                searchInputValue,
                { organizationId }
              ).then((data) => saveContactData(data))
            }
          />
        </div>
        <Grid
          style={{ marginBottom: "120px" }}
          columns={`repeat(${mobileView ? 1 : 3}, calc(80vw/3))`}
          gap="1em 1em"
        >
          {Array.isArray(filteredContacts) &&
            _.orderBy(filteredContacts, (s) => s.name, ["asc"]).map(
              (contact, idx) => (
                <Card
                  width={mobileView ? "calc(80vw)" : "calc(80vw/3)"}
                  key={idx}
                  styles={{ backgroundColor: "#fff", cursor: "pointer" }}
                  bodyStyle={{ padding: "18px" }}
                  title={`${contact.name} ${contact.firstLastName || ""}`}
                  subtitle={contact.country.name}
                  email={contact.userCompany[0].email}
                  description={contact.userCompany[0].bio}
                  avatarSrc={contact.userCompany[0].profilePhotoUrl}
                  actions={[
                    <span
                      onClick={() => {
                        setMedicData({
                          correo: contact.userCompany[0].email,
                          contactId: contact.id,
                          companyId: contact.userCompany[0].companyId,
                        });
                        setModalMessage({ ...modalMessage, showModal: true });
                      }}
                    >
                      Enviar Correo
                    </span>,
                  ]}
                />
              )
            )}
        </Grid>
        {searchInputValue && filteredContacts.length === 0 && (
          <ErrorLabel color="#00B2F3" value="No existe el asistente buscado." />
        )}
      </Wrapper>
      {canObserveVisor && <div ref={visorRef} />}
    </>
  );
};

const mapStateToProps = (state) => ({
  createAppointment: selectCreateAppointment(state),
  contacts: selectContactData(state),
  searchInputValue: selectSearchInputValue(state),
  currentUser: selectCurrentUser(state),
  organizationId: selectedOrganizationId(state),
});

const mapDispatchToProps = (dispatch) => ({
  setCreateAppointment: (payload) => dispatch(setCreateAppointment(payload)),
  saveContactData: (token, whereOpts) =>
    dispatch(saveContactData(token, whereOpts)),
  resetSearchInputValue: () => dispatch(resetSearchInputValue()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
