import {
  CREATE_POLL,
  INCREASE_VOTE,
  SEND_MESSAGE,
  FINISH_SURVEY,
} from "./poll-types";

const initialState = {
  message: {
    isFetching: false,
    data: [],
  },
  poll: {
    isFetching: false,
    data: [],
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_POLL:
      return {
        ...state,
        poll: {
          data: [...state.poll.data, action.payload.data],
        },
      };
    // case INCREASE_VOTE_MULTI_ANSWER:
    case INCREASE_VOTE:
      return {
        ...state,
        poll: {
          data: action.payload,
        },
      };
    case SEND_MESSAGE:
      return {
        ...state,
        message: {
          data: [...state.message.data, action.payload.data],
        },
      };
    case FINISH_SURVEY:
      return {
        ...state,
        poll: {
          data: action.payload,
        },
      };

    default:
      return state;
  }
}
