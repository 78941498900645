import React from "react";
import { Typography } from "antd";
import "./CopyRight.css";

const CopyRight = () => {
  const { Title } = Typography;
  return (
    <div>
      <Title style={{ color: "#00B2F3", userSelect: "none" }} level={2}>
        Condiciones Generales de Uso
      </Title>
      <p className="color-paragraph" style={{ userSelect: "none" }}>
        El acceso a esta página Web y su uso están sujetos a las siguientes
        condiciones. Por favor, si no está de acuerdo con estas condiciones, no
        utilice la página Web. El Grupo Bayer en los Países Andinos,
        Centroamérica y El Caribe (en los sucesivo BAYER) ha creado la presente
        pagina web y se encarga de administrarla. Nos reservamos el derecho de
        eliminar o modificar total o parcialmente esta pagina Web a las
        Condiciones Generales de Uso en cualquier momento, según nuestro propio
        criterio y sin aviso previo. Por consiguiente, le rogamos que en su
        próxima visita a esta pagina Web vuelva a leer con detenimiento estas
        condiciones para conocer las modificaciones o adiciones que pueden
        haberse producido.
      </p>
      <Title style={{ color: "#00B2F3", userSelect: "none" }} level={5}>
        Cesión de usos y beneficios
      </Title>
      <p className="color-paragraph" style={{ userSelect: "none" }}>
        Toda la información, documentaos e imágenes publicados en esta pagina
        Web son propiedad exclusiva de BAYER. No se permite su uso salvo ciertas
        disposiciones y/o permisos que pudieran hacernos, siempre y cuando se
        mencione su fuente y aparezca el símbolo de copyright en todas las
        copias, el uso de la información será para fines meramente privados y no
        se use con fines comerciales, no se podrá modificar las informaciones en
        modo alguno, y el material grafico obtenido de la pagina Web se usa
        exclusivamente con conjunto con el texto que lo acompaña.
      </p>
      <Title style={{ color: "#00B2F3", userSelect: "none" }} level={5}>
        Marcas y derechos de propiedad intelectual
      </Title>
      <p className="color-paragraph" style={{ userSelect: "none" }}>
        Todas las marcas, avisos comerciales, fotografías, gráficos, diseños y
        demás signos distintivos y activos de propiedad intelectual mencionados
        en esta pagina Web son propiedad de BAYER, salvo que se indique lo
        contrario o que sus derechos correspondan a terceros. Nada de lo
        contenido en esta pagina debe interpretarse como una licencia de
        propiedad intelectual, por lo que Bayer declara que ninguno de los
        contenidos puede reproducirse salvo autorización expresa de Bayer
        declara que ninguno de los contenidos puede reproducirse salvo
        autorización expresa de Bayer. El uso no autorizado de estas marcas
      </p>
      <Title style={{ color: "#00B2F3", userSelect: "none" }} level={5}>
        Limitación de la responsabilidad
      </Title>
      <p className="color-paragraph" style={{ userSelect: "none" }}>
        La información recogida en esta pagina Web ha sido recopilado por BAYER
        a partir de fuentes internas y externas según su leal saber y entender y
        con la debida diligencia profesional. Nos esforzamos por ampliar y
        actualizar esta información de manera continua. La información contenida
        en esta pagina Web tiene como único propósito el de dar a conocer a
        BAYER, a sus productos y servicios. Sin embargo, no asumimos
        responsabilidad alguna- ya sea de forma expresa o tácita—respecto a la
        exhaustividad y exactitud de la información recogida en esta pagina Web.
        En particular, le recomendamos que tenga en cuenta que esta información
        puede haber quedado desfasada. Por este motivo, le aconsejamos que
        compruebe la información ofrecida en esta pagina Web no le exime de
        consultar nuestras recomendaciones más recientes – especialmente las
        contenidas en nuestras fichas de seguridad y en las fichas técnicas de
        nuestros productos-ni de comprobar si los productos son adecuados para
        los procedimientos o los fines previstos. Si necesita consejos o
        instrucciones especificas acerca de nuestros productos y servicios, no
        dude en contactarnos directamente. Los usuarios de esta pagina Web
        declaran su conformidad con el hecho de que el acceso a la página Web
        sus contenidos se realice bajo su propia responsabilidad. Ni BAYER ni
        los terceros que participen en la creación, producción o transmisión de
        esta página Web serán responsables de los danos o perjuicios que
        pudieran derivarse de acceder o de la imposibilidad de acceder a esta
        pagina Web y de su uso o de la imposibilidad de usarla, o del hecho que
        usted confié en algún dato tomado de esta página Web. La información de
        salud contenida aquí se proporciona solo para fines educativos y no esta
        destinada a reemplazar lo que podría discutirse con un medico tratante.
        Todas las decisiones relacionadas con la atención al paciente deben
        realizarse con un médico, teniendo en cuenta las características
        particulares del paciente. La información de productos proporcionada en
        este sitio podría no estar dirigida para su país por lo que debe
        dirigirse a su medico o a la autoridad de salud local para obtener
        información sobre las indicaciones aprobadas en su país. Los productos
        mencionados aquí pueden tener etiquetados diferentes en otros países.
      </p>
      <Title style={{ color: "#00B2F3", userSelect: "none" }} level={5}>
        Usuarios internacionales
      </Title>
      <p className="color-paragraph" style={{ userSelect: "none" }}>
        BAYER comprueba, gestiona y actualiza esta pagina Web desde sus oficinas
        en los Países Andinos, Centroamérica y El Caribe. La pagina ha sido
        concebida exclusivamente para su uso en los Países Andinos,
        Centroamericana y El Caribe. BAYER no garantiza que la información
        incluida en esta pagina Web sea correcta en todo el mundo ni, en
        particular, que los productos y servicios presentados estén disponibles
        en otros países con la misma presentación, tamaño y en las mismas
        condiciones. En caso de que usted acceda a esta pagina Web o descargue
        datos de la página, tenga en cuenta que asumirá la responsabilidad de
        que dichas actuaciones sean compatibles con la legislación vigente en
        ese lugar.
      </p>
      <Title style={{ color: "#00B2F3", userSelect: "none" }} level={5}>
        Paginas Web con log-in: derechos de acceso
      </Title>
      <p className="color-paragraph" style={{ userSelect: "none" }}>
        Observe por favor que usted esta obligado a proteger sus derechos de
        acceso del uso de terceros no autorizados y que debe asegurarse que
        ellos no pueden ser usados por otros sin su autorización. Debe notificar
        de inmediato a BAYER si se entera de cualquier violación con respecto a
        cualesquiera detalles disponibles en el sitio web de BAYER o si personas
        no autorizadas obtuvieron sus derechos de acceso o si ha descubierto
        indicios de la probabilidad de dicho acceso.
      </p>
    </div>
  );
};
export default CopyRight;
