//Libs
import React from "react";
import { connect } from "react-redux";
//Actions
import { setSearchInputValue } from "Redux/Search/search-actions";
//Selectors
import { selectSearchInputValue } from "Redux/Search/search-selectors";

const FilterAndSearchBar = ({
  //Internal Props
  searchInputValue,
  setSearchInputValue,
  //External Props
  mobileView,
  onEnterKeyPressed,
}) => {
  function enterEvent(event) {
    if (event.key === "Enter") {
      onEnterKeyPressed && onEnterKeyPressed();
    }
  }

  return (
    <input
      name="name"
      value={searchInputValue}
      onKeyPress={enterEvent}
      onChange={(event) =>
        setSearchInputValue(event.target.value?.toLowerCase())
      }
      style={{
        width: `${mobileView ? "100%" : "calc(100% + 2em)"}`,
        border: "none",
        paddingLeft: "10px",
        height: "40px",
      }}
      type="text"
      placeholder="Filtrar por nombre..."
    />
  );
};

const mapStateToProps = (state) => ({
  searchInputValue: selectSearchInputValue(state),
});

const mapDispatchToProps = (dispatch) => ({
  setSearchInputValue: (value) => dispatch(setSearchInputValue(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterAndSearchBar);
