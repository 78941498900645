//Services
import FetchPlus from "Services/FetchPlus";
//Config
import { API_URL } from "Config/config";

const directoryBase = `${API_URL}organizations`;

export default class DirectoryService {
  static fetchDirectoryData(
    token,
    { usersLimit = 20, organizationId,LastRowSpeaker = 0 } = {}
  ) {
    const headers = {
      Authorization: token,
    };

    if (LastRowSpeaker !== 0) {
      usersLimit = usersLimit + LastRowSpeaker;
    }

    return FetchPlus(
      `${directoryBase}/${organizationId}/directory?page=0&size=${usersLimit}`,
      {
        headers,
      }
    )
      .then((response) => response)
      .catch((error) => {
        if (error === 401) {
          return error;
        }
        console.log(error)
      });
  }

  static searchInDirectory(
    token,
    searchInputValue,
    { usersLimit = 10, usersLastRow = 0, organizationId } = {}
  ) {
    return FetchPlus(
      `${directoryBase}/${organizationId}/directory?page=${usersLastRow}&size=${usersLimit}&search=${searchInputValue}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    )
      .then((response) => response.directory.rows)
      .catch((error) => console.log(error));
  }
}
