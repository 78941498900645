import React, { useEffect } from "react";
//redux
import { connect } from "react-redux";
import {
  selectFetching,
  selectOrganizacion,
  // selectCssStyleCompany,
  selectCompanyModule,
  selectSubModulos,
} from "Redux/DynamicConfigCompany/dcc-select";
import { setCompanyData } from "Redux/DynamicConfigCompany/dcc-action";
//service
import { CompanyService } from "Services/CompanyService";
//css style
import themeCompany from "Themes/themeCompany";

const DynamicCompanyConfig = ({
  isFetching,
  organizacion,
  // themeCompany,
  modules,
  setCompanyServiceData,
  children,
  subModulos
}) => {
  useEffect(() => {
    CompanyService.getCompanyData(window.location.host).then((data) =>
      setCompanyServiceData(data)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (isFetching) return null;

  //Aca se comprueba si el sitio tiene un tema asignado

  if (!themeCompany || !modules || !organizacion) {
    return (
      <div style={{ color: "white" }}>
        No hemos encontrado una configuración para:
        <b>"{window.location.host}"</b>
        <p>1) Tiene que asignarle un estilo al sitio</p>
        <p>2) Los modulos se encuentran vacio</p>
        <p>3) La organizacion usuario se encuentran vacio</p>
        <p>3) El sitio tiene que estar registrado en la base de dato</p>
      </div>
    );
  }
  return children({ organizacion, modules, themeCompany,subModulos });
};

const mapStateToProps = (state) => ({
  organizacion: selectOrganizacion(state),
  // themeCompany: selectCssStyleCompany(state),
  modules: selectCompanyModule(state),
  subModulos: selectSubModulos(state),
  isFetching: selectFetching(state),
});

const mapDispatchToProps = (dispatch) => ({
  setCompanyServiceData: (data) => dispatch(setCompanyData(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DynamicCompanyConfig);
