import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledLink = styled(Link)`
  margin: ${(props) => props.margin || props.theme.link.margin};
  color: ${(props) => props.color || props.theme.link.color};
  font-weight: 550;
  letter-spacing: 0.2px;
  &:hover {
    color: #00B2F3;
  }
`;

export default StyledLink;
