import { newsStype } from "./new-cardi-one-types";

const initialState = {
  news: [],
  isFetching: false,
  loadingNewData: false,
  totalNewsItem: false
};
const newCardiOneReducer = (state = initialState, action) => {
  switch (action.type) {
    case newsStype.GET_LAST_CARDI_ONE_NEWS:
      return {
        ...state,
        news: action.payload,
        loadingNewData: false
      };
    case newsStype.LOADING_NEWS_DATA:
      return {
        ...state,
        loadingNewData: action.payload
      }
    case newsStype.TOTAL_NEWS_ITEMS:
      return {
        ...state,
        totalNewsItem: action.payload
      }
    case newsStype.CLEAR_NEWS:
      return {
        news: [],
        isFetching: false,
        loadingNewData: false,
        totalNewsItem: false
      }

    default:
      return state;
  }
};

export default newCardiOneReducer;
