import React from "react";
import { Wrapper, Image } from "Components";
import { Typography } from "antd";

const ContactInfoModal = ({ contact }) => {
  const { Title } = Typography;
  const resources = [
    {
      name: "Video 01",
    },
    {
      name: "Image 01",
    },
    {
      name: "Podcast 01",
    },
  ];
  return (
    <Wrapper display="flex" flexDirection="column">
      <Wrapper display="flex" flexDirection="row">
        <Wrapper
          display="flex"
          flexDirection="column"
          padding="24px"
          alignItems="flex-start"
          justifyContent="flex-start"
          width="60%"
        >
          <Title
            style={{ color: "white", fontWeight: "700", fontSize: "24px" }}
          >
            {`Dr. ${contact.title}`}
          </Title>
          <p style={{ color: "white", fontWeight: "700", fontSize: "14px" }}>
            Location: {contact.subtitle}
          </p>
          <p style={{ color: "white", fontWeight: "700", fontSize: "14px" }}>
            Email: {contact.description}
          </p>
          <p style={{ color: "white", fontWeight: "400", fontSize: "14px" }}>
            Lorem ipsum dolor sit amet consectetur adipiscing elit sem neque
            quisque aenean penatibus, enim nam sed interdum tincidunt morbi
            vestibulum hendrerit dapibus erat cras. Nascetur senectus ac tortor
            arcu nostra venenatis parturient euismod integer bibendum non nec et
            per duis, faucibus aptent nisl a eros potenti sociis cum cursus enim
            sollicitudin curae iaculis primis. Malesuada nostra hendrerit mollis
            nunc ultrices lacinia parturient duis.
          </p>
        </Wrapper>
        <Wrapper
          display="flex"
          flexDirection="column"
          padding="24px"
          height="100%"
          width="40%"
          alignItems="flex-start"
        >
          <Image
            src={contact.avatarSrc}
            padding="10px"
            height="180px"
            width="150px"
          />

          <Title
            style={{ color: "white", fontWeight: "700", fontSize: "18px" }}
          >
            Resources
          </Title>
          <ul style={{ listStyle: "none" }}>
            {resources.map((value, idx) => {
              return (
                <li
                  key={idx}
                  style={{
                    color: "white",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                >
                  {value.name}
                </li>
              );
            })}
          </ul>
        </Wrapper>
      </Wrapper>
    </Wrapper>
  );
};
export default ContactInfoModal;
