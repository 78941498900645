import styled, { css } from "styled-components";

const Label = styled.span`
  display: inline-block;
  color: #e5e5e5;
  font-weight: 550;
  background-color: transparent;
  line-height: 1.2;
  text-decoration: none;
  font-size: ${(props) => props.fontSize || "1em"}; //0.9rem;;
  letter-spacing: 0.2px;
  margin: ${(props) => props.margin || 0};
  &:hover {
    color: ${(props) => props.colorhover || "#a9a9a9"};
    transition: 0.4s all;
  }
  ${(props) =>
    props.pointer &&
    css`
      cursor: pointer;
      &:hover {
        color: #a9a9a9;
        transition: 0.4s all;
      }
    `}
`;

export default Label;
