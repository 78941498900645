//Libs
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
//Config
import { ROUTES } from "Config/config";

const StyledContainer = styled.div`
  width: ${(props) => props.theme.goToSignin.container.width};
  display: ${(props) => props.theme.goToSignin.container.display};
  justify-content: ${(props) =>
    props.theme.goToSignin.container.justifyContent};
`;
const StyledQuestion = styled.span`
  color: ${(props) => props.theme.goToSignin.question.color};
  text-align: ${(props) => props.theme.goToSignin.question.textAlign};
  margin-right: ${(props) => props.theme.goToSignin.question.marginRight};
`;
const StyledLink = styled(Link)`
  color: ${(props) => props.theme.goToSignin.link.color};
  text-decoration: ${(props) => props.theme.goToSignin.link.textDecoration};
`;

export default function GoToSignin() {
  return (
    <StyledContainer>
      <StyledQuestion>¿Ya tiene cuenta?</StyledQuestion>
      <StyledLink to={ROUTES.SIGNIN}>Ingresar.</StyledLink>
    </StyledContainer>
  );
}
