import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import qs from "query-string";
import { CgSpinner } from "react-icons/cg";
import "./Home.css";
//Actions
import { setHideTour, changeModalUserInfoState } from "Redux/User/user-actions";
import { getRooms, resetRooms } from "Redux/Room/room-actions";
import {
  getOptimizedStories,
  resetStories,
} from "Redux/Stories/stories-actions";
import { getCategories } from "Redux/Categories/categories-actions";
//Selectors
import {
  selectCurrentUser,
  selectIsTourOpen,
} from "Redux/User/user-selectors";
import { selectedOrganizationId } from "Redux/Organization/organization-select";
import { selectTotalStoryItem } from "Redux/Stories/stories-selectors";
//Components
// import Tour from "reactour";
import { Wrapper, AreaDivisorText, Spinner } from "Components";
import { selectIsRoomsFetching } from "Redux/Room/room-selectors";
import {
  selectTotalCategoryCount,
  selectIsFetchingCategoriesData,
} from "Redux/Categories/categories-selectors";

const RoomsGrid = React.lazy(() => import("Components/RoomsGrid"));
const ResourceCategories = React.lazy(() =>
  import("Components/Resources/ResourceCategories")
);
const Stories = React.lazy(() => import("Components/Stories/StoriesCarousel"));
const Footer = React.lazy(() => import("Components/Footer/Footer"));

// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

function Home({
  currentUser,
  isTourOpen,
  getRooms,
  getOptimizedStories,
  getCategories,
  resetStories,
  location,
  mobileView,
  slices,
  sliceWidth,
  sliceHeight,
  sliceContainerHeight,
  setHideTour,
  resetRooms,
  resetCategories,
  organizationId,
  changeStateModal,
  isRoomsLoading,
  totalCategoryCount,
  totalStoryItem,
  loadingCategories,
}) {
  useEffect(() => {
    if (currentUser && organizationId) {
      getRooms(currentUser.token, { organizationId });
      getOptimizedStories(currentUser.token, currentUser.id, organizationId);
      getCategories(currentUser.token, { organizationId });
    }
    const ref = qs.parse(location.search, { ignoreQueryPrefix: true }).ref
      ? qs.parse(location.search, { ignoreQueryPrefix: true }).ref
      : "";

    if (ref) {
      setTimeout(function () {
        const refElement = document.getElementById(ref);
        if (refElement) {
          refElement.scrollIntoView({ block: "center", behavior: "smooth" });
        }
      }, 1000);
    }

    return () => {
      resetStories();
      resetRooms();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  const size = useWindowSize();

  return (
    organizationId !== null &&
    (isRoomsLoading || totalCategoryCount === null ? (
      <Spinner />
    ) : (
      <Wrapper className="animate__animated animate__fadeIn animate__slow">
        <div data-tour="first-step" />
        <div data-tour="rooms-step" />
        <RoomsGrid size={size} mobileView={mobileView} />
        {
          size.width/size.height > 1.979038224414303 &&
          <div className='icon-scroll'></div>
        }
        <Wrapper
          margin={`${
            mobileView
              ? "3vw"
              : totalStoryItem === 0
                  ? (size.width/size.height > 1.64375 && size.width/size.height < 1.979038224414303
                      ? (size.height - size.width / 1.8 > 0
                          ? `calc(-3vw + ${size.height}px - ${size.width / 1.8}px - ${size.width / 5 / 1.8 - 30}px)`
                          : `calc(-3vw - ${size.height}px + ${size.width / 1.8}px - ${size.width / 5 / 1.8 - 30}px)`
                        )
                      : "3vw"
                    )
                  : (size.height < 0.5625 * size.width
                      ? `${0.5625 * size.width - size.height + 30}px`
                      : "3vw")
              } 0 8vw 0`}
        >
          <div data-tour="stories-step" />
          {totalStoryItem > 0 ||
            (currentUser.isSponsor && (
              <AreaDivisorText>Sigue las historias</AreaDivisorText>
            ))}
          <Stories organizationId={organizationId} />
          <ResourceCategories
            screenSize={size}
            mobileView={mobileView}
            organizationId={organizationId}
            slices={slices}
            sliceWidth={sliceWidth}
            sliceHeight={sliceHeight}
            sliceContainerHeight={sliceContainerHeight}
          />
        </Wrapper>
        <Wrapper
          width="100%"
          height="auto"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          style={{ color: "white", fontSize: "60px", marginBottom: "50px" }}
        >
          {loadingCategories && <CgSpinner className="spin_more_Categorie" />}
        </Wrapper>
        <Footer />
      </Wrapper>
    ))
  );
}

const mapStateToProps = (state) => ({
  currentUser: selectCurrentUser(state),
  isTourOpen: selectIsTourOpen(state),
  organizationId: selectedOrganizationId(state),
  isRoomsLoading: selectIsRoomsFetching(state),
  totalCategoryCount: selectTotalCategoryCount(state),
  totalStoryItem: selectTotalStoryItem(state),
  loadingCategories: selectIsFetchingCategoriesData(state),
});

const mapDispatchToProps = (dispatch) => ({
  getRooms: (token, whereOpts) => dispatch(getRooms(token, whereOpts)),
  getOptimizedStories: (token, userId, organizationId) =>
    dispatch(getOptimizedStories(token, userId, organizationId)),
  getCategories: (token, organizationId) =>
    dispatch(getCategories(token, organizationId)),
  setHideTour: () => dispatch(setHideTour()),
  resetStories: () => dispatch(resetStories()),
  resetRooms: () => dispatch(resetRooms()),
  changeStateModal: (state) => dispatch(changeModalUserInfoState(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Home));
