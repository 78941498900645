import { Checkbox } from "antd";
import React from "react";

export default function CheckboxesGroup({
  handleEndSurvey,
  answerOptions,
  checkAnswer,
  onAnswerPoll,
  isMultiAnswer
}) {
  const [value, setValue] = React.useState();

  function onChange(checkedValues) {
    setValue(checkedValues);
  }

  const handleOnSendPollAnswer = (value) => {
    checkAnswer();
    onAnswerPoll(value,isMultiAnswer, true);
  };
  return (
    <Checkbox.Group style={{ width: "100%" }} onChange={onChange}>
      <div>
        {answerOptions.map((option) => (
          <div className="h5">
            <Checkbox value={option}>{option.name}</Checkbox>
          </div>
        ))}
          <div className="col-sm ml-1">
            <button
              onClick={() => handleOnSendPollAnswer(value)}
              className="btn btn-success col-sm"
            >
              Enviar
            </button>
          </div>
        </div>
    </Checkbox.Group>
  );
}
