const CategoryActionTypes = {
	//Categories
	SET_CATEGORY_DATA: "SET_CATEGORY_DATA",
	SET_LOADING_CATEGORY_DATA: "CATEGORIES_SET_LOADING_CATEGORY_DATA",
	//Videos
	SET_MORE_ADDITIONAL_VIDEOS: "CATEGORIES_SET_MORE_ADDITIONAL_VIDEOS",
	SET_LOADING_MORE_ADDITIONAL_VIDEOS:
		"CATEGORIES_SET_LOADING_MORE_ADDITIONAL_VIDEOS",
	// RESET: "CATEGORIES_RESET",
	SET_LOADING_MORE_RESOURCE: "SET_LOADING_MORE_RESOURCE",
	CLEAR_CATEGORIES_ORGANIZATION: "CLEAR_CATEGORIES_ORGANIZATION",
	//RECOMMEND RESOURCE CATEGIRIE
	GET_RECOMMEND_RESOURCE_ITEM: "GET_RECOMMEND_RESOURCE_ITEM",
	CLEAR_CATEGORIES:"CLEAR_CATEGORIES",
	GET_MORE_VIEW_RESOURCE: "GET_MORE_VIEW_RESOURCE"
};

export default CategoryActionTypes;
