import SearchActionTypes from "./search-types";

export const setSearchInputValue = (value) => ({
  type: SearchActionTypes.SET_INPUT_SEARCH,
  payload: value,
});

export const resetSearchInputValue = () => ({
  type: SearchActionTypes.RESET_SEARCH_INPUT_VALUE,
});
