import SearchActionTypes from "./search-types";

const INITIAL_STATE = {
  value: null,
};

const searchReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SearchActionTypes.SET_INPUT_SEARCH: {
      return {
        ...state,
        value: payload,
      };
    }
    case SearchActionTypes.RESET_SEARCH_INPUT_VALUE: {
      return {
        ...state,
        value: "",
      };
    }
    default:
      return state;
  }
};

export default searchReducer;
