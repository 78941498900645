import styled from "styled-components";

export default styled.form`
  background-image: ${(props) =>
    props.backgroundImage
      ? props.backgroundImage
      : props.theme.form.backgroundImage};
  width: ${(props) => (props.width ? props.width : props.theme.form.width)};
  height: ${(props) => (props.height ? props.height : props.theme.form.height)};
  position: ${(props) =>
    props.position ? props.position : props.theme.form.position};
  display: ${(props) =>
    props.display ? props.display : props.theme.form.display};
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : props.theme.form.flexDirection};
  justify-content: ${(props) =>
    props.justifyContent
      ? props.justifyContent
      : props.theme.form.justifyContent};
  align-items: ${(props) =>
    props.alignItems ? props.alignItems : props.theme.form.alignItems};
  margin: ${(props) => (props.margin ? props.margin : props.theme.form.margin)};
  padding: ${(props) =>
    props.padding ? props.padding : props.theme.form.padding};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : props.theme.form.borderRadius};
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : props.theme.form.boxShadow};
`;
