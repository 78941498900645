export const shouldRenderModule = (currentUser, module) => {
  return (
    (currentUser && module.isPrivate) || (!currentUser && !module.isPrivate)
  );
};

export const shouldRenderHeader = (modules = [], currentRoute = "") => {
  //I'm at home
  if (currentRoute === "/") {
    const module = modules.find(({ path }) => path === currentRoute);
    return !module?.isHeaderBarHidden;
  } else {
    const module = modules.find(({ path }) => {
      //Get first word from currentRoute
      const splittedRoute = currentRoute.split("/")[1];
      return path?.includes(splittedRoute);
    });
    return !module?.isHeaderBarHidden;
  }
};
