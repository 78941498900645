//Services
import FetchPlus from "Services/FetchPlus";
//Config
import { API_URL, COMPANY_URL } from "Config/config";

//urlEndpoint
const companiesBase = `${API_URL}companies`;

export class CompanyService {
  //Get company
  static getCompanyData() {
    return FetchPlus(`${companiesBase}/${COMPANY_URL}`)
      .then((data) => data || {})
      .catch((err) => {
        console.log(err);
      });
  }
}
export async function getProfessions(organizationId, companyId) {
  const professions = await FetchPlus(
    `${companiesBase}/${COMPANY_URL}/professions`
  );
  return professions.professions || [];
}

export async function getInterests(organizationId, companyId) {
  const interests = await FetchPlus(
    `${companiesBase}/${COMPANY_URL}/interests`
  );
  return interests.interests || [];
}
