import React from "react";
import styled, { css } from "styled-components";

const ContainerButton = styled.div`
  margin: ${(props) =>
    props.margin ? props.margin : props.theme.button.container.margin};
  width: ${(props) => props.theme.button.container.width};
  display: ${(props) => props.theme.button.container.display};
  justify-content: ${(props) => props.theme.button.container.justifyContent};
`;

const ButtonStyled = styled.button`
  ${(props) =>
    props.primary &&
    css`
      width: ${(props) =>
        props.width ? props.width : props.theme.button.primary.width};
      min-width: ${(props) => props.theme.button.primary.minWidth};
      height: ${(props) => props.theme.button.primary.height};
      line-height: ${(props) => props.theme.button.primary.lineHeight};
      padding: ${(props) => props.theme.button.primary.padding};
      font-size: ${(props) => props.theme.button.primary.fontSize};
      font-weight: ${(props) => props.theme.button.primary.fontWeight};
      color: ${(props) => props.theme.button.primary.color};
      background-color: ${(props) =>
        props.backgroundColor || props.theme.button.primary.backgroundColor};
      border: ${(props) => props.theme.button.primary.border};
      border-radius: ${(props) => props.theme.button.primary.borderRadius};
      cursor: ${(props) => props.theme.button.primary.cursor};
      display: ${(props) => props.theme.button.primary.display};
      justify-content: ${(props) => props.theme.button.primary.justifyContent};
    `}
`;

export default function Button(props) {
  const { children, container } = props;

  return (
    <ContainerButton {...container}>
      <ButtonStyled {...props}>{children}</ButtonStyled>
    </ContainerButton>
  );
}
