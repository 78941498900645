import dotenv from "dotenv";
dotenv.config();

const APPLICATION_NAME = process.env.REACT_APP_APPLICATION_NAME || "polpoflix";
const COMPANY_URL = process.env.REACT_APP_COMPANY_URL || "localhost:3000";
const API_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_API_URL_DEV;
//DEFAUL_URL
const USER_EMAIL = process.env.USER_DEFAUL_EMAIL || "polpoflix@polpocr.com";
const USER_PASSWORD = process.env.USER_DEFAUL_PASSWOR || "polpoflix";

const NUMBER_OF_REQUEST = process.env.REACT_APP_NUMBER_OF_REQUEST;
const CLOUD_STORAGE_URL = process.env.REACT_APP_CLOUD_STORAGE_URL || "";
const DELAY_INITIAL_LOADER = process.env.REACT_APP_DELAY_INITIAL_LOADER || 2000;

const WS_STREAM_URL = process.env.REACT_APP_WS_STREAM_URL;
const STREAM_PLAYER_URL = process.env.REACT_APP_STREAM_PLAYER_URL;
const WEBRTC_DELAY_LOOP_REQUEST =
  process.env.REACT_APP_WEBRTC_DELAY_LOOP_REQUEST || 5000;
const WEBRTC_BACKGROUND_IMAGE = process.env.REACT_APP_WEBRTC_BACKGROUND_IMAGE;
const WEBRTC_FINAL_IMAGE = process.env.REACT_APP_WEBRTC_FINAL_IMAGE;
const WEBRTC_VIDEO_NOT_READY = process.env.REACT_APP_WEBRTC_VIDEO_NOT_READY;
const TAWK_PROPERTY_ID = process.env.REACT_APP_TAWK_PROPERTY_ID;
const TAWK_SITE_ID = process.env.REACT_APP_TAWK_SITE_ID;

const MOBILE_VIEWPORT_SIZE =
  parseInt(process.env.REACT_APP_MOBILE_VIEWPORT_SIZE) || 800;

const DEVICE_IS_MOBILE =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
const LOCAL_STORAGE_KEYS = {
  ApiToken: "api_token",
  ApiUser: "api_user",
  RedirectRef: "redirect_ref",
};

const SETTING = {
  SHOW_MODAL: "showModal"
}
//ROUTES
const ROUTES = {
  //LOGIN ROUTES
  HOME: "/",
  ROOM: "/room",
  VIDEO: "/video",
  LIFE_ROOM: "/life-room",
  SEARCH_RESULTS: "/searchresults",
  SPONSOR: "/patrocinador",
  SPEAKERS: "/expositores",
  CONTACTUS: "/contactenos",
  DIRECTORY: "/directorio",
  NEWS: "/noticias",
  EDIT_PROFILE: "/user",

  //LOGOUT ROUTES
  SIGNIN: "/sign-in",
  SIGNUP: "/sign-up",
  RESET_PASSWORD: "/reset-password",
  FORGOT_PASSWORD: "/recover-account",
  ORGANIZATION: "/organizations",
};
export {
  APPLICATION_NAME,
  COMPANY_URL,
  API_URL,
  NUMBER_OF_REQUEST,
  MOBILE_VIEWPORT_SIZE,
  DEVICE_IS_MOBILE,
  ROUTES,
  LOCAL_STORAGE_KEYS,
  WS_STREAM_URL,
  STREAM_PLAYER_URL,
  CLOUD_STORAGE_URL,
  DELAY_INITIAL_LOADER,
  WEBRTC_DELAY_LOOP_REQUEST,
  WEBRTC_BACKGROUND_IMAGE,
  WEBRTC_FINAL_IMAGE,
  WEBRTC_VIDEO_NOT_READY,
  TAWK_PROPERTY_ID,
  TAWK_SITE_ID,
  SETTING,
  USER_EMAIL,
  USER_PASSWORD
};
