//Libs
import React, { Component, createRef, Fragment } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { selectRooms } from "Redux/Room/room-selectors";
import Fade from "react-reveal/Fade";
//Utils
import { TitleHide } from "Utils/TitleHide";
import { LeftArrow, RightArrow } from "Utils/ScrollArrows";
//Components
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "Components";
import CollectionItem from "Components/CollectionItem/CollectionItem";
import downloadImage from "Assets/download.png";
//Styles
import "./CollectionPreview.css";

//Mockup Data //FIXME
import data2 from "./data2.json";

class CollectionPreview extends Component {
  constructor() {
    super();
    this.divRef = createRef();
    this.state = {
      displayRightArrow: false,
      paddingLeft: "80px",
    };
  }

  componentDidMount() {
    var node = ReactDOM.findDOMNode(this); //FIXME: Realizar este procedimiento sin utilizar ReactDOM
    TitleHide(node);
  }

  onLeftClick = () => {
    LeftArrow(this.divRef);
  };

  onRightClick = () => {
    RightArrow(this.divRef);
    this.setState({
      displayRightArrow: true,
      paddingLeft: "0px",
    });
  };

  render() {
    const { title, rooms, start, end, movies, tvshow } = this.props;
    const { displayRightArrow, paddingLeft } = this.state;
    // rooms = data.slice(start, end);
    const tvData = data2.slice(start, end);
    return (
      <Fade>
        <div className="collection-preview">
          <h1 className="collection-preview__title">
            {title}
            {title.indexOf("Conferencias ") !== -1 && (
              <Fragment>
                <a
                  style={{
                    textDecoration: "none",
                    color: "#fff",
                    fontSize: "15px",
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.wsna.org/calendar/export/event/uwcne-conference-2019-diabetes-update-1"
                >
                  <img
                    style={{
                      height: "20px",
                      position: "relative",
                      top: "2px",
                      cursor: "pointer",
                    }}
                    src={downloadImage}
                    alt=""
                  />
                  Descargar agenda
                </a>
              </Fragment>
            )}
            {title === "Feria Virtual" && (
              <span
                style={{
                  marginLeft: "15px",
                  fontSize: "15px",
                  fontWeight: "normal",
                }}
              >
                Promociones | Productos | Artículos | Videos | Información
              </span>
            )}
          </h1>
          <div style={{ paddingLeft }} className="collection-preview__box">
            {displayRightArrow && (
              <span className="collection-preview__left-arrow">
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  className="collection-preview__left-arrow-icon"
                  onClick={this.onLeftClick}
                />
              </span>
            )}
            <span className="collection-preview__right-arrow">
              <FontAwesomeIcon
                icon={faChevronRight}
                className="collection-preview__right-arrow-icon"
                onClick={this.onRightClick}
              />
            </span>
            {/* <div className="collection-preview__inner" ref={this.divRef}>
              {movies
                ? rooms.map((item, index) => (
                    <CollectionItem
                      key={item.id}
                      item={item}
                      movies={movies}
                      tvshow={tvshow}
                      index={
                        title.indexOf("Conferencias ") !== -1 ? index : null
                      }
                    />
                  ))
                : null}
              {tvshow
                ? tvData.map((item, index) => (
                    <CollectionItem
                      key={item.id}
                      item={item}
                      movies={movies}
                      tvshow={tvshow}
                      index={
                        title.indexOf("Conferencias ") !== -1 ? index : null
                      }
                    />
                  ))
                : null}
            </div> */}

            <Grid
              height="150px"
              ref={this.divRef}
              columns="repeat(auto-fit, minmax(258px, 1fr))"
              gridAutoFlow="column"
              gap="0 0.2em"
              overflowX="auto"
            >
              {movies &&
                rooms.map((item, index) => (
                  <CollectionItem
                    key={item.id}
                    item={item}
                    movies={movies}
                    tvshow={tvshow}
                    index={title.indexOf("Conferencias ") !== -1 ? index : null}
                  />
                ))}
              {tvshow &&
                tvData.map((item, index) => (
                  <CollectionItem
                    key={item.id}
                    item={item}
                    movies={movies}
                    tvshow={tvshow}
                    index={title.indexOf("Conferencias ") !== -1 ? index : null}
                  />
                ))}
            </Grid>
          </div>
        </div>
      </Fade>
    );
  }
}

const mapStateToProps = (state) => ({
  rooms: selectRooms(state),
});

export default connect(mapStateToProps)(CollectionPreview);
