import { createSelector } from "reselect";

const selectAssistant = (state) => state.assistant;

export const selectCreateAppointment = createSelector(
  [selectAssistant],
  (assistant) => assistant.createAppointment
);

export const selectContactData = createSelector(
  [selectAssistant],
  (assistant) => assistant.control.data
);

export const selectLoadingAssistant = createSelector(
	[selectAssistant], (assistant) => assistant.loadingAssitant
);

export const selectTotalContact = createSelector(
	[selectAssistant], (assistant) => assistant.totalCountContact
);
