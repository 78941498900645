//Libs
import React from "react";
import { Link, withRouter, Redirect } from "react-router-dom";
//Components
import Spinner from "Components/Spinner/Spinner";
import Background from "../../Assets/Background.jpg";
import FormInput from "../../Components/FormInput/FormInput";
import CustomButton from "../../Components/CustomButton/CustomButton";
//Services
import { resetPassword } from "Services/UserService";
//Config File
import { ROUTES } from "Config/config";
//Styles
import "./ResetPassword.css";
//redux
import {connect} from "react-redux";
import {selectTokenPassWord} from "Redux/User/user-selectors";
import {logout} from "Redux/User/user-actions"

class ResetPassword extends React.Component {
  constructor(props) {
    super();
    this.companyId = props.match.params.email;
    this.email = props.match.params.code;

    this.state = {
      loading: false,
      password: "",
      confirmPassword: "",
      passwordDontMatch: false,
      passwordInvalid: false,
      resetPasswordsuccessful: null,
    };
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    const { password, confirmPassword } = this.state;
    if (password.length < 8) {
      this.setState({ passwordInvalid: true });
      return;
    }
    if (password !== confirmPassword) {
      this.setState({ passwordDontMatch: true, passwordInvalid: false });
      return;
    }

    try {

      this.setState({ loading: true, passwordDontMatch: null });
      const resetPasswordsuccessful = await resetPassword(
        this.props.token,
        this.companyId,
        password
      );
      this.setState({ resetPasswordsuccessful, loading: false });
    } catch (error) {
      console.error(error);
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({ [name]: value });
  };

  render() {
    const {
      loading,
      password,
      confirmPassword,
      passwordDontMatch,
      passwordInvalid,
      resetPasswordsuccessful,
    } = this.state;

    if (resetPasswordsuccessful) {
      this.props.logout();
    }

    if (resetPasswordsuccessful) return <Redirect to={ROUTES.SIGNIN} />;

    return (
      <div className="signup">
        {loading && <Spinner />}
        <div
          className="signup__bg"
          style={{
            backgroundImage: `url(${Background})`,
            backgroundSize: "cover",
          }}
        />
        <div className="signup__container">
          <div className="signup__shadow">
            <h2 className="signup__title" tyle={{margin: "10px 10px 10px 10px"}}>Nueva Contraseña</h2>
            <form action="POST" onSubmit={this.handleSubmit}>
              <div style={{width: "100%", textAlign: "center"}}>
                <h5 className="signup__subtitle" style={{margin: "10px 10px 10px 10px"}}>Cuenta {this.email}</h5>
              </div>

              <FormInput
                name="password"
                type="password"
                maxLength={50}
                minLength={8}
                value={password}
                onChange={this.handleChange}
                label="Contraseña"
                required
              />

              <FormInput
                name="confirmPassword"
                type="password"
                maxLength={50}
                minLength={8}
                value={confirmPassword}
                onChange={this.handleChange}
                label="Confirmar Contraseña"
                required
              />

              <div className="signup__btn-container">
                <div className="signup__btn">
                  <CustomButton type="submit">Cambiar</CustomButton>
                </div>
              </div>
            </form>

            {resetPasswordsuccessful === false && (
              <div className="forgot__error-container">
                <span className="forgot__error">
                  Hubo un problema. Por favor vuelva a intentarlo
                </span>
              </div>
            )}

            {passwordDontMatch && (
              <div className="forgot__error-container">
                <span className="forgot__error">
                  Las contraseñas no coinciden
                </span>
              </div>
            )}

            {passwordInvalid && (
              <div className="forgot__error-container">
                <span className="forgot__error">
                  Debe tener una longitud mínima de 8 caracteres
                </span>
              </div>
            )}

            <div className="signup__option">
              <span className="signup__option--newuser">
                ¿Ya tiene una contraseña?
              </span>
              <Link to={ROUTES.SIGNIN} className="signup__option--link">
                Ingresar.
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  token: selectTokenPassWord(state),
});
const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(React.memo(ResetPassword)));
