//Services
import FetchPlus from "Services/FetchPlus";
//Config
import { API_URL } from "Config/config";
const contactBase = `${API_URL}organizations`;

export default class ContactService {
  static fetchContactData(
    token,
    {usersLimit = 10, usersLastRow = 0, organizationId, lasRowContact = 0} = {}
  ) {

    const headers = {
      Authorization: token,
    };

    if (lasRowContact !== 0) {
      usersLimit = usersLimit + lasRowContact;
    }

    return FetchPlus(
      `${contactBase}/${organizationId}/contacts?page=${usersLastRow}&size=${usersLimit}`,
      {
        headers,
      }
    )
      .then((result) => result || [])
      .catch((err) => {
        if (err === 401){
          return err;
        }
        console.log(err);
      });
  }

  static searchInContacts(
    token,
    searchInputValue,
    { organizationId, usersLimit = 10, usersLastRow = 0 }
  ) {
    return FetchPlus(
      `${contactBase}/${organizationId}/contacts?page=${usersLastRow}&size=${usersLimit}&search=${searchInputValue}`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((result) => result.contacts.rows || [])
      .catch((err) => {
        console.log(err);
      });
  }

  static fechSentEmail(
    token,
    subject,
    body,
    emailAssitant,
    contactId,
    organizationId,
    companyId
  ) {
    return FetchPlus(
      `${contactBase}/${organizationId}/contacts/${contactId}/message`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          body: body,
          subject: subject,
          contactEmail: emailAssitant,
          companyId: companyId,
        }),
      }
    )
      .then((message) => message)
      .catch((error) => {
        throw new Error(error);
      });
  }
}
