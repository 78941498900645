//Libs
import React, { useState } from "react";
import { withRouter } from "react-router";

//Selectors
import {
  selectCurrentUser,
  selectIsNavMenuOpen,
} from "Redux/User/user-selectors";

import { selectCategoriesData } from "Redux/Categories/categories-selectors";
import { orgnizacionUnseleted } from "Redux/DynamicConfigCompany/dcc-action";
import { clearOrganization } from "Redux/Organization/organizarion-action";


import { connect } from "react-redux";
import { compose } from "redux";
import { Menu, Dropdown } from "antd";

//service
import { CompanyService } from "Services/CompanyService";
//Components
import {
  Drawer,
  Wrapper,
  Grid,
  ProfilePhoto,
  ResourceUpload,
  Link,
  SwitchProfile,
} from "Components";
import Label from "./Label";
//Actions
import { setNavMenuOpen, logout } from "Redux/User/user-actions";

const { SubMenu } = Menu;

function MenuHeader({
  currentUser,
  logout,
  setNavMenuOpen,
  setDropDownShow,
  DropDownShow,
  mobileView,
}) {
  return (
    <Wrapper display="flex" height="100px" justifyContent="flex-start">
      <ResourceUpload id="profilePhoto">
        <ProfilePhoto
          profilePhotoSrc={currentUser?.profileImageUser}
          containerStyle={{
            margin: "0 10px 0 0",
            right: "5px",
            bottom: "2px",
          }}
          cameraStyle={{
            left: "30px",
            bottom: "0",
            fontSize: "1rem",
          }}
        />
      </ResourceUpload>
      <Dropdown
        overlay={
          <SwitchProfile
            logout={logout}
            setNavMenuOpen={setNavMenuOpen}
            setDropDownShow={setDropDownShow}
            mobileView={mobileView}
          />
        }
        placement="bottomRight"
        visible={DropDownShow === true ? setTimeout(() => { setDropDownShow(false) }, 3000) : false}
        onClick={() => {
          setDropDownShow(true);
        }}
        onBlur={() => setDropDownShow(false)}
      >
        <Label>{currentUser?.name}</Label>
      </Dropdown>
    </Wrapper>
  );
}

const MenuItem = ({ category, to, setNavMenuOpen }) => (
  <Link
    onClick={() => {
      setNavMenuOpen(false);
    }}
    to={to}
    color={"white"}
  >
    {category}
  </Link>
);

function MenuCategories({ location, modules, setNavMenuOpen }) {
  return (
    <Grid overflowY="auto" rows="repeat(auto-fill, minmax(30px, 1fr))">
      {modules.map(
        (
          { name, path, isHeaderBarHidden, isInMobileNav, subModules },
          index
        ) => (
          <React.Fragment key={index}>
            {!isHeaderBarHidden && isInMobileNav && (
              <Menu
                style={{ background: "transparent", color: "white" }}
                theme={"dark"}
                selectable={false}
              >
                {subModules.length !== 0 ? (
                  <SubMenu key={index} title={name}>
                    {path ? (
                      <>
                        <Menu.Item>
                          <MenuItem
                            category={name}
                            to={path}
                            location={location}
                            setNavMenuOpen={setNavMenuOpen}
                          />
                        </Menu.Item>
                        {Array.isArray(subModules) &&
                          subModules.map((value, index) => (
                            <Menu.Item key={index}>
                              {value.subModule.path.includes("#") ? (
                                <Label
                                  margin="0 1em 0 2em"
                                  style={{ color: "rgb(255, 255, 255)" }}
                                  onClick={() =>
                                    window.location.replace(
                                      value.subModule.path
                                    )
                                  }
                                >
                                  {value.subModule.name}
                                </Label>
                              ) : (
                                <Link
                                  margin="0 1em 0 2em"
                                  onClick={() => {
                                    setNavMenuOpen(false);
                                  }}
                                  to={value.subModule.path}
                                  className="ant-dropdown-link"
                                  style={{ color: "rgb(255, 255, 255)" }}
                                >
                                  {value.subModule.name}
                                </Link>
                              )}
                            </Menu.Item>
                          ))}
                      </>
                    ) : (
                      <>
                        {Array.isArray(subModules) &&
                          subModules.map((value, index) => (
                            <Menu.Item key={index}>
                              {value.subModule.path.includes("#") ? (
                                <Label
                                  margin="0 1em 0 2em"
                                  style={{ color: "rgb(255, 255, 255)" }}
                                  onClick={() =>
                                    window.location.replace(
                                      value.subModule.path
                                    )
                                  }
                                >
                                  {value.subModule.name}
                                </Label>
                              ) : (
                                <Link
                                  onClick={() => {
                                    setNavMenuOpen(false);
                                  }}
                                  margin="0 1em 0 2em"
                                  to={value.subModule.path}
                                  className="ant-dropdown-link"
                                  style={{ color: "rgb(255, 255, 255)" }}
                                >
                                  {value.subModule.name}
                                </Link>
                              )}
                            </Menu.Item>
                          ))}
                      </>
                    )}
                  </SubMenu>
                ) : (
                  <Menu.Item>
                    <MenuItem
                      category={name}
                      to={path}
                      location={location}
                      setNavMenuOpen={setNavMenuOpen}
                    />
                  </Menu.Item>
                )}
              </Menu>
            )}
          </React.Fragment>
        )
      )}
    </Grid>
  );
}

function MenuFooter({ logout, unselected, setNavMenuOpen, setDropDownShow, clearCategories }) {
  return (
    <Wrapper padding="20px 0 0 0" display="flex" justifyContent="flex-start">
      <Label
        pointer
        onClick={async () => {
          setDropDownShow(false);
          const companyData = await CompanyService.getCompanyData(
            window.location.host
          ).then((data) => data);
          setNavMenuOpen(false);
          unselected(companyData.company);
          logout();
        }}
      >
        Salir
      </Label>
    </Wrapper>
  );
}

function NavMenu({
  currentUser,
  // categories,
  isNavMenuOpen,
  setNavMenuOpen,
  logout,
  location,
  modules,
  unselected,
  mobileView,
  clearOrganization
}) {
  const [DropDownShow, setDropDownShow] = useState(false);

  return (
    <Drawer
      key="left"
      position="relative"
      title={
        <MenuHeader
          currentUser={currentUser}
          logout={logout}
          DropDownShow={DropDownShow}
          setNavMenuOpen={setNavMenuOpen}
          setDropDownShow={setDropDownShow}
          mobileView={mobileView}
        />
      }
      placement="left"
      closable={false}
      onClose={() => setNavMenuOpen(false)}
      visible={isNavMenuOpen}
    >
      <Grid height="100%" rows="7fr 1fr">
        {/* categories={categories} */}
        <MenuCategories
          location={location}
          modules={modules}
          setNavMenuOpen={setNavMenuOpen}
        />
        <MenuFooter
          logout={logout}
          unselected={unselected}
          clearOrganization={clearOrganization}
          setDropDownShow={setDropDownShow}
          setNavMenuOpen={setNavMenuOpen}
        />
      </Grid>
    </Drawer>
  );
}

const mapStateToProps = (state) => ({
  currentUser: selectCurrentUser(state),
  isNavMenuOpen: selectIsNavMenuOpen(state),
  categories: selectCategoriesData(state),
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  setNavMenuOpen: (open) => dispatch(setNavMenuOpen(open)),
  unselected: (company) => dispatch(orgnizacionUnseleted(company)),
  clearOrganization: () => dispatch(clearOrganization()),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(NavMenu);
