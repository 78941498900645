import { useEffect, useState, useRef } from "react";

export default function useNearScreen({
  root,
  rootMargin,
  threshold,
  externalRef,
  observeOnce = true,
} = {}) {
  const [isNearScreen, setNearScreen] = useState(false);
  const fromRef = useRef();

  useEffect(() => {
    let observer;

    const element = externalRef ? externalRef.current : fromRef.current;

    function onIntersection(entries) {
      const el = entries[0];

      if (el.isIntersecting) {
        setNearScreen(true);
        observeOnce && observer.disconnect();
      } else {
        !observeOnce && setNearScreen(false);
      }
    }

    Promise.resolve(
      typeof IntersectionObserver !== "undefined"
        ? IntersectionObserver
        : import("intersection-observer")
    ).then(() => {
      observer = new IntersectionObserver(onIntersection, {
        root,
        rootMargin,
        threshold,
      });
      if (element) observer.observe(element);
    });

    return () => observer?.disconnect();
  });

  return [isNearScreen, fromRef];
}
