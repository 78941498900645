import styled from "styled-components";
import { LoadingOutlined } from "@ant-design/icons";

const StyledFloatLoadingButton = styled(LoadingOutlined)`
  padding: ${(props) =>
    props.padding || props.theme.floatLoadingButton.padding};
  color: ${(props) => props.color || props.theme.floatLoadingButton.color};
  position: ${(props) =>
    props.position || props.theme.floatLoadingButton.position};
  top: ${(props) => props.top || props.theme.floatLoadingButton.top};
  bottom: ${(props) => props.bottom || props.theme.floatLoadingButton.bottom};
  left: ${(props) => props.left || props.theme.floatLoadingButton.left};
  right: ${(props) => props.right || props.theme.floatLoadingButton.right};
  z-index: ${(props) => props.zIndex || props.theme.floatLoadingButton.zIndex};
  font-size: ${(props) =>
    props.fontSize || props.theme.floatLoadingButton.fontSize};
  transform: ${(props) =>
    props.transform || props.theme.floatLoadingButton.transform};
  background-color: ${(props) =>
    props.backgroundColor || props.theme.floatLoadingButton.backgroundColor};
  border-radius: ${(props) =>
    props.borderRadius || props.theme.floatLoadingButton.borderRadius};
  box-shadow: ${(props) =>
    props.boxShadow || props.theme.floatLoadingButton.boxShadow};
  transform: ${(props) =>
    props.transform || props.theme.floatLoadingButton.transform};
  transition: ${(props) =>
    props.transition || props.theme.floatLoadingButton.transition};
  cursor: ${(props) => props.cursor || props.theme.floatLoadingButton.cursor};
  &:hover {
    color: ${(props) =>
      props.hoverColor || props.theme.floatLoadingButton.hover.color};
  }
`;

export default StyledFloatLoadingButton;
