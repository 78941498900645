/* Check if <array> is an array */
export const checkIfInputIsAnArrayValid = (array) =>
  Array.isArray(array) ? array : [];

/* Check if <pollId> is valid */
export const checkIfPollIdIsValid = (pollId) =>
  pollId && typeof pollId === "string";

/* Check if <answerOptions> is valid */
export const checkIfPollAnswersIsValid = (answerOptions) => Object.keys(answerOptions).length > 0;

//Check if user has poll rol
export const checkIfUserIsPollRol = (user) =>{
  return true;
}
//true if user already vote that poll
