import React, { useState } from "react";
import { Card } from "antd";
import { Avatar, Modal, ContactInfoModal } from "Components";
import styled from "styled-components";

const { Meta } = Card;

const ModalInformation = styled(Modal)`
  .ant-modal-body {
    padding: 0px;
  }
`;

const CardComponent = ({
  title,
  subtitle,
  coverImg,
  actions,
  avatarSrc,
  description,
  styles,
  bodyStyle,
  width,
  hasModal,
  email,
  titlePro
}) => {
  const contact = { title, subtitle, avatarSrc, description, email };
  const [showInfoModal, setShowInfoModal] = useState({
    modalShow: false,
  });

  const ModalInfo = ({ show, close }) => {
    return (
      <ModalInformation visible={show} onCancel={close} footer={null}>
        <ContactInfoModal contact={contact} />
      </ModalInformation>
    );
  };

  return (
    <>
      {hasModal ? (
        <ModalInfo
          show={showInfoModal.modalShow}
          close={() => setShowInfoModal({ modalShow: false })}
        />
      ) : (
        ""
      )}

      <Card
        style={{ width: width || 300, ...styles }}
        bodyStyle={bodyStyle}
        cover={coverImg}
        actions={actions}
        onClick={() => {
          setShowInfoModal({ modalShow: true });
        }}
      >
        {/* <infoModal /> */}
        <Meta
          avatar={avatarSrc && <Avatar src={avatarSrc} />}
          title={title}
          description={
            <>
              {subtitle && (
                <p style={{ fontWeight: "bold", marginBottom: "0px" }}>
                  {subtitle}
                </p>
              )}
              {email && <b style={{ fontsize: "14px" }}>{email}</b>}
              {description && <p>{description}</p>}
            </>
          }
        />
        {titlePro}
      </Card>
    </>
  );
};

export default CardComponent;
