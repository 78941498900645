//Libs
import React from "react";
import styled, { css } from "styled-components";

const ContainerImage = styled.div`
  width: auto;
  display: block;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
`;

const StyledImage = styled.img`
  margin: ${(props) => props.margin || props.theme.image.margin};
  padding: ${(props) => props.padding || props.theme.image.padding};
  width: ${(props) => props.width || props.theme.image.width};
  ${(props) =>
    props.stories &&
    css`
      height: 100%;
    `}
  min-height: ${(props) => props.minHeight || props.theme.image.minHeight};
  max-height: ${(props) => props.maxHeight || props.theme.image.maxHeight};
  border: ${(props) => props.border || props.theme.image.border};
  cursor: ${(props) => props.cursor || props.theme.image.cursor};
  object-fit: ${(props) => (props.isStory ? 'contain' : (props.objectFit || props.theme.image.objectFit))};
  ${(props) =>
    props.isStory &&
    css`
      border: 1px solid #fff;
    `}
  border-radius: ${(props) => props.circular && "50%"};
`;

function Image({
  src,
  alt,
  margin,
  padding,
  width,
  height,
  minHeight,
  maxHeight,
  border,
  cursor,
  circular,
  previewProfilePhoto,
  stories,
  isStory,
}) {
  return (
    <ContainerImage>
      <StyledImage
        isStory={isStory}
        src={src}
        alt={alt}
        margin={margin}
        padding={padding}
        width={width}
        height={height}
        minHeight={minHeight}
        maxHeight={maxHeight}
        border={border}
        cursor={cursor}
        circular={circular}
        previewProfilePhoto={previewProfilePhoto}
        stories={stories}
      />
    </ContainerImage>
  );
}

export default React.memo(Image);
