import styled, { css } from "styled-components";

export default styled.div`
  margin: ${(props) => props.margin || props.theme.wrapper.margin};
  padding: ${(props) => props.padding || props.theme.wrapper.padding};
  position: ${(props) => props.position || props.theme.wrapper.position};
  width: ${(props) => props.width || props.theme.wrapper.width};
  min-width: ${(props) => props.minWidth || props.theme.wrapper.minWidth};
  max-width: ${(props) => props.maxWidth || props.theme.wrapper.maxWidth};
  height: ${(props) => props.height || props.theme.wrapper.height};
  min-height: ${(props) => props.minHeight || props.theme.wrapper.minHeight};
  max-height: ${(props) => props.maxHeight || props.theme.wrapper.maxHeight};
  text-align: ${(props) => props.textAlign || props.theme.wrapper.textAlign};
  display: ${(props) => props.display || props.theme.wrapper.display};
  flex-direction: ${(props) =>
    props.flexDirection || props.theme.wrapper.flexDirection};
  justify-content: ${(props) =>
    props.justifyContent || props.theme.wrapper.justifyContent};
  align-items: ${(props) => props.alignItems || props.theme.wrapper.alignItems};
  border: ${(props) => props.border || props.theme.wrapper.border};
  background: ${(props) => props.background || props.theme.wrapper.background};
  cursor: ${(props) => props.cursor || props.theme.wrapper.cursor};
  transition: ${(props) => props.transition || props.theme.wrapper.transition};
  outline: ${(props) => props.outline || props.theme.wrapper.outline};
  box-shadow: ${(props) => props.boxShadow || props.theme.wrapper.boxShadow};
  transform: ${(props) => props.transform || props.theme.wrapper.transform};
  z-index: ${(props) => props.zIndex || props.theme.wrapper.zIndex};
  ${(props) =>
    props.withNav &&
    css`
      margin-top: ${props.theme.nav.height};
    `};

  ${(props) =>
    props.animation === "scaleUp1_6" &&
    css`
      transition: transform 300ms;
      &:hover {
        transition-delay: 600ms;
        transform: scale(1.6);
        z-index: 10;
      }
    `}
`;
