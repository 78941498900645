//Libs
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { Menu, Dropdown } from "antd";
import ReactPlayer from "react-player";
import { uuid } from "uuidv4";
import Mixpanel from "Services/MixPanelService";
import { FaPlay } from "react-icons/fa";
//Components
import { Wrapper, OnlineLabel, Image } from "Components";
import { Carousel } from "antd";
//Config
import {
  ROUTES,
  MOBILE_VIEWPORT_SIZE,
  DEVICE_IS_MOBILE,
} from "../../Config/config";
//Styles
import "./RoomItem.css";

const ContentRoom = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 10vw 0 2vw 3vw;
  background-color: rgba(0, 0, 0, 0.4);
  @media screen and (max-width: ${MOBILE_VIEWPORT_SIZE}px) {
    padding: 51px 0 2vw 3vw;
  }
  ${() =>
    DEVICE_IS_MOBILE &&
    css`
      padding: 51px 0 2vw 3vw;
    `}
`;

const RoomNumber = styled.span`
  border: solid 3px #dcdcdc;
  border-style: none none none solid;
  background-color: rgba(51, 51, 51, 0.6);
  font-size: 1.1vw;
  padding: 0.5vw 3.5vw 0.5vw 0.8vw;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 2.4vw;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  @media screen and (max-width: ${MOBILE_VIEWPORT_SIZE}px) {
    height: 30px;
    padding: 0;
  }
`;

const RoomNumberText = styled.span`
  color: #fff;
  padding: 0;
  border-width: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  padding: 0 0.4em;
  unicode-bidi: normal;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  @media screen and (max-width: ${MOBILE_VIEWPORT_SIZE}px) {
    font-size: 12px;
  }
`;

const SeeNowButton = styled(Link)`
  position: absolute;
  left: 0;
  bottom: ${(props) => props.bottom};
  width: ${(props) => props.width};
  padding: ${(props) => props.padding};
  font-size: ${(props) => props.fontSize};
  text-align: center;
  font-weight: 600;
  color: #000;
  background-color: #fff;
  text-decoration: none;
  cursor: pointer;
  transition: 0.5s;
  z-index: 40;
  border-radius: 3px;
  &:hover {
    color: ${(props) => props.colorhover};
  }
`;

const LinkToVideoResource = styled(Link)`
  cursor: pointer;
`;

const RoomNumberContainer = styled.div`
  z-index: 10;
  position: absolute;
  right: 0;
  bottom: 35%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
`;

const VideoName = styled.span`
  display: inline-block;
  max-width: 45vw;
  min-height: 14.3vw;
  font-size: 2.8vw;
  font-weight: 600;
  color: #fff;
  @media screen and (max-width: ${MOBILE_VIEWPORT_SIZE}px) {
    font-size: 3vw;
  }
  ${() =>
    DEVICE_IS_MOBILE &&
    css`
      font-size: 3vw;
    `}
`;

const SpeakerName = styled.span`
  display: block;
  max-width: 45vw;
  font-size: 2.5vw;
  font-weight: 600;
  color: #fff;
  @media screen and (max-width: ${MOBILE_VIEWPORT_SIZE}px) {
    font-size: 3w;
  }
  ${() =>
    DEVICE_IS_MOBILE &&
    css`
      font-size: 3w;
    `}
`;

const SpeakerJobTitle = styled.span`
  display: block;
  max-width: 45vw;
  font-size: 1.2vw;
  font-weight: 600;
  color: #fff;
  @media screen and (max-width: ${MOBILE_VIEWPORT_SIZE}px) {
    font-size: 2vw;
  }
  ${() =>
    DEVICE_IS_MOBILE &&
    css`
      font-size: 2vw;
    `}
`;

const PlayIconContainer = styled.div`
  display: inline-block;
  height: 16px;
  width: 16px;
  position: relative;
  top: 2px;
  margin-right: 12px;
  font-size: ${(props) => props.fontSize};
`;

const MenuLanguagesPlayVideo = ({
  roomId,
  streamId,
  streamWss,
  resources,
  currentUser,
  setVideoProperties,
}) => (
  <Menu>
    {/* RESOURCE ITEM */}
    {Array.isArray(resources) &&
      resources.map((resource, idx) => (
        <Menu.Item key={idx}>
          <LinkToVideoResource
            to={`${ROUTES.ROOM}/${roomId}/${resource.id}`}
            onClick={() => {
              setVideoProperties({
                videoId: resource.id,
                streamId,
                streamWss,
              });
              Mixpanel.track(Mixpanel.TYPES.PLAY_ROOM, {
                roomId,
                videoId: resource.id,
                language: resource.languageId,
                userId: currentUser.id,
                agent: window.navigator.userAgent,
              });
            }}
          >
            <Wrapper display="flex" justifyContent="flex-start">
              <Image
                margin="0 15px 0 0"
                width="32px"
                height="32px"
                src={resource.languageIconUrl}
              />
              <Wrapper cursor="pointer">{resource.languageName}</Wrapper>
            </Wrapper>
          </LinkToVideoResource>
        </Menu.Item>
      ))}
  </Menu>
);

function PlayVideoButton({
  roomId,
  streamId,
  streamWss,
  resources,
  defaultVideoUrl,
  currentUser,
  setVideoProperties,
  mobileView,
  organizationStyle,
}) {
  const resource = resources[0];

  if (resources.length < 2) {
    const showWelcomeData = resources.length === 0;

    const options = {
      to: showWelcomeData
        ? `${ROUTES.VIDEO}/welcome`
        : `${ROUTES.ROOM}/${roomId}/${resource?.id}`,
      videoId: showWelcomeData ? "welcome" : resource?.id,
      withChat: showWelcomeData ? false : resource?.isChatAvailable,
    };

    return (
      <SeeNowButton
        bottom={mobileView ? "5px" : "120px"}
        width={mobileView ? "160px" : "225px"}
        padding={mobileView ? "7px 20px" : "16px 20px"}
        fontSize={mobileView ? "16px" : "23px"}
        colorhover={organizationStyle.buttonColor || "#00B2F3"}
        to={options.to}
        onClick={() => {
          setVideoProperties({
            videoId: options.videoId,
            streamId,
            streamWss,
            videoUrl: defaultVideoUrl,
            withChat: options.withChat,
          });
          if (resource) {
            Mixpanel.track(Mixpanel.TYPES.PLAY_ROOM, {
              roomId,
              videoId: resource.resourceId,
              language: resource.language,
              userId: currentUser.id,
              agent: window.navigator.userAgent,
            });
            //google analicty Event tracking
          }
        }}
      >
        <PlayIconContainer fontSize={mobileView ? "15px" : ""}>
          <FaPlay />
        </PlayIconContainer>
        Reproducir
      </SeeNowButton>
    );
  }
  return (
    <Dropdown
      placement="topLeft"
      overlay={
        <MenuLanguagesPlayVideo
          roomId={roomId}
          streamId={streamId}
          streamWss={streamWss}
          resources={resources}
          currentUser={currentUser}
          setVideoProperties={setVideoProperties}
        />
      }
    >
      <SeeNowButton
        bottom={mobileView ? "5px" : "120px"}
        width={mobileView ? "160px" : "225px"}
        padding={mobileView ? "7px 20px" : "16px 20px"}
        fontSize={mobileView ? "16px" : "23px"}
        colorhover={organizationStyle.buttonColor || "#00B2F3"}
      >
        <PlayIconContainer fontSize={mobileView ? "15px" : ""}>
          <FaPlay />
        </PlayIconContainer>
        Reproducir
      </SeeNowButton>
    </Dropdown>
  );
}

function Speakers({ speakers = [] }) {
  return (
    <Wrapper width="100%" position="relative" height="12.5vw" maxWidth="45vw">
      {speakers.length === 0 && null}
      {speakers.length === 1 && (
        <>
          <SpeakerName>
            {`${
              speakers[0].professionalTittles[0]
                ? speakers[0].professionalTittles[0].name
                : ""
            } ${speakers[0].name}`.trim()}
          </SpeakerName>
          {speakers[0].professionalTittles[0] &&
          speakers[0].professionalTittles[0].SpeakerOrganization ? (
            <SpeakerJobTitle>
              {speakers[0].professionalTittles[0].SpeakerOrganization.jobTittle}
            </SpeakerJobTitle>
          ) : null}
        </>
      )}
      {speakers.length > 1 && (
        <Carousel autoplay effect="fade" dots={null}>
          {speakers.map(({ name, professionalTittles }, idx) => (
            <Wrapper key={idx}>
              <SpeakerName>
                {`${
                  professionalTittles[0] ? professionalTittles[0].name : ""
                } ${name}`.trim()}
              </SpeakerName>
            </Wrapper>
          ))}
        </Carousel>
      )}
    </Wrapper>
  );
}

function RoomItem({
  roomId,
  roomName,
  resources,
  streamId,
  streamWss,
  defaultVideoUrl,
  mobileView,
  currentUser,
  setVideoProperties,
  organizationStyle,
  renderAttributes,
  setting,
}) {
  const { name, speakers, source, hidePlayButton } =
    useMemo(() => {
      // if (!resources || resources.includes) return {};

      const resourceRoom = resources[0];
      return {
        resourceId: resourceRoom?.id,
        name: renderAttributes?.roomName,
        speakers: resourceRoom?.speakers,
        source: setting?.source,
        hidePlayButton: setting?.hidePlayButton,
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser, resources, renderAttributes]);

  return (
    <Wrapper
      display="block"
      position="relative"
      height="calc(100vw / 1.8)"
      id={"room"}
    >
      <ReactPlayer
        playing={true} //Autoplay
        muted
        loop
        width="100%"
        height="auto"
        url={`${defaultVideoUrl}?id=${uuid()}`}
        onReady={(player) => {
          const hls = player.getInternalPlayer('hls');
          hls.startLevel = hls.levels.length - 1;
        }}
      />
      <ContentRoom>
        <Wrapper
          padding={mobileView ? 0 : "4vw 0 0 0"}
          position="relative"
          width="100%"
          height="100%"
          display={mobileView ? "flex" : "block"}
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <RoomNumberContainer>
            <RoomNumber>
              <RoomNumberText>{roomName}</RoomNumberText>
            </RoomNumber>
          </RoomNumberContainer>
          {!hidePlayButton && (
            <PlayVideoButton
              roomId={roomId}
              streamId={streamId}
              streamWss={streamWss}
              resources={resources}
              source={source}
              defaultVideoUrl={defaultVideoUrl}
              currentUser={currentUser}
              setVideoProperties={setVideoProperties}
              mobileView={mobileView}
              organizationStyle={organizationStyle}
            />
          )}
          <VideoName>
            {name}
            {streamId && (
              <OnlineLabel />
            )}
          </VideoName>
          <Speakers speakers={speakers} />
        </Wrapper>
      </ContentRoom>
    </Wrapper>
  );
}

export default React.memo(RoomItem);
