import RoomActionTypes from "./room-types";

const INITIAL_STATE = {
  isFetching: false,
  rooms: [],
  roomCast: [],
  roomVideos: "",
};

const roomsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case RoomActionTypes.SET_ROOM_DATA: {
      return {
        ...state,
        rooms: Array.prototype.concat.apply(state.rooms, payload),
        isFetching: false,
      };
    }
    case RoomActionTypes.SET_LOADING_ROOM_DATA: {
      return {
        ...state,
        isFetching: payload,
      }
    }
    case RoomActionTypes.RESET:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default roomsReducer;
