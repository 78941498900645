//Config
import { ROUTES } from "Config/config";
//Onboarding
import ForgotPassword from "Pages/ForgotPassword/ForgotPassword";
import ResetPassword from "Pages/ResetPassword/ResetPassword";
import SignIn from "Pages/SignIn/SignIn";
import SignUp from "Pages/SignUp/SignUp";
//Private
import Home from "Pages/Home/Home";
import WatchVideoRoomPage from "Pages/WatchVideoRoomPage/WatchVideoRoomPage";
import Directory from "Pages/Directory/Directory";
import Speakers from "Pages/Speakers/Speakers";
import News from "Pages/News/News";
import ContactUs from "Pages/ContactUs/ContactUs";
import Organization from "Pages/Organization/Organization";
import EditProfile from "Pages/EditProfile/EditProfile";

const getComponent = (path) => {

  switch (path) {
    //Onboarding Routes
    case ROUTES.SIGNIN:
      return SignIn;
    case ROUTES.SIGNUP:
      return SignUp;
    case `${ROUTES.RESET_PASSWORD}/:email/:code`:
      return ResetPassword;
    case ROUTES.FORGOT_PASSWORD:
      return ForgotPassword;
    case ROUTES.ORGANIZATION:
      return Organization;
    // //Private Routes
    case ROUTES.HOME:
      return Home;
    case `${ROUTES.ROOM}/:roomId/:videoId`:
      return WatchVideoRoomPage;
    case `${ROUTES.VIDEO}/:videoId`:
      return WatchVideoRoomPage;
    case ROUTES.SPEAKERS:
      return Speakers;
    case ROUTES.CONTACTUS:
      return ContactUs;
    case ROUTES.DIRECTORY:
      return Directory;
    case ROUTES.NEWS:
      return News;
    case ROUTES.EDIT_PROFILE:
      return EditProfile;

    default:
      return null;
  }
};

const injectComponents = (modules = []) => {
  return modules.map((module) => ({
    ...module,
    component: getComponent(module.path),
  }));
};

export default injectComponents;
