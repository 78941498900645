import { createSelector } from "reselect";

const selectDirectory = (state) => state.directory;

export const selectIsFetching = createSelector(
  [selectDirectory],
  (directory) => directory.isFetching
);

export const selectDirectoryData = createSelector(
  [selectDirectory],
  (directory) => directory.data
);

export const loadingDataDirectory = createSelector(
	[selectDirectory],
	(directory) => directory.loadingDirectoryData
);

export const totalItemDirectory = createSelector(
	[selectDirectory],
	(directory) => directory.totalDirectoryItem
);

