import Organizacion2 from "Assets/logo-old.png";

const company = {
  id: 1,
  name: "CardiOne",
  url: "Bayer.com",
  cssStyle: {
    backgroundColor: "#582e80",
    // backgroundImage:
    //   "https://media.istockphoto.com/photos/dark-black-and-blue-grungy-wall-background-for-display-or-montage-of-picture-id1150477705?k=6&m=1150477705&s=612x612&w=0&h=q0f-napJKrSFE3ETwopLe6ETDRXk50zv7hctci_-j4A=",
    TextColorCompany: "#FFFFFF",
    fontSizeCompanyTittle: "37px",
  },
  countryId: 1,
  createdAt: "",
  updatedAt: "",
  organizations: [
    {
      id: 1,
      name: "CardiOne",
      url: "imageLogo",
      cssStyle: {
        backgroundColor: "#582e80",
        // backgroundImage: Organizacion1,
        backgroundColorBoxOrg: "#000000",
        TextColor: "#FFFFFF",
      },
      countryId: 1,
      companyId: 1,
      createdAt: "",
      updatedAt: "",
    },
    {
      id: 2,
      name: "OrganizacionTest",
      url: Organizacion2,
      cssStyle: {
        backgroundColor: "#582e80",
        // backgroundImage: Organizacion2,
        backgroundColorBoxOrg: "#000000",
        TextColor: "#FFFFFF",
      },
      countryId: 1,
      companyId: 1,
      createdAt: "",
      updatedAt: "",
    },
  ],
};

export default company;
