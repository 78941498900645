//Services
import FetchPlus from "Services/FetchPlus";
//Config
import { API_URL } from "Config/config";

const organizationBase = `${API_URL}organizations`;

export async function fetchRooms(
  token,
  { roomsLimit = 5, roomsLastRow = 0, organizationId } = {}
) {
  const headers = {
    Authorization: token,
  };

  return FetchPlus(
    `${organizationBase}/${organizationId}/rooms?size=${roomsLimit}&page=${roomsLastRow}`,
    {
      headers,
    }
  )
    .then((rooms) => rooms.rooms.rows || [])
    .catch((err) => {
      console.log(err);
    });
}

export async function fetchRoomData(token, roomId, { organizationId } = {}) {
  const headers = {
    Authorization: token,
  };

  return FetchPlus(`${organizationBase}/${organizationId}/rooms/${roomId}`, {
    headers,
  })
    .then((room) => room || {})
    .catch((err) => {
      console.log(err);
    });
}
