import styled from "styled-components";
import { CameraOutlined } from "@ant-design/icons";

const StyledFloatFrownButton = styled(CameraOutlined)`
  padding: ${(props) => props.padding || props.theme.floatCameraButton.padding};
  color: ${(props) => props.color || props.theme.floatCameraButton.color};
  position: ${(props) =>
    props.position || props.theme.floatCameraButton.position};
  top: ${(props) => props.top || props.theme.floatCameraButton.top};
  bottom: ${(props) => props.bottom || props.theme.floatCameraButton.bottom};
  left: ${(props) => props.left || props.theme.floatCameraButton.left};
  right: ${(props) => props.right || props.theme.floatCameraButton.right};
  z-index: ${(props) => props.zIndex || props.theme.floatCameraButton.zIndex};
  font-size: ${(props) =>
    props.fontSize || props.theme.floatCameraButton.fontSize};
  transform: ${(props) =>
    props.transform || props.theme.floatCameraButton.transform};
  background-color: ${(props) =>
    props.backgroundColor || props.theme.floatCameraButton.backgroundColor};
  border-radius: ${(props) =>
    props.borderRadius || props.theme.floatCameraButton.borderRadius};
  box-shadow: ${(props) =>
    props.boxShadow || props.theme.floatCameraButton.boxShadow};
  transform: ${(props) =>
    props.transform || props.theme.floatCameraButton.transform};
  transition: ${(props) =>
    props.transition || props.theme.floatCameraButton.transition};
  cursor: ${(props) => props.cursor || props.theme.floatCameraButton.cursor};
  &:hover {
    color: ${(props) =>
      props.hoverColor || props.theme.floatCameraButton.hover.color};
  }
`;

export default StyledFloatFrownButton;
