import { Avatar } from "antd";
import styled from "styled-components";

const AvatarStyled = styled(Avatar)`
  cursor: pointer;
  width: ${(props) => (props.width ? props.width : props.theme.avatar.width)};
  height: ${(props) =>
    props.height ? props.height : props.theme.avatar.height};
  margin: ${(props) =>
    props.margin ? props.margin : props.theme.avatar.margin};
`;

export default AvatarStyled;
