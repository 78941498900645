//Libs
import React, { useRef, useCallback } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import useNearScreen from "Hooks/useNearScreen";
import debounce from "just-debounce-it";

//Service
import DirectoryService from "Services/DirectoryService";
import { filterData } from "Services/SearchInputService";
//Actions
import { setDirectoryData } from "Redux/Directory/directory.actions";
import { resetSearchInputValue } from "Redux/Search/search-actions";
//Selectors
import { selectSearchInputValue } from "Redux/Search/search-selectors";
import { selectCurrentUser } from "Redux/User/user-selectors";
import {
  selectIsFetching,
  selectDirectoryData,
  loadingDataDirectory,
  totalItemDirectory
} from "Redux/Directory/directory.selectors";
import { selectedOrganizationId } from "Redux/Organization/organization-select";
//Components
import {
  Wrapper,
  Grid,
  Card,
  ErrorLabel,
  FilterAndSearchBar,
  Spinner,
  Title
} from "Components";

const Directory = ({
  //Internal Props
  directoryData,
  setDirectoryData,
  //External Props
  mobileView,
  searchInputValue,
  currentUser,
  resetSearchInputValue,
  organizationId,
  loadingDataDirectory,
  totalItemDirectory
}) => {

  const visorRef = useRef();
  const nowShowRef = useRef();
  const canObserveVisor = !loadingDataDirectory && totalItemDirectory > 0;
  const [isNearScreen] = useNearScreen({
    externalRef: canObserveVisor ? visorRef : null,
    rootMargin: "60%",
    observeOnce: false,
  });

  const handleNextpage = useCallback(
    debounce(
      () =>
        setDirectoryData(currentUser.token, {
          organizationId,
          LastRowSpeaker: directoryData.length
        }),
      200
    ),
    [directoryData]
  );

  React.useEffect(() => {
    if (isNearScreen && canObserveVisor && directoryData.length < totalItemDirectory) {
      handleNextpage();
    }
  }, [isNearScreen, canObserveVisor, directoryData.length, handleNextpage, totalItemDirectory]);

  React.useEffect(() => {
    setDirectoryData(currentUser.token, { organizationId });
    resetSearchInputValue();
  }, [currentUser.token, organizationId, resetSearchInputValue, setDirectoryData]);

  const filteredDirectoryData = directoryData ? filterData(
    directoryData,
    searchInputValue,
    "users"
  ) : [];

  return !directoryData ?
    (
      <Wrapper
        ref={nowShowRef}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Title>No hay Directorio disponibles.</Title>
      </Wrapper>
    ) :
    directoryData.length === 0 ? <Spinner /> : (
      <>
        <Wrapper
          alignItems="left"
          display="flex"
          flexDirection="column"
          margin="90px 10vw 10px 10vw"
        >
          <div style={{ marginBottom: "1em" }}>
            <h1 style={{ color: "#fff" }}>Médicos registrados en CardiOne</h1>
            <FilterAndSearchBar
              mobileView={mobileView}
              onEnterKeyPressed={() =>
                DirectoryService.searchInDirectory(
                  currentUser.token,
                  searchInputValue,
                  { organizationId }
                ).then((data) => setDirectoryData(data))
              }
            />
          </div>
          <Grid
            style={{ marginBottom: "120px" }}
            columns={`repeat(${mobileView ? 1 : 3}, calc(80vw/3))`}
            gap="1em 1em"
          >
            {Array.isArray(filteredDirectoryData) &&
              _.orderBy(filteredDirectoryData, (s) => s.name, ["asc"]).map(
                (contact, idx) => (
                  <Card
                    width={mobileView ? "calc(80vw)" : "calc(80vw/3)"}
                    key={idx}
                    styles={{ backgroundColor: "#fff", cursor: "pointer" }}
                    bodyStyle={{ padding: "18px" }}
                    title={`${contact.name} ${contact.firstLastName || ""}`}
                    subtitle={contact.country.name}
                    description={contact.userCompany[0].email}
                    avatarSrc={contact.userCompany[0].profilePhotoUrl}
                  />
                )
              )}
          </Grid>
          {searchInputValue && filteredDirectoryData.length === 0 && (
            <ErrorLabel color="#00B2F3" value="No existe el asistente buscado." />
          )}
        </Wrapper>
        {canObserveVisor && <div ref={visorRef} />}
      </>
    );
};

const mapStateToProps = (state) => ({
  //Internal reducer
  directoryData: selectDirectoryData(state),
  isFetching: selectIsFetching(state),
  //External reducers
  searchInputValue: selectSearchInputValue(state),
  currentUser: selectCurrentUser(state),
  organizationId: selectedOrganizationId(state),
  loadingDataDirectory: loadingDataDirectory(state),
  totalItemDirectory: totalItemDirectory(state)
});

const mapDispatchToProps = (dispatch) => ({
  setDirectoryData: (token, whereOps) => dispatch(setDirectoryData(token, whereOps)),
  resetSearchInputValue: () => dispatch(resetSearchInputValue()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Directory);
