import styled from "styled-components";
import { CheckCircleOutlined } from "@ant-design/icons";

const StyledFloatCheckButton = styled(CheckCircleOutlined)`
  padding: ${(props) => props.padding || props.theme.floatCheckButton.padding};
  color: ${(props) => props.color || props.theme.floatCheckButton.color};
  position: ${(props) =>
    props.position || props.theme.floatCheckButton.position};
  top: ${(props) => props.top || props.theme.floatCheckButton.top};
  bottom: ${(props) => props.bottom || props.theme.floatCheckButton.bottom};
  left: ${(props) => props.left || props.theme.floatCheckButton.left};
  right: ${(props) => props.right || props.theme.floatCheckButton.right};
  z-index: ${(props) => props.zIndex || props.theme.floatCheckButton.zIndex};
  font-size: ${(props) =>
    props.fontSize || props.theme.floatCheckButton.fontSize};
  transform: ${(props) =>
    props.transform || props.theme.floatCheckButton.transform};
  background-color: ${(props) =>
    props.backgroundColor || props.theme.floatCheckButton.backgroundColor};
  border-radius: ${(props) =>
    props.borderRadius || props.theme.floatCheckButton.borderRadius};
  box-shadow: ${(props) =>
    props.boxShadow || props.theme.floatCheckButton.boxShadow};
  transform: ${(props) =>
    props.transform || props.theme.floatCheckButton.transform};
  transition: ${(props) =>
    props.transition || props.theme.floatCheckButton.transition};
  cursor: ${(props) => props.cursor || props.theme.floatCheckButton.cursor};
  &:hover {
    color: ${(props) =>
      props.hoverColor || props.theme.floatCheckButton.hover.color};
  }
`;

export default StyledFloatCheckButton;
