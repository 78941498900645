import {ROUTES} from "Config/config"
import ContactUs from "Pages/ContactUs/ContactUs";

const getSubModuleComponent = (path) => {
	//Sub modulo inject in components
	switch (path) {
		case ROUTES.CONTACTUS:
			return ContactUs;
		default:
			return null;
	}
};
const injectSubComponent = (modules = []) => {
	return modules.map((module) => ({
		...module,
		component: getSubModuleComponent(module.subModule.path),
	}));
}
export default injectSubComponent;
