//Libs
import React, { useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { BarChartOutlined } from "@ant-design/icons";
import { Element } from "react-scroll";
//Icons
// import { SKIN_TONE_MEDIUM_DARK } from "emoji-picker-react";
//Css
import "./Superchat.css";
//Checks
import {
  checkIfInputIsAnArrayValid,
  checkIfPollAnswersIsValid,
} from "Components/SuperChat/checks";

//Styles
import {
  ChatMessageInputContainer,
  // EmojiPicker,
  EmoticonIcons,
  InputCheckBoxMessage,
  MainContainer,
  MessageCard,
  MessageContainer,
  MessageInput,
  PollCard,
  ReactionPicker,
  SpanQuestionMessage,
} from "Components/SuperChatStyled";
//Components
import { ChartResults, FloatingReaction, PollForm } from "Components/index";
import { default as userPermission } from "Utils/Permissions"
//Redux
import { connect } from "react-redux";
//video action
import { sentCheckPollAnswer } from "Redux/Video/video-actions";
import { selectCheckUserAlreadyVote } from "Redux/Video/video-selectors";
import NewMessagesButton from "Components/NewMessagesButton";

const Superchat = ({
  fullScreen,
  mobileView,
  //polls
  polls = [],
  pollAnswers,
  onAnswerPoll,
  messages = [],
  userId,
  roomId,
  newMessageIsQuestion,
  //Actions
  onCreatePoll,
  closeLocal,
  onMessageInputEnterKeyPressed,
  onMessageInputChange,
  sendNewReaction,
  stopShowingReaction,
  reactions,
  selectCheckUserAlreadyVote,
  setCheckAlreadyVote,
  permissions,
  //eventIsStarted
  eventIsStarted,
  onChangeIsQuestion,
  showNewMessagesButton,
  onClickNewMessagesButton,
  setMessageEndRef,
  firstLoad,
}) => {
  //scroll end when message send
  const messagesEndRef = useRef(null);

  const [messageChat, setMessage] = useState("");

  /* const onEmojiClick = (event, emojiObject) => {
    setMessage(messageChat.concat(emojiObject.emoji));
  }; */

  //show results finish
  const handleFinishSurvey = (poll) => {
    if (!checkIfPollAnswersIsValid(poll)) {
      return;
    }

    setCheckAlreadyVote(false);
    closeLocal(poll, true);
  };
  //scroll bottom effect when user send a message
  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (messages.length > 0) {
      const lastId = messages.length - 1;
      const lastMessage = document.getElementById(`message_${lastId}`);
      if (lastMessage && firstLoad && !mobileView) {
        scrollToBottom(firstLoad);
      }
    }
  }, [firstLoad, messages, mobileView]);

  return (
    <MainContainer>
      {
        reactions.map((r, index) => {
          return (
            <FloatingReaction stopShowingReaction={stopShowingReaction} key={`reaction_${index}`} reaction={r} />
          );
        })
      }

      <MessageContainer
        className="element"
        id="containerElement"
        mobileView={mobileView}
      >
        ){/* Messages*/}
        {showNewMessagesButton && <NewMessagesButton onClick={onClickNewMessagesButton} />}
        {checkIfInputIsAnArrayValid(messages).map((message, idx) => (
          <MessageCard
            key={idx}
            idx={idx}
            currentUserId={userId}
            message={message}
            fullScreenChat={fullScreen}
          />
        ))}
        <div
          id="lastMessage"
          style={{ float: "left", clear: "both" }}
          ref={(el) => {
            setMessageEndRef(el);
          }}
        />
        {/*Se tiene que validar que la encuesta esta activa para que al momento de*/}
        {/*renderizar no se borre Polls*/}
        {polls.map((poll) =>
          selectCheckUserAlreadyVote || poll.userId === userId ? (
            //grafico
            <>
              <ChartResults pollAnswers={pollAnswers[poll.id]} />
              {poll.userId === userId && poll.isOpen && Object.entries(permissions).length !== 0 && permissions?.Polls.includes(userPermission.USER_CAN_CREATE) ? (
                <div style={{ marginTop: "20px" }} className="row">
                  <div className="col-sm">
                    <button
                      className="btn btn-danger col-sm"
                      onClick={() => handleFinishSurvey(poll)}
                      disabled={permissions?.Polls.includes(userPermission.USER_CAN_CREATE) ? false : true}
                    >
                      Terminar
                    </button>
                  </div>
                </div>
              ) : null}
            </>
          ) : (
            <Element name={poll.id} id="PollCard">
              <PollCard
                checkAnswer={setCheckAlreadyVote}
                userVoteYet={selectCheckUserAlreadyVote}
                poll={poll}
                onAnswerPoll={onAnswerPoll}
              />
            </Element>
          )
        )}
        {
          /*
          <EmojiPicker
            onEmojiClick={onEmojiClick}
            disableAutoFocus={true}
            skinTone={SKIN_TONE_MEDIUM_DARK}
            groupNames={{ smileys_people: "PEOPLE" }}
          /> 
          */
        }
        <div ref={messagesEndRef} />
      </MessageContainer>

      <ChatMessageInputContainer>
        <InputCheckBoxMessage
          onChange={(evt) => { onChangeIsQuestion(evt.target.checked); }}
          type="checkbox"
          checked={newMessageIsQuestion}
          disabled={!eventIsStarted}
        />
        <SpanQuestionMessage>Marcar como pregunta</SpanQuestionMessage>

        <div
          id="input-container"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 65% 0% 35%)",
          }}
        >
          <MessageInput
            onKeyDown={(e) => {
              if (e.key === "Enter" && e.target.value !== "") {
                onMessageInputEnterKeyPressed(e);
                setMessage("");
              }
            }}
            disabled={!eventIsStarted}
            placeholder={
              eventIsStarted ? "Envie su mensaje..." : "Chat no disponible..."
            }
            type="text"
            value={messageChat}
            onChange={(e) => {
              setMessage(e.target.value);
              onMessageInputChange(e);
            }}
          />
          <EmoticonIcons fullScreenChat={fullScreen}>
            {Object.entries(permissions).length !== 0 ? (
              permissions?.Polls.includes(userPermission.USER_CAN_CREATE) ? (
                <Popup trigger={<BarChartOutlined />} modal>
                  {close => (<PollForm
                    roomId={roomId}
                    userId={userId}
                    close={close}
                    permissions={permissions.Polls}
                    polls={polls}
                    onCreatePoll={onCreatePoll}
                  />)}
                </Popup>
              ) : null
            ) : null}
          </EmoticonIcons>
          <ReactionPicker
            onSelect={(e) => {
              sendNewReaction(e, true);
            }}
          />
        </div>
      </ChatMessageInputContainer>
    </MainContainer>
  );
};

const mapStateToProps = (state) => ({
  selectCheckUserAlreadyVote: selectCheckUserAlreadyVote(state),
});

const mapDispatchToProps = (dispatch) => ({
  setCheckAlreadyVote: () => dispatch(sentCheckPollAnswer()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(Superchat));
