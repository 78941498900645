//Libs
import React from "react";
import { withRouter } from "react-router";
//Utils
import { FixString } from "Utils/FixString";
// import Truncate from "Utils/Truncate";
//Components
import enVivo from "Assets/en-vivo.svg";
//Config File
import { ROUTES } from "Config/config";
//Styles
import "./CollectionItem.css";

class CollectionItem extends React.Component {
  constructor() {
    super();
    this.state = {
      isHovered: false,
    };
  }

  render() {
    const { item, movies, history, index } = this.props;
    const { title, backdrop_path, url_stand } = item;
    const { isHovered } = this.state;
    // const para = Truncate(overview, 155, " ...leer más");
    var titlePath = movies ? FixString(title) : null;
    // var namePath = tvshow ? FixString(title) : null;

    return (
      <div
        className="collection-item"
        onMouseEnter={() => {
          this.setState({ isHovered: true });
        }}
        onMouseLeave={() => {
          this.setState({ isHovered: false });
        }}
      >
        <div
          onClick={() => {
            if (!url_stand) {
              return history.push(`${ROUTES.ROOM}/${titlePath}`);
            } else {
              history.push(ROUTES.SPONSOR);
            }
          }}
        >
          {isHovered && !url_stand ? (
            <video className="collection-item__movie-video" loop autoPlay>
              <source
                src={require("../../Assets/sample3.mp4")}
                type="video/mp4"
              />
            </video>
          ) : (
            <img
              src={backdrop_path}
              alt="movie"
              className="collection-item__movie-image"
            />
          )}
          {index !== null && (
            <div className="collection-item__hour">
              {
                [
                  "8:00 a.m.",
                  "9:00 a.m.",
                  "10:00 a.m.",
                  "11:00 a.m.",
                  "1:00 p.m.",
                  "2:00 p.m.",
                  "3:00 p.m.",
                  "4:00 p.m.",
                  "5:00 p.m.",
                  "6:00 p.m.",
                ][index % 10]
              }
              {index === 2 && (
                <img
                  style={{
                    display: "inline",
                    height: "20px",
                    marginLeft: "5px",
                    position: "relative",
                    top: "2px",
                  }}
                  src={enVivo}
                  alt=""
                />
              )}
            </div>
          )}
          <div className="collection-item__text">
            <h1 className="collection-item__title">{title}</h1>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CollectionItem);
