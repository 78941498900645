import directoryTypes from "./directory.types";

const INITIAL_STATE = {
  isFetching: true,
  data: [],
  loadingDirectoryData: false,
  totalDirectoryItem: null,
};

const directoryReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case directoryTypes.SET_DIRECTORY_DATA:
      const filteredData = Array.isArray(payload)
        ? payload.filter(
            (user) => state.data.findIndex((usr) => user.id === usr.id) === -1
          )
        : null;
      return {
        ...state,
        data: filteredData ? [...state.data, ...filteredData] : null,
        loadingDirectoryData: false
      };

    case directoryTypes.LOADING_DIRECTORY_DATA:
      return {
        ...state,
        loadingDirectoryData: true
      }
    case directoryTypes.CLEAR_DIRECTORY:
      return {
        isFetching: true,
        data: [],
        loadingDirectoryData: false,
        totalDirectoryItem: null
      }
    case directoryTypes.ADD_TOTAL_ITEMS:
      return {
        ...state,
        totalDirectoryItem: payload
      }

    default:
      return state;
  }
};

export default directoryReducer;
