import { createSelector } from "reselect";

const selectStories = (state) => state.stories;

export const selectStoriesData = createSelector(
  [selectStories],
  (stories) => stories.stories || []
);

export const selectIsStoriesFetching = createSelector(
  [selectStories],
  (stories) => stories.isFetching
);

export const selectIsShowStories = createSelector(
  [selectStories],
  (stories) => stories.isShowStories
);

export const selectStoryIndex = createSelector(
  [selectStories],
  (stories) => stories.storyIndex
);

export const selectTotalStoryItem = createSelector(
  [selectStories],
  (stories) => stories.storyTotalItem
);

export const selectIsOpenStory = createSelector(
  [selectStories],
  (stories) => stories.storyIsOpen
);
