import React from "react";
import { Wrapper } from "Components";

const DeleteIcon = () => (
  <Wrapper
    className="deleteButton"
    height="100%"
    display="flex"
    alignItems="center"
  >
    <svg
      style={{
        cursor: "pointer",
      }}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 5V4C17 2.89543 16.1046 2 15 2H9C7.89543 2 7 2.89543 7 4V5H4C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7H5V18C5 19.6569 6.34315 21 8 21H16C17.6569 21 19 19.6569 19 18V7H20C20.5523 7 21 6.55228 21 6C21 5.44772 20.5523 5 20 5H17ZM15 4H9V5H15V4ZM17 7H7V18C7 18.5523 7.44772 19 8 19H16C16.5523 19 17 18.5523 17 18V7Z"
        fill="#fff"
      />
      <path d="M9 9H11V17H9V9Z" fill="#fff" />
      <path d="M13 9H15V17H13V9Z" fill="#fff" />
    </svg>
  </Wrapper>
);

const SeeMore = (seeMoreUrl, canDeleteThiStory, storyId, onDeleteStory) => (
  <div>
    {canDeleteThiStory && (
      <div
        onClick={() => {
          onDeleteStory(storyId);
        }}
        style={styles.delete}
      >
        <DeleteIcon />
      </div>
    )}
    <div
      onClick={() => {
        document.getElementById("storiesBackdrop").click();
      }}
      style={styles.close}
    >
      <span style={styles.closeText}>X</span>
    </div>
    {seeMoreUrl && (
      <div
        style={styles.seeMore}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          window.open(seeMoreUrl, "_blank");
        }}
      >
        <span style={styles.seeMoreIcon}>⌃</span>
        <span style={styles.seeMoreText}>Ver más</span>
      </div>
    )}
  </div>
);

const styles = {
  close: {
    position: "fixed",
    display: "flex",
    right: "12px",
    top: "17px",
  },
  closeText: {
    cursor: "pointer",
    color: "#fff",
    filter: "drop-shadow(0 0px 2px rgba(0, 0, 0, 0.5))",
    fontSize: "18px",
  },
  delete: {
    position: "fixed",
    display: "flex",
    right: "35px",
    top: "17px",
  },
  seeMore: {
    height: "10vh",
    background: "linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2))",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-end",
    bottom: 0,
  },
  seeMoreText: {
    color: "white",
    textAlign: "center",
    letterSpacing: "0.1em",
    marginBottom: "2.2vh",
    textTransform: "capitalize",
    opacity: "1",
    fontSize: "0.8em",
    transition: "opacity 300ms ease-in-out",
    cursor: "pointer",
  },
  seeMoreIcon: {
    color: "white",
    textAlign: "center",
    letterSpacing: "0.2em",
    marginBottom: "0.4vh",
    opacity: "1",
    filter: "drop-shadow(0 0 5px black)",
    textTransform: "capitalize",
    transition: "opacity 300ms ease-in-out",
    cursor: "pointer",
  },
};

export default SeeMore;
