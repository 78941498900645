import React from "react";
import { Menu } from "antd";
import Label from "Components/Header/Label";
import { useHistory } from "react-router-dom";
import { ROUTES } from "Config/config";
//service
import { CompanyService } from "Services/CompanyService";
//redux
//selector
import { connect } from "react-redux";
import { selectedOrganizationId } from "Redux/Organization/organization-select";
import { selectCurrentUser } from "Redux/User/user-selectors";
//action
import {
  setModuleOrganization,
  orgnizacionUnseleted,
  dccLogout,
} from "Redux/DynamicConfigCompany/dcc-action";
import {
  clearOrganization,
  setOrganizationSeleted,
} from "Redux/Organization/organizarion-action";
import { changeModalUserInfoState } from "Redux/User/user-actions";
import { clearAssitant } from "Redux/ContactUs/assistant-actions";
import { clearDirectory } from "Redux/Directory/directory.actions";
import { clearSpeaker } from "Redux/Speakers/speaker-actions";
import { clearNews } from "Redux/newsCardiOne/new-cardi-one-actions";
import { clearCategorie } from "Redux/Categories/categories-actions";

const { SubMenu } = Menu;

const SwitchProfile = ({
  roles,
  logout,
  dccLogoutCompany,
  clearOrganization,
  unselected,
  currentUser,
  changeStateModal,
  setOrganizationModule,
  setOrganizationSeleted,
  organizationId,
  mobileView,
  setNavMenuOpen,
  setDropDownShow,
  clearAssitant,
  clearDirectory,
  clearSpeaker,
  clearNews,
  clearCategorie,
}) => {
  const history = useHistory();
  //This event redirect a organizacion view
  // const handleRedirectOrganization = async () => {
  //   const companyData = await CompanyService.getCompanyData(
  //     window.location.host
  //   ).then((data) => data);
  //   unselected(companyData.company);
  //   history.push(ROUTES.ORGANIZATION);
  // };
  const handleRedirectOrganization = async (organization) => {
    const companyData = await CompanyService.getCompanyData(
      window.location.host
    ).then((data) => data);

    unselected(companyData.company);
    clearCategorie();
    clearOrganization();
    clearAssitant();
    clearDirectory();
    clearSpeaker();
    clearNews();

    const rolesModules =
      Array.isArray(organization.availableModules) &&
      organization.availableModules.map(
        (orgnizationModule) => orgnizationModule.module
      );

    if (rolesModules !== 0) {
      setOrganizationModule(organization);
      setOrganizationSeleted(organization);

      history.push(ROUTES.HOME);
    }
  };

  return (
    <Menu
      fontSize="16px"
      selectable={false}
      theme={"dark"}
      style={{
        background: "rgb(20, 20, 20)",
        color: "rgb(255, 255, 255)",
        fontsize: "16px",
        width: "200px",
      }}
    >
      {Array.isArray(roles) &&
        roles.map(({ roleName, iconUrl }, index) => (
          <Menu.Item key={index}>
            <a
              href="."
              className="ant-dropdown-link"
              style={{ color: "rgb(255, 255, 255)" }}
            >
              <img
                alt=""
                src={iconUrl}
                width="40px"
                circular="10%"
                style={{ marginRight: "24px" }}
              />
              {roleName}
            </a>
          </Menu.Item>
        ))}
      <Menu.Item style={{ borderTop: "2px solid #00B2F3" }}>
        <Label
          pointer
          onClick={() => {
            // changeStateModal(true);
            history.push(ROUTES.EDIT_PROFILE);
            if (mobileView) {
              setNavMenuOpen(false);
              setDropDownShow(false);
            }
          }}
        >
          Cuenta
        </Label>
      </Menu.Item>
      {currentUser !== null && currentUser.organizations.length >= 2 && (
        <SubMenu title="Organizaciones">
          {currentUser.organizations.map(
            (organization, index) =>
              organization.id !== organizationId && (
                <Menu.Item
                  key={index}
                  onClick={() => {
                    handleRedirectOrganization(organization);
                    if (mobileView) {
                      setNavMenuOpen(false);
                      setDropDownShow(false);
                    }
                  }}
                >
                  {organization.name}
                </Menu.Item>
              )
          )}
        </SubMenu>
      )}
      {!mobileView && (
        <Menu.Item>
          <Label
            pointer
            onClick={async () => {
              const companyData = await CompanyService.getCompanyData(
                window.location.host
              ).then((data) => data);
              unselected(companyData.company);
              clearOrganization();
              history.push(ROUTES.SIGNIN);
              dccLogoutCompany();
              logout();
            }}
          >
            Cerrar sesión
          </Label>
        </Menu.Item>
      )}
    </Menu>
  );
};
const mapStateToProps = (state) => ({
  currentUser: selectCurrentUser(state),
  organizationId: selectedOrganizationId(state),
});
const mapDispatchToProps = (dispatch) => ({
  clearOrganization: () => dispatch(clearOrganization()),
  unselected: (company) => dispatch(orgnizacionUnseleted(company)),
  changeStateModal: (state) => dispatch(changeModalUserInfoState(state)),
  dccLogoutCompany: () => dispatch(dccLogout()),
  setOrganizationModule: (organizationSelect) =>
    dispatch(setModuleOrganization(organizationSelect)),
  setOrganizationSeleted: (organization) =>
    dispatch(setOrganizationSeleted(organization)),
  clearAssitant: () => dispatch(clearAssitant()),
  clearDirectory: () => dispatch(clearDirectory()),
  clearSpeaker: () => dispatch(clearSpeaker()),
  clearNews: () => dispatch(clearNews()),
  clearCategorie: () => dispatch(clearCategorie()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(SwitchProfile));
