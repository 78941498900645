//Libs
import styled from "styled-components";
import { Select } from "antd";

export default styled(Select)`
  width: ${(props) => (props.width ? props.width : props.theme.select.width)};
  height: ${(props) =>
    props.height ? props.height : props.theme.select.height};
  margin: ${(props) =>
    props.margin ? props.margin : props.theme.select.margin};
  color: ${(props) => (props.color ? props.color : props.theme.select.color)};

  background-color: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : props.theme.select.backgroundColor};

  letter-spacing: ${(props) =>
    props.letterSpacing
      ? props.letterSpacing
      : props.theme.select.letterSpacing};
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : props.theme.select.fontSize};
`;
