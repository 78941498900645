import styled from "styled-components";
import { SmileOutlined } from "@ant-design/icons";

const StyledFloatSmileButton = styled(SmileOutlined)`
  padding: ${(props) => props.padding || props.theme.floatSmileButton.padding};
  color: ${(props) => props.color || props.theme.floatSmileButton.color};
  position: ${(props) =>
    props.position || props.theme.floatSmileButton.position};
  top: ${(props) => props.top || props.theme.floatSmileButton.top};
  bottom: ${(props) => props.bottom || props.theme.floatSmileButton.bottom};
  left: ${(props) => props.left || props.theme.floatSmileButton.left};
  right: ${(props) => props.right || props.theme.floatSmileButton.right};
  z-index: ${(props) => props.zIndex || props.theme.floatSmileButton.zIndex};
  font-size: ${(props) =>
    props.fontSize || props.theme.floatSmileButton.fontSize};
  transform: ${(props) =>
    props.transform || props.theme.floatSmileButton.transform};
  background-color: ${(props) =>
    props.backgroundColor || props.theme.floatSmileButton.backgroundColor};
  border-radius: ${(props) =>
    props.borderRadius || props.theme.floatSmileButton.borderRadius};
  box-shadow: ${(props) =>
    props.boxShadow || props.theme.floatSmileButton.boxShadow};
  transform: ${(props) =>
    props.transform || props.theme.floatSmileButton.transform};
  transition: ${(props) =>
    props.transition || props.theme.floatSmileButton.transition};
  cursor: ${(props) => props.cursor || props.theme.floatSmileButton.cursor};
  &:hover {
    color: ${(props) =>
      props.hoverColor || props.theme.floatSmileButton.hover.color};
  }
`;

export default StyledFloatSmileButton;
