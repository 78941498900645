import React from "react";
import { Link } from "react-router-dom";
import { Wrapper, Button, Grid } from "Components";
import { Typography } from "antd";
import moment from "moment";

//service
import MixPanel from "Services/MixPanelService";

const ViewBeforeVideo = ({
    isLive,
    liveRoomId,
    resource,
    categoryRef,
    poster,
    currentUser,
    ROUTES,
    fetchResourceMoreViewsCount,
    setVideoProperties,
    organizationId,
    mobileView,
}) => {
    const { id, name, speakers, description, resourceUrl, isChatAvailable, rooms } =
        resource;

  const toLink =
    isLive && liveRoomId
      ? `${ROUTES.ROOM}/${liveRoomId}/${id}${categoryRef ? `?ref=${categoryRef}` : ""
      }`
      : `${ROUTES.VIDEO}/${id}${categoryRef ? `?ref=${categoryRef}` : ""}`;

  const { Title } = Typography;

    return (
        <div
            style={{
                display: "grid",
                gridTemplateRows: "auto",
                gridTemplateColumns: "100%",
            }}
        >
            <div
                style={{
                    backgroundImage: `url(${poster})`,
                    backgroundSize: "cover",
                    height: mobileView ? "calc((100vw - 16px) * 0.5625)" : "433px",
                }}
            >
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        position: "relative",
                    }}
                >
                    <div
                        style={{
                            position: "absolute",
                            bottom: '20px',
                            left: '20px'
                        }}
                    >
                        <Link to={toLink}>
                            <Button
                                primary={true}
                                onClick={() => {
                                    MixPanel.track(MixPanel.TYPES.ENTER_EVENT_ONLINE, {
                                        userId: currentUser.id,
                                        EventDate: moment().format("MMMM Do YYYY, h:mm:ss a"),
                                        EventName: name,
                                        liveRoomId: liveRoomId,
                                    });
                                    fetchResourceMoreViewsCount(currentUser.token, {
                                        organizationId,
                                        resourceId: id,
                                    });
                                    setVideoProperties({
                                        videoId: id,
                                        videoUrl: resourceUrl,
                                        withChat: isChatAvailable,
                                        streamId: rooms[0]?.streamId,
                                        streamWss: rooms[0]?.streamWss,
                                    });
                                }}
                            >
                                Ver video
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
            <Grid display="Grid" columns="repeat(auto-fit, minmax(240px, 1fr))">
                <Wrapper
                    display="flex"
                    flexDirection="column"
                    padding="24px"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                >
                    <Title
                        style={{ color: "white", fontWeight: "700", fontSize: "24px" }}
                    >
                        {name}
                    </Title>
                    <p style={{ color: "white", fontWeight: "400", fontSize: "14px" }}>
                        {description}
                    </p>
                </Wrapper>
                <Wrapper
                    display="flex"
                    flexDirection="column"
                    padding="24px"
                    height="100%"
                    alignItems="flex-start"
                >
                    <Title
                        style={{ color: "white", fontWeight: "700", fontSize: "18px" }}
                    >
                        Speaker
                    </Title>
                    <ul style={{ listStyle: "none" }}>
                        {speakers.map((value, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <li
                                        style={{
                                            color: "white",
                                            fontWeight: "400",
                                            fontSize: "16px",
                                        }}
                                    >{`${value.name}`}</li>
                                </React.Fragment>
                            );
                        })}
                    </ul>
                </Wrapper>
            </Grid>
        </div>
    );
};
export default ViewBeforeVideo;
