//Libs
import React, { useRef, useCallback } from "react";
import useNearScreen from "Hooks/useNearScreen";
import debounce from "just-debounce-it";
import { connect } from "react-redux";
import {
  Wrapper,
  Grid,
  Card,
  Modal,
  ErrorLabel,
  FilterAndSearchBar,
  Avatar,
  Spinner,
  Title
} from "Components";
import _ from "lodash";
import SpeakerService from "Services/SpeakerService";
//Actions
import { setShowVideos, setSpeakers } from "Redux/Speakers/speaker-actions";
import { resetSearchInputValue } from "Redux/Search/search-actions";
import { setVideoProperties } from "Redux/Video/video-actions";
//Selectors
import { selectSearchInputValue } from "Redux/Search/search-selectors";
import { selectCurrentUser } from "Redux/User/user-selectors";
import {
  selectIsShowVideos,
  selectSpeakers,
  selectTotalSpeakerItem,
  selectloadingSpeaker
} from "Redux/Speakers/speaker-selectors";
import { selectedOrganizationId } from "Redux/Organization/organization-select";

//Services
import { filterData } from "Services/SearchInputService";

const ResourceItem = React.lazy(() =>
  import("../../Components/Resources/ResourceItem")
);

const Speakers = ({
  mobileView,
  isShowVideos,
  setShowVideos,
  sliceHeight,
  sliceContainerHeight,
  currentUser,
  searchInputValue,
  setVideoProperties,
  setSpeakers,
  speakers,
  resetSearchInputValue,
  organizationId,
  loadingSpeaker,
  totalSpeakerItem
}) => {
  const visorRef = useRef();
  const nowShowRef = useRef();
  const canObserveVisor = !loadingSpeaker && totalSpeakerItem > 0;
  const [isNearScreen] = useNearScreen({
    externalRef: canObserveVisor ? visorRef : null,
    rootMargin: "60%",
    observeOnce: false,
  });

  const handleNextpage = useCallback(
    debounce(
      () =>
        setSpeakers(currentUser.token, { organizationId, speakerLastRow: speakers.length }),
      200
    ),
    [speakers]
  );

  React.useEffect(() => {
    if (isNearScreen && canObserveVisor && speakers.length < totalSpeakerItem) {
      handleNextpage();
    }
  }, [isNearScreen, canObserveVisor, handleNextpage, speakers.length, totalSpeakerItem]);

  const [resources, setResources] = React.useState({
    speaker: {},
    data: [],
    isFetching: false,
  });

  React.useEffect(() => {
    setSpeakers(currentUser.token, { organizationId })
    resetSearchInputValue();
  }, [currentUser.token, organizationId, resetSearchInputValue, setSpeakers]);

  const filteredSpeakers = speakers ? filterData(speakers, searchInputValue, "users") : [];
  return !speakers ? (
    <Wrapper
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Title>No hay speaker disponibles.</Title>
    </Wrapper>
  ) : loadingSpeaker ? <Spinner /> : (
    <>
      <Wrapper
        ref={nowShowRef}
        alignItems="left"
        display="flex"
        flexDirection="column"
        margin="90px 10vw 10px 10vw"
      >
        {resources.isFetching ? <Spinner /> : isShowVideos && (
          <Modal
            style={{ width: "100%" }}
            visible={isShowVideos}
            onCancel={() => setShowVideos(false)}
            footer={null}
            width={640}
          >
            {resources.speaker && (
              <div style={{ textAlign: "center" }}>
                <Avatar
                  width={"150px"}
                  height={"150px"}
                  src={resources.speaker.photoUrl}
                  style={{ marginBottom: "10px" }}
                />
                <h2 style={{ color: "#fff" }}>{`${resources.speaker.name} ${resources.speaker.firstLastName || ""
                  }`}</h2>
                <p style={{ color: "#fff" }}>{resources.speaker.countryName}</p>
                <p style={{ color: "#fff" }}>{resources.speaker.bio}</p>
              </div>
            )}
            {!resources.isFetching && resources.data.length === 0 && (
              <div style={{ textAlign: "center" }}>
                <span style={{ color: "#ba000d" }}>
                  Este speaker no cuenta con recursos asignados.
                </span>
              </div>
            )}
            {resources.isFetching ? <Spinner /> : <Grid gap="1em 1em" margin="20px 0 0 0" columns={"repeat(2, 50%)"}>
              {resources?.data.map((video, idx) => (
                <div key={idx} onClick={() => setShowVideos(false)}>
                  <ResourceItem
                    resource={video}
                    width={"100%"}
                    height={sliceHeight}
                    containerHeight={sliceContainerHeight}
                    mobileView={mobileView}
                    setVideoProperties={setVideoProperties}
                    currentUser={currentUser}
                  />
                </div>
              ))}
            </Grid>}
          </Modal>
        )}

        <div style={{ marginBottom: "1em" }}>
          <h1
            style={{
              color: "#fff",
            }}
          >
            Speakers CardiOne
          </h1>
          <FilterAndSearchBar
            mobileView={mobileView}
            onEnterKeyPressed={() =>
              SpeakerService.searchInSpeakers(
                currentUser.token,
                searchInputValue,
                { organizationId }
              ).then((data) => setSpeakers(data))
            }
          />
        </div>
        <Grid
          style={{ marginBottom: "120px" }}
          columns={`repeat(${mobileView ? 1 : 3}, calc(80vw/3))`}
          gap="1em 1em"
        >
          {Array.isArray(filteredSpeakers) &&
            _.orderBy(filteredSpeakers, (s) => s.name, ["asc"]).map(
              (speaker, idx) => (
                <div
                  key={idx}
                  onClick={() => {
                    setShowVideos(true);
                    setResources({ ...resources, isFetching: true, data: [] });
                    SpeakerService.getResourceBySpeakerId(
                      currentUser.token,
                      speaker.id,
                      { organizationId }
                    ).then((data) => {
                      if (Array.isArray(data)) {
                        setResources({
                          ...resources,
                          data,
                          isFetching: false,
                          speaker,
                        });
                      } else {
                        setResources({
                          ...resources,
                          isFetching: false,
                          speaker,
                        });
                      }
                    });
                  }}
                >
                  <Card
                    width={mobileView ? "calc(80vw)" : "calc(80vw/3)"}
                    styles={{ backgroundColor: "#fff", cursor: "pointer" }}
                    bodyStyle={{ padding: "18px" }}
                    title={`${speaker.professionalTittles[0] ? speaker.professionalTittles[0].name : ""} ${speaker.name
                      } ${speaker.firstLastName || ""}`}
                    subtitle={speaker.countryName}
                    description={
                      speaker.bio
                        ? speaker.bio.length > 100
                          ? `${speaker.bio.substring(0, 100)}...`
                          : speaker.bio
                        : ""
                    }
                    avatarSrc={speaker.photoUrl}
                  />
                </div>
              )
            )}
        </Grid>
        {searchInputValue && filteredSpeakers.length === 0 && (
          <ErrorLabel color="#00B2F3" value="No se encontró ningún speaker." />
        )}
      </Wrapper>
      {canObserveVisor && <div ref={visorRef} />}
    </>
  );
};

const mapStateToProps = (state) => ({
  currentUser: selectCurrentUser(state),
  isShowVideos: selectIsShowVideos(state),
  speakers: selectSpeakers(state),
  searchInputValue: selectSearchInputValue(state),
  organizationId: selectedOrganizationId(state),
  loadingSpeaker: selectloadingSpeaker(state),
  totalSpeakerItem: selectTotalSpeakerItem(state)
});

const mapDispatchToProps = (dispatch) => ({
  setShowVideos: (payload) => dispatch(setShowVideos(payload)),
  setSpeakers: (token, whereOpts) => dispatch(setSpeakers(token, whereOpts)),
  setVideoProperties: (payload) => dispatch(setVideoProperties(payload)),
  resetSearchInputValue: () => dispatch(resetSearchInputValue()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Speakers);
