import styled from "styled-components";
import { Modal } from "antd";

const StyledModal = styled(Modal)`
  .ant-modal-body {
    background-color: #141414;
  }
  .ant-modal-content {
    background-color: #141414;
  }
  ${(props) => props.fullHeight ? 'width: calc(100vh * 1.77 - 50px) !important; transform-origin: none !important;' : ''};
`;

export default StyledModal;
