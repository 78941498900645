//Libs
import { useEffect, useState } from "react";

const TIME_DEBOUNCE = 0;

/* WARNING:
 * useScrolling solo debe llamarse una vez en toda la app (Llamar en el top del arbol de componentes y repartir las props)
 */
export default function useScrolling() {
  const [config, setConfiguration] = useState({
    opaqueNavbar: false,
    lastScroll: 0,
    scrollUp: false,
  });

  //SCROLL EVENT
  useEffect(() => {
    let onResizeCallTimer = null;

    window.onscroll = () => {
      clearTimeout(onResizeCallTimer);
      onResizeCallTimer = setTimeout(() => {
        //Header
        const currentScroll =
          document.documentElement.scrollTop || document.body.scrollTop;

        setConfiguration({
          opaqueNavbar: window.scrollY > 0,
          lastScroll: currentScroll,
          scrollUp: !(currentScroll > 0 && config.lastScroll <= currentScroll),
        });
      }, TIME_DEBOUNCE);
    };
  }, [config.lastScroll]);

  return {
    opaqueNavbar: config.opaqueNavbar,
    lastScroll: config.lastScroll,
    scrollUp: config.scrollUp,
  };
}
