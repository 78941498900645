import React from "react";
import AudioPlayer from "react-h5-audio-player";
import 'react-h5-audio-player/lib/styles.css';
import MixPanel from "Services/MixPanelService"

import { Wrapper, Title, Image } from "Components";

const EventBeforePlayAudio = ({ resource }) => {
  //Legacy the resource
  const {
    mobileView,
    mobilePosterUrl,
    resourceUrl,
    posterUrl,
    description,
    name,
  } = resource;
  const poster = mobileView ? mobilePosterUrl : posterUrl;

  return (
    <>
      <Image src={poster}></Image>
      <Wrapper padding="24px" display="flex" flexDirection="column">
        <Title>{name}</Title>
        <p
          style={{
            color: "#fff",
            alignSelf: "flex-start",
            fontSize: "17px",
            fontWeight: "700",
          }}
        >
          Descripción:
        </p>
        <p
          style={{
            color: "#fff",
            fontSize: "14px",
            alignSelf: "flex-start",
          }}
        >
          {description}
        </p>
        <AudioPlayer onPlay={MixPanel.track(MixPanel.TYPES.ENTER_LISTEN_PODCAST, {

        })} src={resourceUrl} />
      </Wrapper>
    </>
  );
};

export default EventBeforePlayAudio;
