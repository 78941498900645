import mixpanel from "mixpanel-browser";

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
  persistence: "localStorage",
  secure_cookie: true,
});

const env_check = process.env.NODE_ENV === "production";

export default {
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
  TYPES: {
    //SIGNUP
    SUCCESSFUL_SIGNUP: "SUCCESSFUL_SIGNUP",
    UNSUCCESSFUL_SIGNUP: "UNSUCCESSFUL_SIGNUP",
    //SIGNIN
    SUCCESSFUL_LOGIN: "SUCCESSFUL_LOGIN",
    UNSUCCESSFUL_LOGIN: "UNSUCCESSFUL_LOGIN",
    //FORGOT PASSWORD
    FORGOT_PASSWORD: "FORGOT_PASSWORD",
    SUCCESSFUL_RESET_PASSWORD: "SUCCESSFUL_RESET_PASSWORD",
    UNSUCCESSFUL_RESET_PASSWORD: "UNSUCCESSFUL_RESET_PASSWORD",
    //ROOM
    PLAY_ROOM: "PLAY_ROOM",
    //CATEGORIES
    SCHEDULE_DOWNLOAD: "SCHEDULE_DOWNLOAD",
    //RESOURCE
    CLICK_RESOURCE: "CLICK_RESOURCE",
    //STORY
    CLICK_STORY: "CLICK_STORY",
    PLAY_STORY: "PLAY_STORY",
    //ENTER EVENT ONLINE
    ENTER_EVENT_ONLINE: "ENTER_EVENT_ONLINE",
    //BE ABLE TO LISTEN PODCAST
    ENTER_LISTEN_PODCAST: "ENTER_LISTEN_PODCAST",
    //POLL
    CREATE_POLL: "CREATE_POLL",
    CREATE_MULTIPLE_OPCION_POLL: "CREATE_MULTIPLE_OPCION",
    USER_SELECT_OPCION_QUESTION_POLL: "USER_SELECT_OPCION_QUESTION",
    DELETE_ANSWER_OPTION_POLL: "DELETE_ANSWER_OPTION_POLL",
    //MESSAGE
    CREATE_MESSAGE_QUESTION: "CREATE_MESSAGE_QUESTION",
    //REACTION
    REACTION_WITH_CHAT_ROOM: "REACTION_WITH_CHAT_ROOM",
    //UPDATE USER
    UPDATE_USER_DATA: "UPDATE_USER_DATA",
  },
};
