import AssistantActionTypes from "./assistant-types";
import UserActionTypes from "Redux/User/user-types";
import ContactService from "Services/ContactUsService";

import { CompanyService } from "Services/CompanyService";
import { orgnizacionUnseleted } from "Redux/DynamicConfigCompany/dcc-action";
import { dccLogout } from "Redux/DynamicConfigCompany/dcc-action";
import { clearOrganization } from "Redux/Organization/organizarion-action";
import { logout } from "Redux/User/user-actions";

export const setCreateAppointment = (payload) => ({
  type: AssistantActionTypes.SET_CREATE_APPOINTMENT,
  payload,
});

export const saveContactData = (token, {organizationId, lasRowContact}) => {
  return (dispatch) => {
    if (!token) {
      dispatch({
        type: UserActionTypes.SHOW_MESSAGE,
        payload: {
          body: "Se necesita un token válido para obtener las noticias",
        },
      });
      return;
    }
    dispatch({
      type: AssistantActionTypes.LOADING_ASSISTANT,
      payload: true
    });

    ContactService.fetchContactData(token, {organizationId, lasRowContact}).then(async (data) => {

      if (data === 401) {
        const companyData = await CompanyService.getCompanyData(
          window.location.host
        ).then((data) => data);

        dispatch(orgnizacionUnseleted(companyData.company));
        dispatch(clearOrganization());
        dispatch(dccLogout());
        dispatch(logout(true));
      } else {
        dispatch({
          type: AssistantActionTypes.ADD_TOTAL_CONTACT_ITEM,
          payload: data.contacts.count
        })

        dispatch({
          type: AssistantActionTypes.SAVE_CONTACT_DATA,
          payload: data.contacts.rows.length === 0 ? null : data.contacts.rows,
        });
      }
    });
  };
};

export const clearAssitant = () => {
  return {
    type: AssistantActionTypes.CLEAR_ASSISTANT,
  };
};