const UserActionTypes = {
  SET_CURRENT_USER: "USER_SET_CURRENT_USER",
  SET_PROFILE_PHOTO_UPDATED: "USER_SET_PROFILE_PHOTO_UPDATED",
  SET_PROFILE_PHOTO_CHANGED: "USER_SET_PROFILE_PHOTO_CHANGED",
  SET_NAV_MENU_OPEN: "USER_SET_NAV_MENU_OPEN",
  SHOW_MESSAGE: "USER_SHOW_MESSAGE",
  HIDE_TOUR: "USER_HIDE_TOUR",
  LOGOUT: "USER_LOGOUT",
  TOKEN_RECOVERY_PASSWORD: "TOKEN_RECOVERY_PASSWORD",
  CONFIG_USER_MODAL_STATE: "CONFIG_USER_MODAL_STATE",
  UPDATE_LOCAL_CURRENT_NAME: "UPDATE_LOCAL_CURRENT_NAME",
  GET_PROFILE_PHOTO: "GET_PROFILE_PHOTO",
  CHANGE_STATUS_MESSAGE_EXPIRED: "CHANGE_STATUS_MESSAGE_EXPIRED",
  RELOADING_MESSAGE: "RELOADING_MESSAGE",
  CHANGE_REDUX_NAME_USER: "CHANGE_REDUX_NAME_USER",
  LOADING_USER_DATA: "LOADING_USER_DATA",
  ADD_CURRENT_USER_INFO: "ADD_CURRENT_USER_INFO"
};

export default UserActionTypes;
