import CategoryActionTypes from "./categories-types";
import UserActionTypes from "Redux/User/user-types";
import {
  fetchCategories,
  fetchmoreResourceCategory,
  fetchRecommendDataresource,
  fetchGetMoreViewResource
} from "Services/CategoryService";
import { CompanyService } from "Services/CompanyService";
import { orgnizacionUnseleted } from "Redux/DynamicConfigCompany/dcc-action";
import { dccLogout } from "Redux/DynamicConfigCompany/dcc-action";
import { clearOrganization } from "Redux/Organization/organizarion-action";
import { logout } from "Redux/User/user-actions";

export function getCategories(token, organizationId) {
  return (dispatch) => {
    if (!token) {
      dispatch({
        type: UserActionTypes.SHOW_MESSAGE,
        payload: {
          body: "Se necesita un token válido para obtener las categorias",
        },
      });
      return;
    }

    dispatch({
      type: CategoryActionTypes.SET_LOADING_CATEGORY_DATA,
      payload: true,
    });

    fetchCategories(token, organizationId)
      .then(async (categories) => {
        if (categories === 401) {
          const companyData = await CompanyService.getCompanyData(
            window.location.host
          ).then((data) => data);
          dispatch(orgnizacionUnseleted(companyData.company));
          dispatch(clearOrganization());
          dispatch(dccLogout());
          dispatch(logout(true));
        }
        else {
          dispatch({
            type: CategoryActionTypes.SET_CATEGORY_DATA,
            payload: categories,
          });
        }
      })
      .catch((err) => console.log(err));
  };
}

export function gertMoreResource(
  token,
  { organizationId, categoryId, resourcesLastRow }
) {
  return (dispatch) => {
    if (!token || !categoryId) {
      dispatch({
        type: UserActionTypes.SHOW_MESSAGE,
        payload: {
          body: "Se necesita un token válido y un Id de categoría para obtener recursos adicionales",
        },
      });
      return;
    }
    dispatch({
      type: CategoryActionTypes.SET_LOADING_MORE_ADDITIONAL_VIDEOS,
      payload: { categoryId, loading: true },
    });

    fetchmoreResourceCategory(token, {
      organizationId,
      categoryId,
      resourcesLastRow,
    })
      .then((resources) => {
        dispatch({
          type: CategoryActionTypes.SET_LOADING_MORE_RESOURCE,
          payload: { resources, categoryId },
        });
      })
      .catch((err) => console.log(err));
  };
}

//recommend resource for you
export const getResourceRecommend = (token, { organizationId, page }) => {
  return (dispatch) => {
    if (!token || !organizationId) {
      dispatch({
        type: UserActionTypes.SHOW_MESSAGE,
        payload: {
          body: "Se necesita un token válido y un Id de organization para obtener recursos adicionales",
        },
      });
    }
    fetchRecommendDataresource(token, { organizationId, page })
      .then((resources) => {
        dispatch({
          type: CategoryActionTypes.GET_RECOMMEND_RESOURCE_ITEM,
          payload: resources,
        });
      })
      .catch((err) => console.log(err));
  };
};

export const clearCategorie = () => ({
  type: CategoryActionTypes.CLEAR_CATEGORIES,
});

// This method brings the most viewed resources 
export const getTheMoreViewResource = (token, { organizationId, page }) => {
  return (dispatch) => {
    if (!token || !organizationId) {
      dispatch({
        type: UserActionTypes.SHOW_MESSAGE,
        payload: {
          body: "Se necesita un token válido y un Id de organization para obtener recursos adicionales",
        },
      });
    }
    fetchGetMoreViewResource(token, { organizationId, page })
      .then((resources) => {
        dispatch({
          type: CategoryActionTypes.GET_MORE_VIEW_RESOURCE,
          payload: resources,
        });
      })
      .catch((err) => console.log(err));
  };
}
