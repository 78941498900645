import React from 'react'
import ConfigUser from 'Components/configUser/configUser';

const EditProfile = ({ mobileView }) => {
    return (
        <ConfigUser mobileView={mobileView} />
    );
}

export default EditProfile;
