//Services
import FetchPlus from "Services/FetchPlus";
//Config
import { API_URL } from "Config/config";

const storiesBase = `${API_URL}organizations`;
const userStoryViewsBase = `${API_URL}user-story-views`;

// export async function fetchStories(
//   token,
//   { storiesLimit = 5, storiesLastRow = 0 } = {}
// ) {
//   const options = {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   };

//   const stories = await FetchPlus(
//     `${storiesBase}/${storiesLimit}/${storiesLastRow}`,
//     options
//   );
//   return stories || [];
// }

export async function fetchOptimizedStories(token, organizationId) {
  const options = {
    headers: {
      Authorization: token,
    },
  };
  return FetchPlus(`${storiesBase}/${organizationId}/stories`, options)
    .then((stories) => stories || [])
    .catch((err) => {
      console.log(err);
    });
}

export async function saveStory(
  token,
  userId,
  story,
  isVideo,
  seeMoreUrl,
  sendMessage,
  organizationId
) {
  if (!token || !userId || !story) {
    sendMessage({
      body: "Se necesita un token y un recurso para realizar esta acción",
    });
    return;
  }

  if (
    seeMoreUrl?.indexOf("http://") === -1 &&
    seeMoreUrl?.indexOf("https://") === -1
  ) {
    seeMoreUrl = "https://".concat(seeMoreUrl);
  }

  seeMoreUrl =
    !seeMoreUrl || seeMoreUrl === "" ? null : encodeURIComponent(seeMoreUrl);

  const formData = new FormData();
  formData.append("file", story); //"file" is a Keyword. No change

  const options = {
    method: "POST",
    headers: {
      Authorization: token,
      isVideo: isVideo,
      seeMoreUrl: seeMoreUrl,
    },
    body: formData,
  };

  return FetchPlus(`${storiesBase}/${organizationId}/user/stories`, options)
    .then((res) => res)
    .catch((errorCode) => {
      if (errorCode === 401 || errorCode === 403) {
        sendMessage({
          body: "La cuenta que posee no puede realizar esta acción",
        });
      } else {
        sendMessage({
          body: "Hubo un problema al subir la foto. Por favor vuelva a intentarlo",
        });
      }
      return false;
    });
}

export async function paranoiaDeleteStoryById(
  token,
  storyId,
  organizationId,
  userId
) {
  if (!token || !storyId) {
    return false;
  }

  const options = {
    method: "PUT",
    headers: {
      Authorization: token,
    },
  };

  return FetchPlus(
    `${storiesBase}/${organizationId}/users/${userId}/stories/${storyId}`,
    options
  )
    .then((res) => !!res)
    .catch((err) => console.log(err));
}

export async function createUserStoryViews(
  token,
  storyId,
  userId,
  watchedPercent,
  watchedUntilFinish
) {
  if (!token || !storyId || !userId) {
    return false;
  }

  // const options  ={
  //     headers: {//TODO: Terminar el create user story view
  //       Authorization:
  //     }
  // }

  return FetchPlus(
    `${userStoryViewsBase}/${storyId}/${userId}/${watchedPercent}/${watchedUntilFinish}`
  )
    .then((res) => !!res)
    .catch((err) => console.log(err));
}
