import React, {useEffect, useRef, useCallback} from "react";
import useNearScreen from "Hooks/useNearScreen";
import debounce from "just-debounce-it";
import {Wrapper, Grid, Card, Spinner,Title} from "Components";
import _ from "lodash";
import {connect} from "react-redux";
//redux
import {getAllNews} from "Redux/newsCardiOne/new-cardi-one-actions";
import {selectCurrentUser} from "Redux/User/user-selectors";
import {selectNewsCardiOneNews, selectLoadingNews, selectTotalNewsItem} from "Redux/newsCardiOne/new-cardi-one-selectors";
import {selectedOrganizationId} from "Redux/Organization/organization-select";
import {MdPictureAsPdf, MdVideocam, MdLink} from "react-icons/md";

const DownLoadPdf = ({url, type}) => {
	if (type === "video") {
		return (
			<a href={url} rel="noopener noreferrer" target="_blank">
      <span>
        <i style={{marginRight: "5px", fontSize: "20px"}}>
          <MdVideocam/>
        </i>
        Mire el {type}
      </span>
			</a>
		);
	}

	if (type === "download") {
		return (
			<a href={url} target="_blank" rel="noopener noreferrer" download>
      <span>
        <i style={{marginRight: "5px", fontSize: "20px"}}>
          <MdPictureAsPdf/>
        </i>
        Descargar Recurso
      </span>
			</a>
		);
	}

	if (type === "web") {
		return (
			<a href={url} target="_blank" rel="noopener noreferrer">
      <span>
        <i style={{marginRight: "5px", fontSize: "20px"}}>
          <MdLink/>
        </i>
        Visite la pagina {type}
      </span>
			</a>
		);
	}


};

const News = ({currentUser, getNews, news, mobileView, organizationId, loadingNews, totalNewsItem}) => {

	const visorRef = useRef();
	const nowShowRef = useRef();
	const canObserveVisor = !loadingNews && totalNewsItem > 0;
	const [isNearScreen] = useNearScreen({
		externalRef: canObserveVisor ? visorRef : null,
		rootMargin: "60%",
		observeOnce: false,
	});
	const handleNextpage = useCallback(
		debounce(
			() =>
				getNews(currentUser.token, {organizationId, lastRowNews: news.length}),
			200
		),
		[news]
	);

	React.useEffect(() => {
		if (isNearScreen && canObserveVisor && news.length < totalNewsItem) {
			handleNextpage();
		}
	}, [isNearScreen, canObserveVisor, handleNextpage, news.length, totalNewsItem]);

	useEffect(() => {
		getNews(currentUser.token, {organizationId});
	}, [currentUser.token, getNews, organizationId]);

	return !news ? (
			<Wrapper
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
				height="100vh">
				<Title>
					No hay noticias disponibles.
				</Title>
			</Wrapper>
		) :
		loadingNews ? <Spinner/> : (
			<>
			<Wrapper
				ref={nowShowRef}
				alignItems="left"
				display="flex"
				flexDirection="column"
				margin="90px 10vw 10px 10vw"
			>
				<h1
					style={{
						color: "#fff",
					}}
				>
					CardiOne News
				</h1>

				<Grid
					style={{marginBottom: "120px"}}
					columns={`repeat(${mobileView ? 1 : 3}, calc(80vw/3))`}
					gap="1em 1em"
				>
					{_.orderBy(news, (s) => s.title, ["asc"]).map((news, idx) => (
						<div key={idx}>
							<Card
								width={mobileView ? "calc(80vw)" : "calc(80vw/3)"}
								styles={{backgroundColor: "#fff"}}
								coverImg={<img src={news.posterUrl} alt="Poster"/>}
								title={news.tittle}
								description={`por ${news.body}`}
								actions={[<DownLoadPdf url={news.resources[0].resourceUrl} type={news.resources[0].resourceType.key}/>]}
							/>
						</div>
					))}
				</Grid>
			</Wrapper>
	     {canObserveVisor && <div ref={visorRef} />}
	    </>
		);
};
const mapStateToProps = (state) => ({
	currentUser: selectCurrentUser(state),
	news: selectNewsCardiOneNews(state),
	organizationId: selectedOrganizationId(state),
	loadingNews: selectLoadingNews(state),
	totalNewsItem: selectTotalNewsItem(state)
});
const mapDispatchToProps = (dispatch) => ({
	getNews: (token, organizationData) =>
		dispatch(getAllNews(token, organizationData)),
});
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(News));
