import { newsStype } from "./new-cardi-one-types";
import UserActionTypes from "Redux/User/user-types";
import { fetchAllCardiOneNews } from "Services/NewsService";

import { CompanyService } from "Services/CompanyService";
import { orgnizacionUnseleted } from "Redux/DynamicConfigCompany/dcc-action";
import { dccLogout } from "Redux/DynamicConfigCompany/dcc-action";
import { clearOrganization } from "Redux/Organization/organizarion-action";
import { logout } from "Redux/User/user-actions";

export const getAllNews = (token, {organizationId, lastRowNews}) => {
  return (dispatch) => {
    if (!token) {
      dispatch({
        type: UserActionTypes.SHOW_MESSAGE,
        payload: {
          body: "Se necesita un token válido para obtener las noticias",
        },
      });
      return;
    }
    dispatch({
      type: newsStype.LOADING_NEWS_DATA,
      payload: true
    });

    fetchAllCardiOneNews(token, {organizationId, lastRowNews}).then( async (data) => {

      if (data === 401) {
        const companyData = await CompanyService.getCompanyData(
          window.location.host
        ).then((data) => data);
        dispatch(orgnizacionUnseleted(companyData.company));
        dispatch(clearOrganization());
        dispatch(dccLogout());
        dispatch(logout(true));
      } else {
        dispatch({
          type: newsStype.TOTAL_NEWS_ITEMS,
          payload: data.news.count
        })
        dispatch({
          type: newsStype.GET_LAST_CARDI_ONE_NEWS,
          payload: data.news.rows.length === 0 ? null : data.news.rows,
        });
      }
    });
  };
};

export const clearNews = () => {
  return {
    type: newsStype.CLEAR_NEWS,
  };
};
