import { createSelector } from "reselect";

const selectSpeaker = (state) => state.speaker;

export const selectIsShowVideos = createSelector(
  [selectSpeaker],
  (speaker) => speaker.isShowVideos
);

export const selectSpeakers = createSelector(
  [selectSpeaker],
  (speaker) => speaker.speakers
);

export const selectloadingSpeaker = createSelector(
	[selectSpeaker],
	(speaker) => speaker.loadingNewData
);

export const selectTotalSpeakerItem = createSelector(
	[selectSpeaker],
	(speaker) => speaker.totalSpeakerItem
);

