//Services
import FetchPlus from "Services/FetchPlus";
//Config
import { API_URL } from "Config/config";

const userVideoMessagesBase = `${API_URL}user-video-messages`;
const userVideoReactionsBase = `${API_URL}user-video-reactions`;
const organizationBase = `${API_URL}organizations`;

//Video
export async function fetchVideoData(token, resourceId, organizationId) {
  const headers = {
    Authorization: token,
  };
  return FetchPlus(
    `${organizationBase}/${organizationId}/resources/${resourceId}`,
    {
      headers,
    }
  )
    .then((video) => video || {})
    .catch((err) => {
      console.log(err);
    });
}

//Messages
export async function fetchUserVideoMessages(
  token,
  videoId,
  { messagesLimit = 10, messagesLastRow = 0 } = {}
) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return FetchPlus(
    `${userVideoMessagesBase}/${videoId}/${messagesLimit}/${messagesLastRow}`,
    {
      headers,
    }
  )
    .then((messages) => messages || [])
    .catch((err) => console.log(err));
}

export async function createMessageInDatabase(
  token,
  { organizationId, resourceId, messageBody, currentVideoSecond = 0, isQuestion }
) {
  const options = {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      messageBody,
      currentVideoSecond,
      isQuestion,
    }),
  };
  return FetchPlus(
    `${organizationBase}/${organizationId}/resources/${resourceId}/comment`,
    options
  )
    .then((response) => response)
    .catch((err) => console.log(err));
}

//Reactions
export async function fetchUserVideoReactions(token, videoId) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const reactions = await FetchPlus(`${userVideoReactionsBase}/${videoId}`, {
    headers,
  });

  if (!reactions) return {};

  return Array.isArray(reactions)
    ? reactions.reduce((acc, reaction) => {
        acc[reaction.emoji] = reaction.count;
        return acc;
      }, {})
    : reactions;
}

export async function createReactionInDatabase(
  token,
  resourceId,
  body,
  reactionId,
  currentVideoSecond = 0,
  organizationId
) {
  const options = {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      messageBody: body,
      reactionId,
      currentVideoSecond,
    }),
  };

  return FetchPlus(
    `${organizationBase}/${organizationId}/resources/${resourceId}/reaction`,
    options
  )
    .then((res) => res)
    .catch((err) => console.log(err));
}

//Polls
export async function createPollInDatabase(
  token,
  isMultiAnswer,
  question,
  roomId,
  optionValue,
  organizationId,
  timer
) {
  const options = {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      question,
      isMultiAnswer,
      options: optionValue,
    }),
  };

  return FetchPlus(
    `${organizationBase}/${organizationId}/resources/${roomId}/polls`,
    options
  )
    .then((res) => res.poll)
    .catch((err) => console.log(err));
}

//close poll
export async function closePollAnswerDatabase(
  token,
  { organizationId, pollId, currentVideoId }
) {
  const options = {
    method: "PUT",
    headers: {
      Authorization: token,
    },
  };

  return FetchPlus(
    `${organizationBase}/${organizationId}/resources/${currentVideoId}/polls/${pollId}`,
    options
  )
    .then((res) => res)
    .catch((err) => console.log(err));
}

//sent poll answer
export async function setPollAnswerDataBase(
  token,
  { organizationId, currentVideoId, pollId, PollOptionId }
) {
  const options = {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      pollOptionId: PollOptionId,
    }),
  };
  return FetchPlus(
    `${organizationBase}/${organizationId}/resources/${currentVideoId}/polls/${pollId}/answer`,
    options
  )
    .then((res) => res)
    .catch((err) => console.log(err));
}
