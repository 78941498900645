//Libs
import React from "react";
import dotenv from "dotenv";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { store, persistor } from "./Redux/store.js";
import { PersistGate } from "redux-persist/integration/react";
//Components
import App from "./App";
//dynamic config
import DynamicCompanyConfig from "Components/DynamicCompanyConfig/DynamicCompanyConfig";
//Styles
import "antd/dist/antd.css";
import "animate.css/animate.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "moment/locale/es";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "Themes";

dotenv.config();
function initSentry() {
  //Se utiliza el toolkit de Sentry directamente, si funciona se deja este, de lo contrario se puede usar Raven
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

//Production ENV?
if (process.env.NODE_ENV === "production") {
  initSentry();
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <DynamicCompanyConfig>
        {({ organizacion, modules, themeCompany,subModulos }) => (
          <Router>
            <ThemeProvider theme={themeCompany}>
              <GlobalStyle />
              <App modules={modules} organizacion={organizacion} subModulos={subModulos} />
            </ThemeProvider>
          </Router>
        )}
      </DynamicCompanyConfig>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
