import moment from "moment";
// eslint-disable-next-line
import momentTimezone from "moment-timezone";

export function getHumanDate(
  evaluatedDate,
  { unit = "milliseconds", locale = "es", withSuffix = true } = {}
) {
  const timezone =
    moment.tz.guess() ||
    Intl.DateTimeFormat().resolvedOptions().timeZone ||
    "America/Costa_Rica";
  const now = moment();
  evaluatedDate = moment(evaluatedDate).tz(timezone);

  //Usage:
  //evaluatedDate.diff(with respect to...)

  return moment
    .duration(evaluatedDate.diff(now, unit))
    .locale(locale)
    .humanize(withSuffix);
}
