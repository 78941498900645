import React from "react";
import ReactApexChart from "react-apexcharts";

const ChartResults = ({ pollAnswers = {} }) => {
  const chart = () => {
    const categoriesChart = Object.keys(pollAnswers);
    const dataChart = Object.values(pollAnswers);

    return {
      series: [
        {
          name: "Votos",
          data: dataChart,
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: true,
        },
        xaxis: {
          categories: categoriesChart,
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
      },
    };
  };
  return (
    <div id="chart">
      <ReactApexChart
        options={chart().options}
        series={chart().series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default ChartResults;
