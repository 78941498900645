import styled from "styled-components";
import { FrownOutlined } from "@ant-design/icons";

const StyledFloatFrownButton = styled(FrownOutlined)`
  padding: ${(props) => props.padding || props.theme.floatFrownButton.padding};
  color: ${(props) => props.color || props.theme.floatFrownButton.color};
  position: ${(props) =>
    props.position || props.theme.floatFrownButton.position};
  top: ${(props) => props.top || props.theme.floatFrownButton.top};
  bottom: ${(props) => props.bottom || props.theme.floatFrownButton.bottom};
  left: ${(props) => props.left || props.theme.floatFrownButton.left};
  right: ${(props) => props.right || props.theme.floatFrownButton.right};
  z-index: ${(props) => props.zIndex || props.theme.floatFrownButton.zIndex};
  font-size: ${(props) =>
    props.fontSize || props.theme.floatFrownButton.fontSize};
  transform: ${(props) =>
    props.transform || props.theme.floatFrownButton.transform};
  background-color: ${(props) =>
    props.backgroundColor || props.theme.floatFrownButton.backgroundColor};
  border-radius: ${(props) =>
    props.borderRadius || props.theme.floatFrownButton.borderRadius};
  box-shadow: ${(props) =>
    props.boxShadow || props.theme.floatFrownButton.boxShadow};
  transform: ${(props) =>
    props.transform || props.theme.floatFrownButton.transform};
  transition: ${(props) =>
    props.transition || props.theme.floatFrownButton.transition};
  cursor: ${(props) => props.cursor || props.theme.floatFrownButton.cursor};
  &:hover {
    color: ${(props) =>
      props.hoverColor || props.theme.floatFrownButton.hover.color};
  }
`;

export default StyledFloatFrownButton;
