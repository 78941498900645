import React, { useState } from "react";
import Switch from "./Switch/Switch";
import TextField from "@material-ui/core/TextField";
import { MdPlaylistAdd } from "react-icons/md";
//Service
import MixPanel from "Services/MixPanelService";
import {
  CardPoll,
  DeleteAnswerOption,
  MainContainerPoll,
  PlusCreatePoll,
  PollSubtitle,
  PollTitle,
  SwitchContainer,
  AddQuestionButton,
} from "Components/SuperChatStyled";
import { default as userPermission } from "Utils/Permissions"

const PollForm = ({ onCreatePoll, roomId, polls, permissions, close }) => {
  const [valueSwitch, setValueSwitch] = useState(false);
  const [option, setOption] = useState("");
  const [answerOption, setAnswerOption] = useState([]);
  const [errorAlert, setAlert] = useState(false);
  const [pollContent, setPollContent] = useState("");

  const addAnswerOptions = () => {
    //Input value Validation
    if (option.trim() === "") {
      const alert = {
        msg: "Por favor, digite una opción.",
        classes: "alert alert-danger text-center text-uppercase p3",
      };
      setAlert(alert);
      setTimeout(() => setAlert(false), 1500);
      return;
    }
    if (answerOption.findIndex((opt) => opt.value === option) !== -1) {
      const alert = {
        msg: "La opción ingresada ya existe",
        classes: "alert alert-danger text-center text-uppercase p3",
      };
      setAlert(alert);
      setTimeout(() => setAlert(false), 1500);
      return;
    }
    setAlert(false);
    setAnswerOption([
      ...answerOption,
      {
        value: option,
      },
    ]);
    setOption("");
  };

  //Create and put poll in redux state
  const createPoll = () => {
    if (pollContent.trim() === "" || answerOption.length < 2) {
      const alert = {
        msg: "Todos los campos son obligatorios, debe digitar una pregunta y al menos dos opciones.",
        classes: "alert alert-danger text-center text-uppercase p3",
      };
      setAlert(alert);
      return;
    }

    if (polls.length >= 1) {
      const alert = {
        msg: "Ya se encuentra una encuesta activa.",
        classes: "alert alert-danger text-center text-uppercase p3",
      };
      setAlert(alert);
      return;
    }

    setAlert(false);
    //Aca se envia los datos del poll al service
    onCreatePoll({
      question: pollContent,
      roomId: roomId,
      multiAnswer: valueSwitch,
      optionValue: answerOption,
    });
  };

  //delete answer option in form
  const deleteAnswerOption = (value) => {
    MixPanel.track(MixPanel.TYPES.DELETE_ANSWER_OPTION_POLL);
    setAnswerOption(answerOption.filter((option) => option.value !== value));
  };

  const arrayAnswerOptions = answerOption;

  return (
    <MainContainerPoll className="card">
      <form>
        <CardPoll className="card">
          <PollTitle>Pregunta</PollTitle>
          <TextField
            id="filled-basic"
            label="Escribe tu pregunta."
            variant="filled"
            color="primary"
            className="bg-white m-3"
            value={pollContent}
            onChange={(e) => {
              setPollContent(e.target.value);
            }}
          />
        </CardPoll>

        <CardPoll className="card">
          <PollTitle className="col-sm">Respuestas</PollTitle>
          <div
            style={{ background: "transparent", display: "flex" }}
            className="card"
          >
            <TextField
              // style={{paddingRight: "50px"}}
              id="filled-basic"
              label="Escriba y agregue una opción"
              variant="filled"
              color="primary"
              className="bg-white m-3"
              value={option}
              onChange={(e) => setOption(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  MixPanel.track(
                    MixPanel.TYPES.USER_SELECT_OPCION_QUESTION_POLL
                  );
                  addAnswerOptions();
                }
              }}
            />
            <AddQuestionButton
              onClick={(e) => {
                e.preventDefault();
                if (option.trim() !== "") {
                  MixPanel.track(MixPanel.TYPES.USER_SELECT_OPCION_QUESTION_POLL);
                  addAnswerOptions();
                }
              }}
              className="btn"
            >
              <MdPlaylistAdd />
            </AddQuestionButton>
          </div>
          {arrayAnswerOptions.map((option, idx) => (
            <div key={idx} className="m-3">
              <span>
                {idx + 1}) {option.value}
              </span>
              <DeleteAnswerOption
                onClick={() => deleteAnswerOption(option.value)}
              />
            </div>
          ))}
        </CardPoll>

        <CardPoll className="card">
          <PollTitle>Opciones Adicionales</PollTitle>
          <PollSubtitle className="col-sm">Respuesta Múltiple</PollSubtitle>
          <SwitchContainer className="col-sm">
            <Switch
              isOn={valueSwitch}
              onColor="#00B2F3"
              handleToggle={() => {
                setValueSwitch(!valueSwitch);
                MixPanel.track(MixPanel.TYPES.CREATE_MULTIPLE_OPCION_POLL);
              }}
            />
          </SwitchContainer>
        </CardPoll>

        <CardPoll className="card">
          {errorAlert ? (
            <p className={errorAlert.classes}>{errorAlert.msg}</p>
          ) : null}
          <button
            style={{ backgroundColor: "#00B2F3", border: "none" }}
            type="button"
            onClick={() => {
              createPoll();
              MixPanel.track(MixPanel.TYPES.CREATE_POLL);
            }}
            className="w-100 btn"
            disabled={permissions.includes(userPermission.USER_CAN_CREATE) ? false : true}
          >
            <PlusCreatePoll /> Crear
          </button>
        </CardPoll>
      </form>
    </MainContainerPoll>
  );
};

export default PollForm;
