import StoryActionTypes from "./stories-types";
import UserActionTypes from "Redux/User/user-types";
import {
  fetchOptimizedStories,
  paranoiaDeleteStoryById,
} from "Services/StoryService";

export function onDeleteStory(token, storyId, userId, organizationId) {
  return (dispatch) => {
    if (!token || !storyId || !userId || !organizationId) {
      return false;
    }
    return paranoiaDeleteStoryById(token, storyId, organizationId, userId)
      .then((res) => {
        if (!!res) {
          dispatch({
            type: StoryActionTypes.SET_STORY_DATA,
            payload: { isFetching: true },
          });

          dispatch(getOptimizedStories(token, userId, organizationId));
        }
      })
      .catch((err) => console.log(err));
  };
}

export function getOptimizedStories(token, userId, organizationId) {
  return (dispatch) => {
    if (!token || !userId || !organizationId) {
      dispatch({
        type: UserActionTypes.SHOW_MESSAGE,
        payload: {
          body: "Se necesita un token válido para obtener las historias",
        },
      });
      return;
    }
    dispatch({
      type: StoryActionTypes.SET_STORY_DATA,
      payload: { isFetching: true },
    });

    fetchOptimizedStories(token, organizationId)
      .then((data) => {
        dispatch({
          type: StoryActionTypes.SET_STORIES_LIST,
          payload: {
            currentUserId: userId,
            stories: data.stories.rows,
            storyTotalItem: data.pagination.totalItems,
            onDeleteStory: (storyId) =>
              dispatch(onDeleteStory(token, storyId, userId, organizationId)),
          },
        });
      })
      .catch((err) => console.log(err));
  };
}

export const setStoryData = (data) => ({
  type: StoryActionTypes.SET_STORY_DATA,
  payload: data,
});

export const resetStories = () => ({
  type: StoryActionTypes.RESET,
});

export const storyIsOpen = (isOpen) => ({
  type: StoryActionTypes.STORY_IS_OPEN,
  payload: isOpen,
});
