//Services
import FetchPlus from "Services/FetchPlus";
//Config
import { API_URL } from "Config/config";
const speakerBase = `${API_URL}organizations`;

export default class SpeakerService {
  static fetchSpeakers(
    token,
    { organizationId, usersLimit = 10, usersLastRow = 0 }
  ) {
    return FetchPlus(
      `${speakerBase}/${organizationId}/speakers?page=${usersLastRow}&size=${usersLimit}`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((response) => response)
      .catch((error) => {
        if (error === 401) {
          return error;
        }
        console.log(error)
      });
  }

  static searchInSpeakers(
    token,
    searchInputValue,
    {  usersLimit = 10, organizationId, speakerLastRow = 0 }
  ) {
    return FetchPlus(
      `${speakerBase}/${organizationId}/speakers?page=0&size=${usersLimit}&search=${searchInputValue}`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((response) => response.speakers.rows)
      .catch((error) => console.log(error));
  }

  static getResourceBySpeakerId(
    token,
    speakerId,
    { resourcesLimit = 10, resourcesLastRow = 0, organizationId } = {}
  ) {
    return FetchPlus(
      `${speakerBase}/${organizationId}/speakers/${speakerId}/resources?page=${resourcesLastRow}&size=${resourcesLimit}`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((response) => response.resources)
      .catch((error) => console.log(error));
  }
}
