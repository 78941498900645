//Libs
import React from "react";
import styled, { css } from "styled-components";
import { withRouter } from "react-router";
//Actions
import { logout, setNavMenuOpen } from "Redux/User/user-actions";
import { selectIsOpenStory } from "Redux/Stories/stories-selectors"
import { connect } from "react-redux";
import { compose } from "redux";
//Components
// import { faBars } from "@fortawesome/free-solid-svg-icons";
//library
import {
  Wrapper,
  Link,
  // ProfilePhoto,
  Logo,
  // ResourceUpload,
  // SwitchProfile,
} from "Components";
import Label from "./Label";
// import MenuButton from "./MenuButton";
//DropDown
import { Dropdown } from "antd";
//React Icon Component
// import { AiOutlineDown } from "react-icons/ai";
import { SubMenuItem } from "./SubMenuItem";

const StyledDesktopHeader = styled.div`
  width: 100%;
  height: 5vw; //100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
  padding: 0 2.1vw; //20px;
  ${(props) =>
    window.scrollY === 0
      ? css`
          background-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.7) 10%,
            rgba(0, 0, 0, 0)
          );
        `
      : !props.scrollUp
        ? css`
          background-color: #000;
        `
        : !props.scrollUp &&
        css`
          background-color: #000;
        `};
  transition: background-color 0.7s, opacity 0.3s;
`;

const StyledMobileHeader = styled.div`
  width: 100%;
  height: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 40;
  padding: 0 2.1vw;
  background-color: #000;
`;

function DesktopHeader({
  currentUser,
  modules,
  roles,
  opaqueNavbar,
  scrollUp,
  logout,
  currentRoute,
  mobileView,
}) {
  return (
    <StyledDesktopHeader opaqueNavbar={opaqueNavbar} scrollUp={scrollUp}>
      <Wrapper>
        <Logo />
        {modules.map(
          (
            { path, name, isHeaderBarHidden, isInDesktopNav, subModules },
            index
          ) =>
            !isHeaderBarHidden &&
            isInDesktopNav &&
            (path ? (
              <Dropdown
                key={index}
                overlay={<SubMenuItem SubMenuItem={subModules} />}
              >
                <Link
                  margin="0 1em 0 2em"
                  to={path}
                  color={currentRoute === path ? "#00B2F3" : null}
                >
                  {name}
                </Link>
              </Dropdown>
            ) : (
              <Dropdown
                key={index}
                overlay={<SubMenuItem SubMenuItem={subModules} />}
              >
                <Label
                  margin="0 1em 0 2em"
                  style={{ cursor: "pointer" }}
                  colorhover="#00B2F3"
                >
                  {name}
                </Label>
              </Dropdown>
            ))
        )}
      </Wrapper>
      {/* <Wrapper display="flex">
        <ResourceUpload id="profilePhoto">
          <ProfilePhoto
            profilePhotoSrc={currentUser?.profileImageUser}
            containerStyle={{
              right: "5px",
              bottom: "2px",
            }}
            cameraStyle={{
              left: "30px",
              bottom: "0",
              fontSize: "1rem",
            }}
          />
        </ResourceUpload>
        <Dropdown
          overlay={
            <SwitchProfile
              roles={roles}
              mobileView={mobileView}
              logout={logout}
            />
          }
          placement="bottomRight"
          arrow
        >
          <Wrapper display="flex" cursor="pointer" margin="0.8em">
            <Label margin="0 5px 0 0">Hola, {currentUser?.name}</Label>
            <AiOutlineDown style={{ color: "rgba(255,255,255,.8)" }} />
          </Wrapper>
        </Dropdown>
      </Wrapper> */}
    </StyledDesktopHeader>
  );
}

function MobileHeader({ setNavMenuOpen, scrollUp }) {
  return (
    <StyledMobileHeader scrollUp={scrollUp}>
      <Logo />
    </StyledMobileHeader>
  );
}

function Header({
  currentRoute,
  modules,
  roles,
  mobileView,
  opaqueNavbar,
  scrollUp,
  currentUser,
  logout,
  setNavMenuOpen,
  isOpenStory
}) {
  return mobileView ? (
    !isOpenStory && <MobileHeader setNavMenuOpen={setNavMenuOpen} scrollUp={scrollUp} />
  ) : (
    <DesktopHeader
      mobileView={mobileView}
      currentUser={currentUser}
      modules={modules}
      roles={roles}
      opaqueNavbar={opaqueNavbar}
      scrollUp={scrollUp}
      logout={logout}
      currentRoute={currentRoute}
    />
  );
}

const mapStateToProps = (state) => ({
  isOpenStory: selectIsOpenStory(state)
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  setNavMenuOpen: (open) => dispatch(setNavMenuOpen(open)),
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Header);
