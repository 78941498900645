//Libs
import React from "react";
import { Link, Redirect } from "react-router-dom";
//Components
import Spinner from "Components/Spinner/Spinner";
import FormInput from "Components/FormInput/FormInput";
import CustomButton from "Components/CustomButton/CustomButton";
import Background from "Assets/Background.jpg";
//Services
import {
  sendEmailForgotPassword,
  sendCodeConfirmationToRecoveryPassword,
} from "Services/UserService";
import MixPanel from "Services/MixPanelService";
//Config File
import { ROUTES } from "Config/config";
//Styles
import "./ForgotPassword.css";
//redux
import { connect } from "react-redux";
import { selectCompany } from "Redux/DynamicConfigCompany/dcc-select";
import { setRecoveryPasswordToken } from "Redux/User/user-actions";


function SendEmail({ email, sent, handleChange, handleSubmit }) {
  return (
    <>
      <h1 className="forgot__title">Recuperar Contraseña</h1>
      <form action="POST" onSubmit={handleSubmit}>
        <FormInput
          name="email"
          type="email"
          maxLength={150}
          value={email}
          onChange={handleChange}
          label="Email"
          required
        />

        <div className="forgot__btn-container">
          <div className="forgot__btn">
            <CustomButton type="submit">Recuperar</CustomButton>
          </div>
        </div>
      </form>

      {sent === false && (
        <div className="forgot__error-container">
          <span className="forgot__error">Correo no registrado</span>
        </div>
      )}
    </>
  );
}

function EnterCode({ code, codeValidated, handleChange, handleSubmit }) {
  return (
    <>
      <h1 className="forgot__title">Código de Confirmación</h1>

      <span className="forgot__reviewcode">
        Por favor revisa el código que ha sido enviado a su correo. Si en 2
        minutos no ha recibido el correo, vuelva a intentarlo
      </span>

      <form action="POST" onSubmit={handleSubmit}>
        <FormInput
          name="code"
          type="text"
          maxLength={6}
          value={code}
          onChange={handleChange}
          label="Ingrese el código"
          required
        />

        <div className="forgot__btn-container">
          <div className="forgot__btn">
            <CustomButton type="submit">Confirmar </CustomButton>
          </div>
        </div>
      </form>

      {codeValidated === false && (
        <div className="forgot__error-container">
          <span className="forgot__error">Código inválido</span>
        </div>
      )}
    </>
  );
}

function GoToSignin() {
  return (
    <div className="forgot__option">
      {/* <span className="forgot__option--newuser">¿Volver al inicio?</span> */}
      <Link to={ROUTES.SIGNIN} className="forgot__option--link">
        ¿Volver al inicio?
      </Link>
    </div>
  );
}

class ForgotPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      //Email Recovery
      email: "",
      sent: null,
      //Code Confirmation
      code: "",
      codeValidated: null,
    };
  }

  handleSubmitEmail = async (event) => {
    event.preventDefault();
    try {
      this.setState({ loading: true });
      const sent = await sendEmailForgotPassword(
        this.state.email,
        this.props.company.id
      );
      //MixPanel successful reset password
      MixPanel.track(MixPanel.TYPES.SUCCESSFUL_RESET_PASSWORD);
      this.setState({ sent, loading: false });
    } catch (err) {
      console.error(err);
    }
  };

  handleSubmitCode = async (event) => {
    event.preventDefault();

    try {
      const { code } = this.state;
      this.setState({ loading: true });
      const codeValidated = await sendCodeConfirmationToRecoveryPassword(
        this.props.company.id,
        code
      );
      this.props.setTokenPasswordRecovery(codeValidated.token)
      this.setState({ codeValidated: codeValidated.status, loading: false });
    } catch (err) {
      console.error(err);
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  render() {
    const { loading, email, code, sent, codeValidated } = this.state;
    const { id } = this.props.company;

    if (codeValidated)
      return <Redirect to={`${ROUTES.RESET_PASSWORD}/${id}/${email}`} />;

    return (
      <div className="forgot">
        {loading && <Spinner />}
        <div
          className="forgot__bg"
          style={{
            backgroundImage: `url(${Background})`,
            backgroundSize: "cover",
          }}
        />
        <div className="forgot__container">
          <div className="forgot__shadow">
            {sent ? (
              <EnterCode
                code={code}
                codeValidated={codeValidated}
                handleChange={this.handleChange}
                handleSubmit={this.handleSubmitCode}
              />
            ) : (
              <SendEmail
                email={email}
                sent={sent}
                handleChange={this.handleChange}
                handleSubmit={this.handleSubmitEmail}
              />
            )}
            <GoToSignin />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  company: selectCompany(state),
});
const mapDispatchToProps = (dispatch) => ({
  setTokenPasswordRecovery: (token) => dispatch(setRecoveryPasswordToken(token)),
});
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ForgotPassword));
