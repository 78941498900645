import _ from "lodash";
import StoryActionTypes from "./stories-types";
import { CLOUD_STORAGE_URL, APPLICATION_NAME } from "Config/config";
import { getHumanDate } from "Utils/Datetools";
import StorySeeMore from "../../Components/Stories/StorySeeMore";

const INITIAL_STATE = {
  isFetching: false,
  stories: [],
  isShowStories: false,
  storyIndex: undefined,
  //Upload Story
  storyUploading: false,
  storyUploaded: null, //Values: [null, false, true]
  storyTotalItem: null,
  storyIsOpen: false
};

const storiesReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case StoryActionTypes.SET_STORIES_LIST: {
      const { currentUserId, stories, onDeleteStory } = payload;
      let newPayload = _.map(_.groupBy(stories, "userId"), (sg) => {
        return _.orderBy(sg, "createdAt", "asc");
      });

      newPayload.sort((a, b) => {
        var dateA = new Date(a[a.length - 1].createdAt);
        var dateB = new Date(b[b.length - 1].createdAt);
        return dateB - dateA;
      });
      const newStories = _.map(newPayload, (s) => {
        return s.map((ss) => {
          return {
            storyId: ss.id,
            sponsorId: ss.userId,
            url: ss.url,
            type: ss.isVideo ? "video" : "image",
            seeMore: true,
            seeMoreCollapsed: StorySeeMore.bind(
              null,
              ss.seeMoreUrl,
              currentUserId === ss.userId,
              ss.id,
              onDeleteStory
            ),
            //aca se hardcodea
            header: {
              heading: ss.user.name,
              subheading: getHumanDate(ss.createdAt),
              profileImage: (ss.user && Array.isArray(ss.user.userCompany) && ss.user.userCompany[0] && ss.user.userCompany[0].profilePhotoUrl) || CLOUD_STORAGE_URL.concat(
                `/${process.env.NODE_ENV
                }/${APPLICATION_NAME}/sponsors/avatars/default-avatar.png`
              ),
            },
          };
        });
      });

      return {
        ...state,
        stories: newStories,
        isFetching: false,
        storyTotalItem: payload.storyTotalItem
      };
    }
    case StoryActionTypes.SET_STORY_DATA: {
      const { isFetching, isShowStories, storyIndex } = payload;
      return {
        ...state,
        isFetching,
        isShowStories,
        storyIndex,
      };
    }
    case StoryActionTypes.RESET:
      return INITIAL_STATE;
    case StoryActionTypes.STORY_IS_OPEN:
      return {
        ...state,
        storyIsOpen: payload
      }

    default:
      return state;
  }
};

export default storiesReducer;
