import { createSelector } from "reselect";

const selectCategories = (state) => state.categories;

export const selectCategoriesData = createSelector(
	[selectCategories],
	(categories) => categories.categories
);

export const selectTotalCategoryCount = createSelector(
	[selectCategories],
	(categories) => categories.totalCategoryCount
);

export const selectIsFetchingCategoriesData = createSelector(
	[selectCategories],
	(categories) => categories.isFetching
);

export const selectIsAddMoreVideosFetching = createSelector(
	[selectCategories],
	(categories) => categories.isMoreAdditionalVideosFetching
);

export const seletCurrentRecommendRecourcePageCount = createSelector(
	[selectCategories],
	(categories) => {
		return {
			recommendResourceCurrentPage: categories.currentRecommendRecourcePage,
			totalItemRecommendResource: categories.totalResourceRecItem
		}
	}
);

export const selectResourceTotalCount = createSelector(
	[selectCategories],
	(categories) => categories.totalResourceItem
);

export const selectMoreViewCurrentPageAndTotalCount = createSelector(
	[selectCategories],
	(categories) => {
		return {
			moreViewCurrentPage: categories.currentMoreVieRecourcePage,
			totalResourceMoreVieItem: categories.totalResourceMoreVieItem
		}
	}
);