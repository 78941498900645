import SpeakerActionTypes from "./speaker-types";
import UserActionTypes from "Redux/User/user-types";
import SpeakerService from "Services/SpeakerService";

import { CompanyService } from "Services/CompanyService";
import { orgnizacionUnseleted } from "Redux/DynamicConfigCompany/dcc-action";
import { dccLogout } from "Redux/DynamicConfigCompany/dcc-action";
import { clearOrganization } from "Redux/Organization/organizarion-action";
import { logout } from "Redux/User/user-actions";

export const setShowVideos = (payload) => ({
  type: SpeakerActionTypes.SET_SHOW_VIDEOS,
  payload,
});

export const setSpeakers = (token, {organizationId, speakerLastRow}) => {
  return (dispatch) => {
    if (!token) {
      dispatch({
        type: UserActionTypes.SHOW_MESSAGE,
        payload: {
          body: "Se necesita un token válido para obtener las noticias",
        },
      });
      return;
    }
    dispatch({
      type: SpeakerActionTypes.LOADING_SPEAKER,
      payload: true
    });

    SpeakerService.fetchSpeakers(token, {organizationId, speakerLastRow}).then(async (data) => {
      if (data === 401) {
        const companyData = await CompanyService.getCompanyData(
          window.location.host
        ).then((data) => data);
        dispatch(orgnizacionUnseleted(companyData.company));
        dispatch(clearOrganization());
        dispatch(dccLogout());
        dispatch(logout(true));
      } else {
        dispatch({
          type: SpeakerActionTypes.TOTAL_SPEAKER_ITEM,
          payload: data.speakers.count
        })

        dispatch({
          type: SpeakerActionTypes.SET_SPEAKERS,
          payload: data.speakers.rows.length === 0 ? null : data.speakers.rows,
        });
      }
    });
  };
};

export const clearSpeaker = () => {
  return {
    type: SpeakerActionTypes.CLEAR_SPEAKER_DATE,
  };
};