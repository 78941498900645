import AssistantActionTypes from "./assistant-types";

const INITIAL_STATE = {
  control: {
    isFetching: false,
    data: [],
  },
  createAppointment: {
    isOpen: false,
    type: undefined, //['appointment', 'message']
  },
  loadingAssitant: false,
  totalCountContact: null
};

const assistantReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case AssistantActionTypes.SET_CREATE_APPOINTMENT:
      return {
        ...state,
        createAppointment: {
          ...state.createAppointment,
          ...payload,
        },
      };
    case AssistantActionTypes.SAVE_CONTACT_DATA:
      const filteredContact = Array.isArray(payload)
        ? payload.filter(
            (user) =>
              state.control.data.findIndex((usr) => user.id === usr.id) === -1
          )
        : null;

      return {
        ...state,
        control: {
          ...state.control,
          data: filteredContact ? [...state.control.data, ...filteredContact] : null ,
        },
        loadingAssitant: false
      };
    case AssistantActionTypes.LOADING_ASSISTANT:
      return {
        ...state,
        loadingAssitant: payload
      }
    case AssistantActionTypes.CLEAR_ASSISTANT:
      return {
        ...state,
        control: {
          isFetching: false,
          data: [],
        },
        createAppointment: {
          isOpen: false,
          type: undefined,
        },
        loadingAssitant: false
      };
    case AssistantActionTypes.ADD_TOTAL_CONTACT_ITEM:
      return {
        ...state,
        totalCountContact: payload
      }
    default:
      return state;
  }
};

export default assistantReducer;
