import React, { useState } from "react";
//Componentes
import { Wrapper, Grid, Image } from "Components/index";
import Spinner from "Components/Spinner/Spinner";

//codigo hardCodeado temporal
import company from "./OrganizacionHC";
//library
import styled from "styled-components";
//config
import { ROUTES } from "Config/config";
//Image simulated
import LogoSimulator from "Assets/POLPO_SUMMIT_LOGI.png";
//redux
import { connect } from "react-redux";
import { selectOrganizacion } from "Redux/DynamicConfigCompany/dcc-select";
import { setModuleOrganization } from "Redux/DynamicConfigCompany/dcc-action";
import { setOrganizationSeleted } from "Redux/Organization/organizarion-action";
import { selectCurrentUser } from "Redux/User/user-selectors";
//react router dom
import { useHistory } from "react-router";

/*Funcionalidad de esta vista:
    1) Esta vista se mostrara si la company tiene mas de una organizacion.
    2) Se mostrara las organizacion estilos perfiles para que el usuario pueda elegir la que desee.
    3) Los perfiles se mostrara segun al perfil que pertenezca el usuario.
    4) El dise;o tiene que manejarse de manera dinamica(Ver como el endPoint enviara estos datos).
    5) Consumir el endpoint que traiga las organizaciones de la company
    */

const WrapperImage = styled(Wrapper)`
  background-image: ${(props) => props.backgroundImage};
  background-size: cover;
  background-position: center;
  flex-wrap: wrap;
`;

const Organization = ({
  Organization,
  setOrganizationModule,
  setOrganizationSeleted,
  currentUser,
}) => {
  /*
    1) El company tiene que decomponerse y extrar los style dinamico que se utilizaria en
    el style dinamico.
    2)Image logo tiene que ser dinamico
    3)Image de la organizacion tiene que ser dinamico
    4)Nombre de la organizacion tiene que ser dinamico 
    5) background Image tiene que ser dinamico.
*/
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  //Aca se realizara toda la logica de seleccion de organizacion
  const handledSelectOrganizacion = (organization) => {

    const rolesModules =
      Array.isArray(organization.availableModules) &&
      organization.availableModules.map((orgnizationModule) => orgnizationModule.module);

    if (rolesModules !== 0) {
      setLoading(true);
      setOrganizationModule(organization);
      setOrganizationSeleted(organization);
      setLoading(false);

      history.push(ROUTES.HOME);
    } else {
      history.push(ROUTES.ORGANIZATION);
    }
  };

  return (
    <>
      {loading && <Spinner />}
      <WrapperImage
        style={{
          backgroundImage: `url(${company.cssStyle.backgroundImage})`,
        }}
        display="flex"
        height="100vh"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          display="grid"
          background={`${company.cssStyle.backgroundColor}`}
          width="983px"
          minHeight="559px"
          rows="216px auto"
        >
          <Grid display="grid" rows="141px 65px;" gap="10px">
            <Image width={"188px"} margin={"14px"} src={LogoSimulator} />
            <p
              style={{
                color: company.cssStyle.TextColorCompany,
                fontSize: company.cssStyle.fontSizeCompanyTittle,
                fontWeight: "700",
                justifySelf: "center",
              }}
            >
              ¿Qué organización desea ver?
            </p>
          </Grid>
          <WrapperImage display="flex" justifyContent="center"margin="40px 10px 25px 40px">
            {Array.isArray(Organization) &&
              currentUser.organizations.map((organization, index) => (
                <button
                  key={index}
                  style={{
                    backgroundColor: "transparent",
                    outline: "inherit",
                    border: "none",
                  }}
                  onClick={() => handledSelectOrganizacion(organization)}
                >
                  <WrapperImage
                    display="flex"
                    justifyContent="center"
                    alignItems="start"
                    cursor="pointer"
                    width="173px" 
                    height="187px"
                    margin="14px"
                  >
                    <WrapperImage
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      style={{
                        background: organization.cssStyle.backgroundColorBoxOrg,
                        height: "144px",
                        width: "173",
                      }}
                    >
                      <Image
                        width={"173px"}
                        src={organization.cssStyle.backgroundImage}
                      />
                    </WrapperImage>
                    <p
                      style={{
                        fontSize: "20px",
                        color: "#fff",
                        fontWeight: "700",
                        marginTop: "23px",
                      }}
                    >
                      {organization.name}
                    </p>
                  </WrapperImage>
                </button>
              ))}
          </WrapperImage>
        </Grid>
      </WrapperImage>
    </>
  );
};
const mapStateToProps = (state) => ({
  Organization: selectOrganizacion(state),
  currentUser: selectCurrentUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  setOrganizationModule: (organizationSelect) =>
    dispatch(setModuleOrganization(organizationSelect)),
  setOrganizationSeleted: (organization) =>
    dispatch(setOrganizationSeleted(organization)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Organization);
