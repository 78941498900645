import React from "react";
import { Menu } from "Components";
import { Link } from "Components";
import Label from "./Label";

export const SubMenuItem = ({ SubMenuItem }) => {
  return (
    <div>
      <Menu fontSize="12px" width="auto" style={{border: "none"}}>
        {Array.isArray(SubMenuItem) &&
        SubMenuItem.map(({subModule}, index) => (
          <Menu.Item key={index}>
            {subModule.path.includes("#") ? (
                <Label margin="0 1em 0 2em"
                       style={{color: "rgb(255, 255, 255)"}}
                       onClick={() => window.location.replace(subModule.path)}>{subModule.name}</Label>) :(
              <Link
                margin="0 1em 0 2em"
                to={subModule.path}
                className="ant-dropdown-link"
                style={{color: "rgb(255, 255, 255)"}}
              >
                {subModule.name}
              </Link>)}
          </Menu.Item>
        ))}
      </Menu>
    </div>
  );
};
