import React from "react";
import "./FormInput.css";

const TextAreaInput = (props) => {
  const { onChange, label, seeMoreLabel, ...rest } = props;

  return (
    <div className="group">
      <textarea className="form-input" onChange={onChange} {...rest} />

      {label ? (
        <label
          className={`${props?.value?.length ? "shrink" : ""} form-input-label ${seeMoreLabel ? 'see-more-label' : ''}`}
        >
          {label}
        </label>
      ) : null}
    </div>
  );
};

export default TextAreaInput;
